import { formatISO, startOfDay, addDays, getDay, startOfMonth, lastDayOfMonth } from 'date-fns';

const today = new Date();
const yesterday = new Date(today);
yesterday.setDate(yesterday.getDate() - 1);

export function format(date) {
  return formatISO(date, { representation: 'date' });
}

function getFormattedDate(date) {
  return format(startOfDay(date));
}

function getPreviousDate(days, date = new Date()) {
  const yesterday = new Date(date);
  yesterday.setDate(yesterday.getDate() - days);
  return yesterday;
}

function getNextDate(days, date = new Date()) {
  return addDays(date, days);
}

function getWeek(date) {
  const today = getDay(date);
  const from = getFormattedDate(getPreviousDate(today, date));
  const to = getFormattedDate(getNextDate(6 - today, date));
  return { from, to };
}

function getMonth(date) {
  const from = format(startOfMonth(date));
  const to = format(lastDayOfMonth(date));
  return { from, to };
}

export function getToday() {
  const today = new Date();
  return getFormattedDate(today);
}

export function getYesterday() {
  const yesterday = getPreviousDate(1);
  return getFormattedDate(yesterday);
}

export function getTomorrow() {
  const tomorrow = getNextDate(1);
  return getFormattedDate(tomorrow);
}

export function getCurrentWeek() {
  return getWeek(new Date());
}

export function getLastWeek() {
  return getWeek(getPreviousDate(7));
}

export function getCurrentMonth() {
  return getMonth(new Date());
}

export function getLastMonth() {
  const date = new Date();
  const lastMonthDate = new Date(date.getFullYear(), date.getMonth(), 0);
  return getMonth(lastMonthDate);
}
