import React, { useState, useEffect, useContext, useMemo, useCallback } from 'react';
import { SERVICE_TYPE } from 'constants/index';
import getService from 'services/serviceDiscovery';
import { CommonUtils } from 'utils';
import { useFreshdeskContextState } from 'store';
import { useMessageChannelAdapter } from 'hooks';

const initialValues = {
  idLookup: {},
  nativeCallFailed: false,
  fields: {},
  loaded: false,
};

export const NativeObjectsContext = React.createContext(initialValues);
const NativeObjectsUpdaterContext = React.createContext();

const NativeObjectsContextProvider = ({ children }) => {
  const { getFieldDetailsFor } = useMessageChannelAdapter();
  const [nativeObjectData, setNativeObjectData] = useState(initialValues);
  const { state: parentWindowState, getConfigType } = useFreshdeskContextState();
  const configType = getConfigType();
  const getNativeIdLookup = useCallback(async () => {
    try {
      const apiService = getService(SERVICE_TYPE.ENTITY);
      const response = await apiService.native();
      const nativeIdLookup = response?.schemas
        ?.filter(schema => parentWindowState.native_objects_enabled.includes(schema.name.toLowerCase()))
        ?.reduce((map, schema) => {
          schema.context = schema.name.toLowerCase();
          return { ...map, [schema.id]: schema };
        }, {});
      return CommonUtils.buildResponse(true, nativeIdLookup);
    } catch (e) {
      return CommonUtils.buildErrorResponse(e);
    }
  }, [parentWindowState.native_objects_enabled]);

  const getFieldDetailsForNativeObjects = useCallback(
    async (ucrEnabledNativeObjects = []) => {
      const response = {};
      const promises = [];
      for (const nativeObjIdentifier of ucrEnabledNativeObjects) {
        promises.push(getFieldDetailsFor(nativeObjIdentifier, configType));
      }
      const fieldsResponse = await Promise.allSettled(promises);
      const nativeFieldResponseList = fieldsResponse.filter(r => r.status === 'fulfilled').map(r => r.value);
      nativeFieldResponseList.forEach(nativeFieldResponse => {
        if (nativeFieldResponse.success) {
          const { meta, response: apiResponse } = nativeFieldResponse.response;
          const responseKey = Object.keys(apiResponse)[0];
          response[meta] = apiResponse[responseKey];
        }
      });
      return CommonUtils.buildResponse(true, response);
    },
    [getFieldDetailsFor, configType]
  );

  useEffect(() => {
    (async () => {
      if (!parentWindowState.loaded) return;
      const response = await Promise.allSettled([getNativeIdLookup(), getFieldDetailsForNativeObjects(parentWindowState.native_objects_enabled)]);
      let idLookup = {};
      let nativeCallFailed = false;
      let fields = {};
      if (response[0].value?.success) {
        idLookup = response[0].value.response;
      } else {
        nativeCallFailed = true;
      }
      if (response[1].value?.success) {
        fields = response[1].value.response;
      }
      setNativeObjectData({
        idLookup,
        nativeCallFailed,
        fields,
        loaded: true,
      });
    })();
  }, [parentWindowState.loaded, parentWindowState.native_objects_enabled, getNativeIdLookup, getFieldDetailsFor, getFieldDetailsForNativeObjects]);

  const loadNativeObjectsData = useCallback(async () => {
    if (!parentWindowState.loaded) return;
    if (nativeObjectData.nativeCallFailed) {
      const response = await getNativeIdLookup();
      let idLookup = {};
      let nativeCallFailed = true;
      if (response.success) {
        idLookup = response.response;
        nativeCallFailed = false;
      }
      setNativeObjectData(prev => {
        return { ...prev, idLookup, nativeCallFailed };
      });
    }
  }, [getNativeIdLookup, nativeObjectData.nativeCallFailed, parentWindowState.loaded]);

  const updateFunctions = useMemo(() => {
    return {
      loadNativeObjectsData,
    };
  }, [loadNativeObjectsData]);

  return (
    <NativeObjectsContext.Provider value={nativeObjectData}>
      <NativeObjectsUpdaterContext.Provider value={updateFunctions}>{children}</NativeObjectsUpdaterContext.Provider>
    </NativeObjectsContext.Provider>
  );
};

const useNativeObjectsState = () => {
  const nativeObjectData = useContext(NativeObjectsContext);
  if (!nativeObjectData) {
    throw new Error('useForwardRefState must be used within a ForwardRefProvider');
  }
  return nativeObjectData;
};

const useNativeObjectsUpdater = () => {
  const { loadNativeObjectsData } = useContext(NativeObjectsUpdaterContext);
  if (!loadNativeObjectsData) {
    throw new Error('useNativeObjectsUpdater must be used within a NativeObjectsContextProvider');
  }
  return { loadNativeObjectsData };
};

export { NativeObjectsContextProvider, useNativeObjectsState, useNativeObjectsUpdater };
