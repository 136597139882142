import nativeObjectConfigs from 'configs/nativeObjectConfigs';
import { ENTITY_FIELD_TYPE, LEGO_CONSTANTS, SCHEMA_TYPE } from 'constants/index';
import { CommonUtils } from 'utils';

const createRowHeaderData = (header, recordId, value, subText, avatar, isLink) => ({
  header,
  id: recordId,
  recordId,
  value,
  subtext: subText,
  avatar,
  isLink: isLink,
});

const createRowData = (header, recordId, value, isLink) => ({
  header,
  recordId,
  id: recordId,
  value: value ?? '-',
  isLink,
});
export const createCompanyRecord = (nativeRecord, label, nativeRecordId, translation) => {
  const nativeObjectRecord = {};
  if (!nativeRecord) return nativeObjectRecord;
  nativeObjectRecord.id = nativeRecordId;
  nativeObjectRecord.rows = [
    createRowHeaderData(
      label,
      CommonUtils.convertValueToBigIntString(nativeRecord?.org_company_id),
      nativeRecord?.name,
      nativeRecord?.domains.toString(),
      nativeRecord?.thumb_url,
      false
    ),
    createRowData(translation('general.noOfContacts'), CommonUtils.convertValueToBigIntString(nativeRecord?.org_company_id), nativeRecord.contacts_count, true),
    createRowData(translation('general.healthScore'), CommonUtils.convertValueToBigIntString(nativeRecord?.org_company_id), nativeRecord.health_score, false),
    createRowData(
      translation('general.renewalDate'),
      CommonUtils.convertValueToBigIntString(nativeRecord?.org_company_id),
      CommonUtils.getFormattedDateForDateInString(nativeRecord.renewal_date),
      false
    ),
  ];
  return nativeObjectRecord;
};

export const createTicketRecord = (nativeRecord, label, nativeRecordId, translation, language, nativeObjectData) => {
  const statusChoices = nativeObjectData?.fields?.[LEGO_CONSTANTS.TICKET]?.filter(field => field.name === LEGO_CONSTANTS.WIDGET_COLUMNS.STATUS)?.map(item => item.choices ?? []);

  const ticketStatusIdMap = statusChoices?.[0]?.reduce((map, choice) => {
    return { ...map, [choice?.value]: choice?.customer_display_name };
  }, {});

  const priorityChoices = nativeObjectData?.fields?.[LEGO_CONSTANTS.TICKET]
    ?.filter(field => field.name === LEGO_CONSTANTS.WIDGET_COLUMNS.PRIORITY)
    ?.map(item => item.choices ?? []);
  const ticketPriorityIdMap = priorityChoices?.[0]?.reduce((map, choice) => {
    return { ...map, [choice?.value]: choice?.label };
  }, {});

  const nativeObjectRecord = {};
  nativeObjectRecord.id = nativeRecordId;
  nativeObjectRecord.rows = [
    {
      header: label,
      id: nativeRecord?.id,
      recordId: nativeRecord?.id,
      value: nativeRecord?.subject ? `#${nativeRecord?.id} - ${nativeRecord?.subject} ` : `${nativeRecord?.id}`,
      tag: nativeRecord?.archived,
    },
    createRowData(translation('general.from'), nativeRecord?.id, nativeRecord?.requester?.name ?? '-', false),
    createRowData(
      translation('general.createdAt'),
      nativeRecord?.id,
      CommonUtils.getFormattedDate(new Date(nativeRecord?.created_at), language ?? LEGO_CONSTANTS.US_TIME_ZONE),
      false
    ),
    createRowData(translation('general.status'), nativeRecord?.id, ticketStatusIdMap?.[nativeRecord?.status] ?? nativeRecord?.status ?? '-', false),
    createRowData(translation('general.priority'), nativeRecord?.id, ticketPriorityIdMap?.[nativeRecord?.priority] ?? nativeRecord?.priority ?? '-', false),
  ];

  return nativeObjectRecord;
};

export const createContactRecord = (nativeRecord, label, nativeRecordId, translation) => {
  const nativeObjectRecord = {};
  nativeObjectRecord.id = nativeRecordId;
  nativeObjectRecord.rows = [
    createRowHeaderData(label, CommonUtils.convertValueToBigIntString(nativeRecord?.org_contact_id), nativeRecord?.name, nativeRecord?.job_title, nativeRecord?.thumb_url),
    createRowData(translation('general.company'), CommonUtils.convertValueToBigIntString(nativeRecord?.org_contact_id), nativeRecord?.company?.name, false),
    createRowData(translation('general.email'), CommonUtils.convertValueToBigIntString(nativeRecord?.org_contact_id), nativeRecord?.email, false),
    createRowData(translation('general.phone'), CommonUtils.convertValueToBigIntString(nativeRecord?.org_contact_id), nativeRecord?.phone, false),
  ];

  return nativeObjectRecord;
};

export const createInverseNativeSection = (baseRecordData, relatedEntity, fieldName, fieldNamefieldLabelMap, t) => {
  const pluralNativeWidgetMapping = {
    ticket: LEGO_CONSTANTS.NATIVE_WIDGETS.TICKET,
    contact: LEGO_CONSTANTS.NATIVE_WIDGETS.CONTACT,
    company: LEGO_CONSTANTS.NATIVE_WIDGETS.COMPANY,
  };
  return {
    contextLabel: baseRecordData?.data[baseRecordData?.metadata?.primary_field_name],
    primaryFieldLabel: relatedEntity?.context && relatedEntity?.context[0].toUpperCase() + relatedEntity?.context.slice(1),
    linkModalHeaderLabel: `${t('general.link', { label: relatedEntity?.context })}`,
    fieldType: fieldName,
    fieldLabel: fieldNamefieldLabelMap[fieldName],
    fieldName: fieldName,
    linkCtaLabel: t('general.link', { label: relatedEntity?.context }),
    noRecordLabel: t('general.noDataLinked', { data: pluralNativeWidgetMapping[relatedEntity?.context] }),
    viewAllRecordsLabel: t('general.viewAllRedirect', { label: pluralNativeWidgetMapping[relatedEntity?.context] }),
    records: [],
  };
};

export const checkForShowLinkCTA = (fieldNameRelationShipMappingByFieldName, records) =>
  fieldNameRelationShipMappingByFieldName === LEGO_CONSTANTS.WIDGET_RELATIONSHIP.MANY_TO_ONE ||
  (fieldNameRelationShipMappingByFieldName === LEGO_CONSTANTS.WIDGET_RELATIONSHIP.ONE_TO_ONE && records?.length === 0);

export const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const createCustomRecord = (filteredRecordData, record, translatedEntity, t, language) => {
  const customObjectRecord = {};
  const customRecordId = record?.display_id;
  customObjectRecord.id = customRecordId;

  customObjectRecord.rows = [];
  customObjectRecord.rows = filteredRecordData.map(recordDetails => {
    return {
      header: getFieldHeaderForRecord(recordDetails, translatedEntity, record),
      recordId: customRecordId,
      id: customRecordId,
      value: getFieldValueForRecord(translatedEntity?.fields.filter(field => field?.name === recordDetails[0])[0]?.type, recordDetails[1] ?? '-', t, language),
    };
  }, []);

  return customObjectRecord;
};

export const getFieldHeaderForRecord = (recordDetails, translatedEntity, record) => {
  if (recordDetails[0] === record?.metadata?.primary_field_name) {
    return record?.section ?? translatedEntity?.fields.filter(field => field?.name === recordDetails[0])[0]?.label;
  }
  return translatedEntity?.fields.filter(field => field?.name === recordDetails[0])[0]?.label;
};

export const getFieldValueForRecord = (fieldType, actualValue, translationFn, language) => {
  switch (fieldType) {
    case ENTITY_FIELD_TYPE.CHECKBOX:
      return actualValue === true ? translationFn('general.yes') : translationFn('general.no');
    case ENTITY_FIELD_TYPE.NUMBER:
    case ENTITY_FIELD_TYPE.DECIMAL:
      return actualValue === null ? LEGO_CONSTANTS.EMPTY_DEFAULT_DATA : actualValue;
    case ENTITY_FIELD_TYPE.DATE:
      return actualValue ? CommonUtils.getFormattedDate(new Date(actualValue), language) : actualValue;
    case ENTITY_FIELD_TYPE.MULTI_SELECT:
      actualValue = Array.isArray(actualValue) && actualValue?.filter(val => val);
      return Array.isArray(actualValue) && actualValue.length > 0 ? actualValue?.join(', ') : '-';
    default:
      return actualValue ?? LEGO_CONSTANTS.EMPTY_DEFAULT_DATA;
  }
};

export const getFieldDataForRelationshipFieldType = async (
  associations,
  field,
  fieldType,
  actualValue,
  nativeObjectData,
  relatedEntity,
  getAllNativeObjectAssociations,
  record,
  recordPageController,
  configType
) => {
  const primaryFieldName = associations?.[actualValue]?.metadata.primary_field_name;
  const fieldSubType = fieldType === ENTITY_FIELD_TYPE.RELATIONSHIP && field?.field_options?.related_object_type === SCHEMA_TYPE.NATIVE ? SCHEMA_TYPE.NATIVE : SCHEMA_TYPE.CUSTOM;
  if (!actualValue) return LEGO_CONSTANTS.EMPTY_DEFAULT_DATA;
  if (fieldSubType === SCHEMA_TYPE.NATIVE) {
    const nativeFields = recordPageController.getNativeRelationshipFields(relatedEntity);
    const nativeIdentifierToIdsLookup = recordPageController.getAssociatedNativeObjectIdsForRecord(record.data, nativeObjectData, nativeFields);
    const nativeAssociations = await getAllNativeObjectAssociations(nativeIdentifierToIdsLookup);
    const fieldRelatedEntityId = field?.related_entity_id;
    const context = nativeObjectData?.idLookup[fieldRelatedEntityId] ? nativeObjectData.idLookup[fieldRelatedEntityId]?.context : null;
    const configs = nativeObjectConfigs[configType][context];
    return nativeAssociations?.[context]?.[actualValue]?.[configs.defaultDisplayField] ?? actualValue;
  } else {
    return associations?.[actualValue]?.data?.[primaryFieldName] ?? actualValue;
  }
};

export const getFieldValueForSearchRecord = async (
  associations,
  field,
  fieldType,
  actualValue,
  translationFn,
  language,
  nativeObjectData,
  relatedEntity,
  getAllNativeObjectAssociations,
  record,
  recordPageController,
  configType
) => {
  if (fieldType === ENTITY_FIELD_TYPE.RELATIONSHIP) {
    return getFieldDataForRelationshipFieldType(
      associations,
      field,
      fieldType,
      actualValue,
      nativeObjectData,
      relatedEntity,
      getAllNativeObjectAssociations,
      record,
      recordPageController,
      configType
    );
  } else {
    return getFieldValueForRecord(fieldType, actualValue, translationFn, language);
  }
};

export const objectPropertyCheck = objectField => objectField && Object.keys(objectField).length !== 0 && Object.getPrototypeOf(objectField) === Object.prototype;

export const createTicketForSearchPreview = (record, translation, language) => {
  const ticketFields = [];
  Object.keys(record).forEach(key => {
    const val = record[key];
    switch (key) {
      case 'id':
        ticketFields.push({
          name: key,
          value: val ?? '-',
          label: translation('general.id'),
        });
        break;
      case 'subject':
        ticketFields.push({
          name: key,
          value: val ?? '-',
          label: translation('general.subject'),
        });
        break;
      case 'from':
        ticketFields.push({
          name: key,
          value: val ?? '-',
          label: translation('general.from'),
        });
        break;
      case 'created_at':
        ticketFields.push({
          name: key,
          value: CommonUtils.getFormattedDate(new Date(val), language ?? LEGO_CONSTANTS.US_TIME_ZONE) ?? '-',
          label: translation('general.createdAt'),
        });
        break;
      case 'status':
        ticketFields.push({
          name: key,
          value: val ?? '-',
          label: translation('general.status'),
        });
        break;
      case 'priority':
        ticketFields.push({
          name: key,
          value: val ?? '-',
          label: translation('general.priority'),
        });
        break;
      default:
        break;
    }
  });
  return ticketFields;
};

export const createContactForSearchPreview = (record, translation) => {
  const contactFields = [];
  Object.keys(record).forEach(key => {
    const val = record[key];
    switch (key) {
      case 'name':
        contactFields.push({
          name: key,
          value: val ?? '-',
          label: translation('general.name'),
        });
        break;
      case 'company_name':
        contactFields.push({
          name: key,
          value: val ?? '-',
          label: translation('general.company'),
        });
        break;
      case 'email':
        contactFields.push({
          name: key,
          value: val ?? '-',
          label: translation('general.email'),
        });
        break;
      case 'phone':
        contactFields.push({
          name: key,
          value: val ?? '-',
          label: translation('general.phone'),
        });
        break;
      default:
        break;
    }
  });
  return contactFields;
};

export const createCompanyForSearchPreview = (record, translation) => {
  const companyFields = [];
  Object.keys(record).forEach(key => {
    const val = record[key];
    switch (key) {
      case 'name':
        companyFields.push({
          name: key,
          value: val ?? '-',
          label: translation('general.companyName'),
        });
        break;
      case 'contacts_count':
        companyFields.push({
          name: key,
          value: val ?? '-',
          label: translation('general.noOfContacts'),
        });
        break;
      case 'health_score':
        companyFields.push({
          name: key,
          value: val ?? '-',
          label: translation('general.healthScore'),
        });
        break;
      case 'renewal_date':
        companyFields.push({
          name: key,
          value: CommonUtils.getFormattedDateForDateInString(val) ?? '-',
          label: translation('general.renewalDate'),
        });
        break;
      case 'domains':
        companyFields.push({
          name: key,
          value: val?.toString() ?? '-',
          label: translation('general.domains'),
        });
        break;
      default:
        break;
    }
  });
  return companyFields;
};

export const createConversationRecord = (nativeRecord, label, nativeRecordId, translation, language, nativeObjectData) => {
  const statusChoices = nativeObjectData?.fields?.[LEGO_CONSTANTS.CONVERSATION]
    ?.filter(field => field.name === LEGO_CONSTANTS.WIDGET_COLUMNS.STATUS)
    ?.map(item => item.choices ?? []);

  const conversationStatusIdMap = statusChoices?.[0]?.reduce((map, choice) => {
    return { ...map, [choice?.id]: choice?.value };
  }, {});

  const priorityChoices = nativeObjectData?.fields?.[LEGO_CONSTANTS.CONVERSATION]
    ?.filter(field => field.name === LEGO_CONSTANTS.WIDGET_COLUMNS.PRIORITY)
    ?.map(item => item.choices ?? []);
  const conversationPriorityIdMap = priorityChoices?.[0]?.reduce((map, choice) => {
    return { ...map, [choice?.id]: choice?.value };
  }, {});

  const nativeObjectRecord = {};
  nativeObjectRecord.id = nativeRecordId;
  nativeObjectRecord.rows = [
    {
      header: label,
      id: nativeRecordId,
      recordId: nativeRecordId,
      value: nativeRecord?.title,
    },
    createRowData(translation('general.contactName'), nativeRecordId, nativeRecord?.conversationHostUserName, false),
    createRowData(translation('general.contactEmail'), nativeRecordId, nativeRecord?.email, false),
    createRowData(translation('general.status'), nativeRecordId, conversationStatusIdMap?.[nativeRecord?.status] ?? nativeRecord?.status, false),
    createRowData(translation('general.priority'), nativeRecordId, conversationPriorityIdMap?.[nativeRecord?.priority] ?? nativeRecord?.priority, false),
  ];

  return nativeObjectRecord;
};
export const createConversationForSearchPreview = (record, translation, language) => {
  const conversationFields = [];
  Object.keys(record).forEach(key => {
    const val = record[key];
    switch (key) {
      case 'title':
        conversationFields.push({
          name: key,
          value: val ?? '-',
          label: translation('general.conversationSubject'),
        });
        break;
      case 'conversationHostUserName':
        conversationFields.push({
          name: key,
          value: val ?? '-',
          label: translation('general.contactName'),
        });
        break;
      case 'email':
        conversationFields.push({
          name: key,
          value: val ?? '-',
          label: translation('general.contactEmail'),
        });
        break;
      case 'status':
        conversationFields.push({
          name: key,
          value: val ?? '-',
          label: translation('general.status'),
        });
        break;
      case 'priority':
        conversationFields.push({
          name: key,
          value: val ?? '-',
          label: translation('general.priority'),
        });
        break;
      default:
        break;
    }
  });
  return conversationFields;
};

export const createSKUContactRecord = (nativeRecord, label, nativeRecordId, translation) => {
  const nativeObjectRecord = {};
  nativeObjectRecord.id = nativeRecordId;
  nativeObjectRecord.rows = [
    createRowHeaderData(label, CommonUtils.convertValueToBigIntString(nativeRecord?.mcr_id), nativeRecord?.name, nativeRecord?.job_title, nativeRecord?.avatar, false),
    createRowData(
      translation('general.email'),
      CommonUtils.convertValueToBigIntString(nativeRecord?.mcr_id),
      nativeRecord?.emails?.filter(email => email.is_primary === true)?.[0]?.value,
      false
    ),
    createRowData(translation('general.phone'), CommonUtils.convertValueToBigIntString(nativeRecord?.mcr_id), nativeRecord?.mobile_number, false),
    createRowData(
      translation('general.company'),
      CommonUtils.convertValueToBigIntString(nativeRecord?.mcr_id),
      nativeRecord?.sales_accounts?.filter(salesAccount => salesAccount.is_primary === true)?.[0]?.name,
      false
    ),
  ];
  return nativeObjectRecord;
};

export const createSKUCompanyRecord = (nativeRecord, label, nativeRecordId, translation) => {
  const nativeObjectRecord = {};
  if (!nativeRecord) return nativeObjectRecord;
  nativeObjectRecord.id = nativeRecordId;
  nativeObjectRecord.rows = [
    createRowHeaderData(label, CommonUtils.convertValueToBigIntString(nativeRecord?.mcr_id), nativeRecord?.name, nativeRecord?.website, nativeRecord?.avatar, false),
    createRowData(translation('general.noOfContacts'), CommonUtils.convertValueToBigIntString(nativeRecord?.mcr_id), nativeRecord.contacts_count, true),
    createRowData(translation('general.healthScore'), CommonUtils.convertValueToBigIntString(nativeRecord?.mcr_id), nativeRecord.health_score, false),
    createRowData(
      translation('general.renewalDate'),
      CommonUtils.convertValueToBigIntString(nativeRecord?.mcr_id),
      CommonUtils.getFormattedDateForDateInString(nativeRecord.renewal_date),
      false
    ),
  ];
  return nativeObjectRecord;
};

export const createSKUCompanyForSearchPreview = (record, translation) => {
  const companyFields = [];
  Object.keys(record).forEach(key => {
    const val = record[key];
    switch (key) {
      case 'name':
        companyFields.push({
          name: key,
          value: val ?? '-',
          label: translation('general.companyName'),
        });
        break;
      case 'contacts_count':
        companyFields.push({
          name: key,
          value: val ?? '-',
          label: translation('general.noOfContacts'),
        });
        break;
      default:
        break;
    }
  });
  return companyFields;
};

export const getNativeObjectIDForConfigType = (configType, nativeObjectValue) => {
  if (configType === LEGO_CONSTANTS.DEFAULT) return nativeObjectValue;
  return nativeObjectValue > Number.MAX_SAFE_INTEGER ? CommonUtils.convertValueToBigIntString(nativeObjectValue) : nativeObjectValue;
};
