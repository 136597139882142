import React, { useState, useCallback, useMemo, useContext } from 'react';
import { Permissions } from 'utils';

const initialContextValue = {
  permissions: {
    schema: [],
    record: [],
    nativeObjects: {},
  },
  account: {
    id: null,
  },
  organisation: {
    id: null,
  },
  user: {
    id: null,
    name: null,
    locale: {
      dateTimeFormat: null,
      timezone: null,
      language: null,
    },
  },
  native_objects_enabled: [],
  configType: 'default',
  loaded: false,
  basepath: '',
  accountPrimaryLanguage: '',
};

export const FreshdeskStateContext = React.createContext(initialContextValue);
const FreshdeskUpdaterContext = React.createContext();

const FreshdeskContextProvider = props => {
  const [state, setState] = useState(initialContextValue);

  const getPermissions = useCallback(() => {
    return state?.permissions ?? { schema: [], record: [] };
  }, [state?.permissions]);
  const getSchemaPermissions = useCallback(() => {
    return new Permissions(getPermissions().schema ?? []);
  }, [getPermissions]);

  const getRecordPermissions = useCallback(() => {
    return new Permissions(getPermissions().record ?? []);
  }, [getPermissions]);

  const getNativeObjectPermissions = useCallback(() => {
    return state.permissions.nativeObjects ?? {};
  }, [state.permissions.nativeObjects]);

  const getAccount = useCallback(() => {
    return state?.account ?? { id: null };
  }, [state?.account]);

  const getOrganisation = useCallback(() => {
    return state?.organisation ?? { id: null };
  }, [state?.organisation]);

  const hasContextLoaded = useCallback(() => {
    return state?.loaded;
  }, [state?.loaded]);

  const getUserDetails = useCallback(() => {
    const userDetails = state?.user ?? {
      id: null,
      name: null,
      locale: {
        dateTimeFormat: null,
        timezone: null,
        language: null,
      },
    };
    return userDetails;
  }, [state?.user]);

  const getEnabledNativeObjects = useCallback(() => {
    return state.native_objects_enabled ?? [];
  }, [state.native_objects_enabled]);

  const getAccountPrimaryLanguage = useCallback(() => {
    return state.accountPrimaryLanguage;
  }, [state.accountPrimaryLanguage]);

  const getAccountBasePath = useCallback(() => {
    return state.basepath;
  }, [state.basepath]);

  const getConfigType = useCallback(() => {
    return state.configType;
  }, [state.configType]);

  const contextValue = useMemo(() => {
    return {
      getPermissions,
      getSchemaPermissions,
      getRecordPermissions,
      getAccount,
      getOrganisation,
      getUserDetails,
      getEnabledNativeObjects,
      hasContextLoaded,
      getNativeObjectPermissions,
      getAccountBasePath,
      getAccountPrimaryLanguage,
      getConfigType,
      state,
    };
  }, [
    getPermissions,
    getSchemaPermissions,
    getRecordPermissions,
    getAccount,
    getOrganisation,
    getUserDetails,
    getEnabledNativeObjects,
    hasContextLoaded,
    getNativeObjectPermissions,
    getAccountBasePath,
    getAccountPrimaryLanguage,
    getConfigType,
    state,
  ]);

  return (
    <FreshdeskStateContext.Provider value={contextValue}>
      <FreshdeskUpdaterContext.Provider value={setState}>{props.children}</FreshdeskUpdaterContext.Provider>
    </FreshdeskStateContext.Provider>
  );
};

const useFreshdeskContextState = () => {
  const state = useContext(FreshdeskStateContext);
  if (!state) {
    throw new Error('useFreshdeskContextState must be used within a FreshdeskContextProvider');
  }
  return state;
};

const useFreshdeskContextUpdater = () => {
  const setter = useContext(FreshdeskUpdaterContext);
  if (!setter) {
    throw new Error('useFreshdeskContextUpdater must be used within a FreshdeskContextProvider');
  }

  return useCallback(
    (toUpdate = {}) => {
      setter(prev => {
        return { ...prev, ...toUpdate };
      });
    },

    [setter]
  );
};

export { FreshdeskContextProvider, useFreshdeskContextState, useFreshdeskContextUpdater };
