import React from 'react';
import { LEGO_CONSTANTS, ENTITY_FIELD_TYPE, SCHEMA_TYPE } from 'constants/index';
import { useWidgetContextState } from 'store';
import styles from './LinkModal.module.css';

const EntityRecord = ({ relatedEntity, displayDetails }) => {
  const fieldOrderForEntity = useWidgetContextState();
  const fieldsAllowed = fieldOrderForEntity?.[`${LEGO_CONSTANTS.ENTITY_ID_PREFIX}${relatedEntity.id}`] ?? { loaded: false, value: [] };

  const { enrichedRecordData: details } = displayDetails;
  const renderDisplayValue = item => {
    if (item.fieldType === ENTITY_FIELD_TYPE.RELATIONSHIP && item.fieldSubType === SCHEMA_TYPE.NATIVE) {
      if (item.displayValue.meta === LEGO_CONSTANTS.TICKET) {
        return item.displayValue?.value?.subject ?? LEGO_CONSTANTS.EMPTY_DEFAULT_DATA;
      } else if (item.displayValue.meta === LEGO_CONSTANTS.CONTACT) {
        return item.displayValue?.value?.name ?? LEGO_CONSTANTS.EMPTY_DEFAULT_DATA;
      }
    } else if (item.fieldType === ENTITY_FIELD_TYPE.RELATIONSHIP && item.fieldSubType === SCHEMA_TYPE.CUSTOM) {
      return item.displayValue.value ?? item.displayValue;
    }
    return item.displayValue ?? LEGO_CONSTANTS.EMPTY_DEFAULT_DATA;
  };

  const dispayData =
    fieldsAllowed?.value?.length !== 0 ? details.filter(item => fieldsAllowed?.value?.includes(item.fieldId)) : details.slice(0, LEGO_CONSTANTS.WIDGET_CUSTOMISATION_LIMIT);

  return (
    <div className={styles['data-container']}>
      {fieldsAllowed?.loaded &&
        dispayData.map(detail => {
          return (
            <div key={detail.fieldLabel} className={styles['data-container--field']}>
              <div className={styles['field-key']}>{detail.fieldLabel}</div>
              {detail.displayValue !== null ? (
                <div className={styles['field-value']}>{renderDisplayValue(detail)}</div>
              ) : (
                <div className="fw-color-smoke-300">{LEGO_CONSTANTS.EMPTY_DEFAULT_DATA}</div>
              )}
            </div>
          );
        })}
    </div>
  );
};

export default EntityRecord;
