import { RestService, API, getPath } from './lib';

class EntityRecordAssociationService extends RestService {
  getAll = (ids = {}, params = {}) => {
    const url = getPath(API.ENTITY_RECORD_ASSOCIATIONS, ids, params);
    return this.sendRequest(url);
  };

  create = (ids = {}, payload = {}, params = {}) => {
    this.validate(['id', 'displayId'], ids);
    const url = getPath(API.ENTITY_RECORD_ASSOCIATIONS, ids, params);
    return this.sendRequest(url, 'POST', payload);
  };

  delete = (ids = {}, params = {}) => {
    this.validate(['id', 'displayId'], ids);
    const url = getPath(API.ENTITY_RECORD_ASSOCIATIONS, ids, params);
    return this.sendRequest(url, 'DELETE');
  };
}

export const entityRecordAssociationService = new EntityRecordAssociationService();
