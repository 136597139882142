import React from 'react';
import { useTranslation } from 'react-i18next';
import { BlankState } from 'UI';
import pageNotFoundImage from '../../images/empty-records-search.svg';

const PageNotFound = () => {
  const { t } = useTranslation();
  return <BlankState heading={t('general.pageNotFound')} image={pageNotFoundImage} blankContainer={true} />;
};
export default PageNotFound;
