import React from 'react';
import { FwIcon } from '@freshworks/crayons/react';
import { CommonUtils } from 'utils';
import { LEGO_CONSTANTS } from 'constants/index';

import styles from './Header.module.css';

const Header = props => {
  const { icon, isLoading, heading, description, children, image, context = LEGO_CONSTANTS.ENTITY_LIST } = props;
  const iconName = CommonUtils.getIconNamefromUrl(icon);
  const headerClassNames = context === LEGO_CONSTANTS.RECORDS_LIST ? `${styles.header} fw-py-16 fw-px-16` : `${styles.header} fw-py-16 fw-px-24`;
  return (
    <div className={headerClassNames}>
      {!isLoading && heading && (
        <>
          <div className={styles['header-left-container']}>
            {image && (
              <div className={styles['header-icon']}>
                <img alt="blankState" width={18} height={18} src={image} />
              </div>
            )}
            {icon && (
              <div className={styles['header-icon']}>
                <FwIcon size={22} src={icon?.substring(0, icon?.lastIndexOf('/'))} name={iconName} color={'#12344D'} />
              </div>
            )}
            <div className={styles['header-name-container']}>
              <label className={`fw-type-bold fw-color-elephant-900 ${styles['header-name']}`}>{heading}</label>
              <label className={`fw-type-sm fw-color-smoke-700 ${styles['header-description']}`}>{description || ''}</label>
            </div>
          </div>
          <div className={styles['header-right-container']}>{children}</div>
        </>
      )}
    </div>
  );
};

export default Header;
