import { useCallback, useMemo, useRef } from 'react';
import { nativeObjectsPrivateApiAdapter } from 'services/nativeObjectsPrivateApiAdapter';
import useChannel from './use-channel';
import { MESSAGE_CHANNEL_COMM_TYPE } from 'constants/index';
import nativeObjectConfigs from 'configs/nativeObjectConfigs';
import { CommonUtils } from 'utils';

const useMessageChannelAdapter = () => {
  const searchDebounceTimerRef = useRef(null);
  const { postMessage } = useChannel();

  const getAccessToParentAppContext = useCallback(
    async context => {
      const { resource } = context;
      return await postMessage({
        action: MESSAGE_CHANNEL_COMM_TYPE.GET_ACCESS,
        resource,
      });
    },
    [postMessage]
  );

  const updateNativeObject = useCallback(
    async context => {
      const { model, id, modelProperties } = context;
      return await postMessage({
        action: MESSAGE_CHANNEL_COMM_TYPE.UPDATE_NATIVE_OBJECT,
        model,
        id,
        modelProperties,
      });
    },
    [postMessage]
  );

  const getLanguageListForTranslations = useCallback(
    async context => {
      try {
        const { id } = context;
        const response = await postMessage({
          action: MESSAGE_CHANNEL_COMM_TYPE.GET_LANGUAGES,
          id,
        });
        const { data } = response?.response ?? { data: null };
        return data;
      } catch (error) {
        console.error(error);
      }
    },
    [postMessage]
  );

  const navigate = useCallback(
    context => {
      const { resource, parameters, ...options } = context;
      postMessage(
        {
          action: MESSAGE_CHANNEL_COMM_TYPE.NAVIGATE,
          resource,
          parameters,
          ...options,
        },
        false
      );
    },
    [postMessage]
  );

  const emitRouteChangeEvent = useCallback(
    context => {
      const { resource } = context;
      postMessage(
        {
          action: MESSAGE_CHANNEL_COMM_TYPE.ROUTE_CHANGE,
          resource,
        },
        false
      );
    },
    [postMessage]
  );

  const onSchemaCRUD = useCallback(
    context => {
      const { action, resource, parameters } = context;
      postMessage(
        {
          action,
          resource,
          parameters,
        },
        false
      );
    },
    [postMessage]
  );

  const __getFieldDetailsFor = useCallback(
    async nativeObjIdentifier => {
      try {
        const response = await postMessage({
          action: MESSAGE_CHANNEL_COMM_TYPE.GET_NATIVE_OBJECT_FIELDS,
          model: nativeObjIdentifier,
        });
        const { data } = response?.response ?? { data: null };
        return CommonUtils.buildResponse(true, { meta: nativeObjIdentifier, response: data });
      } catch (error) {
        console.error(error);
        return CommonUtils.buildErrorResponse(error);
      }
    },
    [postMessage]
  );

  const getFieldDetailsFor = useCallback(
    async (nativeObjIdentifier, configType = 'default') => {
      const configs = nativeObjectConfigs[configType][nativeObjIdentifier];
      if (configs.messageChannelSupport) return await __getFieldDetailsFor(nativeObjIdentifier);
      else return nativeObjectsPrivateApiAdapter.getFieldDetailsFor(nativeObjIdentifier, configType);
    },
    [__getFieldDetailsFor]
  );

  const __getRecords = useCallback(
    async (nativeObjIdentifier, recordIds = [], configType = 'default') => {
      try {
        const configs = nativeObjectConfigs[configType][nativeObjIdentifier];
        const __recordIds = configs.hasBigIntegerIds ? recordIds.map(id => CommonUtils.convertValueToBigIntString(id)) : recordIds;
        const parameters = { [configs.bulkGetIdParamsKey]: __recordIds, ...configs.bulkGetParams };
        const response = await postMessage({
          action: MESSAGE_CHANNEL_COMM_TYPE.BULK_GET_NATIVE_OBJECT,
          model: nativeObjIdentifier,
          parameters,
        });
        const { data } = response?.response ?? { data: null };
        return CommonUtils.buildResponse(true, data);
      } catch (error) {
        console.error(error);
        return CommonUtils.buildErrorResponse(error);
      }
    },
    [postMessage]
  );

  const getRecords = useCallback(
    async (nativeObjIdentifier, recordIds = [], configType = 'default') => {
      const configs = nativeObjectConfigs[configType][nativeObjIdentifier];
      if (configs.messageChannelSupport) return await __getRecords(nativeObjIdentifier, recordIds, configType);
      else return nativeObjectsPrivateApiAdapter.getRecords(nativeObjIdentifier, recordIds, configType);
    },
    [__getRecords]
  );

  const __search = useCallback(
    async (nativeObjIdentifier, parameters) => {
      try {
        const response = await postMessage({
          action: MESSAGE_CHANNEL_COMM_TYPE.SEARCH_NATIVE_OBJECT,
          model: nativeObjIdentifier,
          parameters,
        });
        const { data } = response?.response ?? { data: null };
        return CommonUtils.buildResponse(true, data);
      } catch (error) {
        console.error(error);
        return CommonUtils.buildErrorResponse(error);
      }
    },
    [postMessage]
  );

  const search = useCallback(
    async (nativeObjIdentifier, params = {}, configType = 'default') => {
      clearTimeout(searchDebounceTimerRef.current);
      const searchParams = { term: params.term, limit: params.page_size, ...params.subParameters };
      const configs = nativeObjectConfigs[configType][nativeObjIdentifier];
      if (configs.messageChannelSupport) {
        return new Promise((resolve, reject) => {
          searchDebounceTimerRef.current = setTimeout(async () => {
            const res = await __search(nativeObjIdentifier, searchParams);
            res.success ? resolve(res) : reject(res);
          }, 1500);
        });
      } else return nativeObjectsPrivateApiAdapter.search(nativeObjIdentifier, searchParams, configType);
    },
    [__search]
  );

  return useMemo(() => {
    return {
      getAccessToParentAppContext,
      updateNativeObject,
      navigate,
      emitRouteChangeEvent,
      onSchemaCRUD,
      getFieldDetailsFor,
      getRecords,
      search,
      getLanguageListForTranslations,
    };
  }, [getAccessToParentAppContext, updateNativeObject, navigate, emitRouteChangeEvent, onSchemaCRUD, getFieldDetailsFor, getRecords, search, getLanguageListForTranslations]);
};

export default useMessageChannelAdapter;
