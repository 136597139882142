import { RestService } from 'services/lib';
import { LEGO_CONSTANTS } from 'constants/index';

export default class LegoTable {
  service = null;
  ids = {};
  pageSize = null;
  order = null;
  orderBy = null;
  defaultOrderBy = null;
  dataField = null;
  enableSearch = true;
  enableFilter = false;
  disableCount = false;
  enableFilterChangeEvent = false;
  onSuccess = null;

  getService = () => {
    return this.service;
  };

  getIds = () => {
    return this.ids;
  };

  getPageSize = () => {
    return this.pageSize;
  };

  getOrder = () => {
    return this.order;
  };

  getOrderBy = () => {
    return this.orderBy ?? this.defaultOrderBy;
  };

  getDataField = () => {
    return this.dataField;
  };

  setOrderBy = orderBy => {
    this.orderBy = orderBy;
  };

  setOrder = order => {
    this.order = order;
  };

  isSearchEnabled = () => {
    return this.enableSearch;
  };

  isFilterEnabled = () => {
    return this.enableFilter;
  };

  isFilterChangeEventEnabled = () => {
    return this.enableFilterChangeEvent;
  };

  isCountDisabled = () => {
    return this.disableCount;
  };

  applyOnSuccess = () => {
    return async (...args) => {
      const res = await this.onSuccess?.apply?.(this, args);
      return res;
    };
  };

  static Builder = class {
    service = null;
    ids = {};
    pageSize = LEGO_CONSTANTS.DEFAULT_PAGE_SIZE;
    order = LEGO_CONSTANTS.ORDER.DESC;
    defaultOrderBy = 'created_time';
    orderBy = null;
    dataField = 'records';
    enableSearch = false;
    enableFilter = false;
    enableFilterChangeEvent = false;
    callback = null;

    setService = service => {
      if (service === null || !(service instanceof RestService)) throw new Error(LEGO_CONSTANTS.LEGO_TABLE_ERRORS.INVALID_SERVICE_TYPE);
      this.service = service;
      return this;
    };

    setIds = ids => {
      this.ids = ids;
      return this;
    };

    setPageSize = pageSize => {
      this.pageSize = pageSize;
      return this;
    };

    setOrder = order => {
      this.order = order;
      return this;
    };

    setOrderBy = orderBy => {
      this.orderBy = orderBy;
      return this;
    };

    setDefaultOrderBy = orderBy => {
      if (!orderBy) throw new Error(LEGO_CONSTANTS.LEGO_TABLE_ERRORS.INVALID_ORDER_BY);
      this.defaultOrderBy = orderBy;
      return this;
    };

    setDataField = dataField => {
      this.dataField = dataField;
      return this;
    };

    withSearch = () => {
      this.enableSearch = true;
      return this;
    };

    withFilter = () => {
      this.enableFilter = true;
      return this;
    };

    withoutCount = () => {
      this.disableCount = true;
      return this;
    };

    withFilterChangeEvent = () => {
      this.enableFilterChangeEvent = true;
      return this;
    };

    setOnSuccess = func => {
      if (typeof func !== 'function') throw new Error(LEGO_CONSTANTS.LEGO_TABLE_ERRORS.INVALID_VALUE_FOR_FUNCTION);
      this.onSuccess = func;
      return this;
    };

    build = () => {
      return new LegoTable({
        service: this.service,
        ids: this.ids,
        pageSize: this.pageSize,
        order: this.order,
        orderBy: this.orderBy,
        defaultOrderBy: this.defaultOrderBy,
        dataField: this.dataField,
        enableSearch: this.enableSearch,
        enableFilter: this.enableFilter,
        disableCount: this.disableCount,
        onSuccess: this.onSuccess,
        enableFilterChangeEvent: this.enableFilterChangeEvent,
      });
    };
  };

  constructor(obj) {
    this.service = obj.service;
    this.ids = obj.ids;
    this.pageSize = obj.pageSize;
    this.order = obj.order;
    this.orderBy = obj.orderBy;
    this.defaultOrderBy = obj.defaultOrderBy;
    this.dataField = obj.dataField;
    this.enableSearch = obj.enableSearch;
    this.enableFilter = obj.enableFilter;
    this.disableCount = obj.disableCount;
    this.enableFilterChangeEvent = obj.enableFilterChangeEvent;
    this.onSuccess =
      obj.onSuccess ??
      (arg => {
        return arg;
      });
  }
}
