import { LEGO_CONSTANTS } from 'constants/index';

const getKey = suffix => {
  return `${LEGO_CONSTANTS.ENV_VAR_PREFIX}${suffix}`;
};

class PropertyStore {
  get = suffix => {
    const key = getKey(suffix);
    if (!Object.prototype.hasOwnProperty.call(process.env, key)) throw new Error(`Property '${suffix}' does not exist`);
    return process.env[key];
  };
}

export const propertyStore = new PropertyStore();
