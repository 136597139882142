import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useFreshdeskContextState, useNativeObjectsState } from 'store';
import { recordPageController } from 'controllers';
import { LEGO_CONSTANTS, SCHEMA_TYPE, ENTITY_FIELD_TYPE } from 'constants/index';
import { useMessageChannelAdapter } from 'hooks';
import { CommonUtils } from 'utils';
import nativeObjectConfigs from 'configs/nativeObjectConfigs';

const useRecordsPage = () => {
  const { id, displayId } = useParams();
  const { t: translationFn } = useTranslation();
  const { getRecords: getNativeRecordsMC } = useMessageChannelAdapter();
  const nativeObjectData = useNativeObjectsState();
  const { getSchemaPermissions, getRecordPermissions, getUserDetails, getConfigType } = useFreshdeskContextState();

  const configType = getConfigType();
  const language = getUserDetails()?.locale?.language;
  const timezone = getUserDetails()?.locale?.timezone;
  const schemaPermissions = useMemo(() => getSchemaPermissions(), [getSchemaPermissions]);
  const recordPermissions = useMemo(() => getRecordPermissions(), [getRecordPermissions]);
  recordPageController.setPermissions(schemaPermissions, recordPermissions, configType);

  const getRelatedCustomEntityIds = useCallback(entity => {
    return recordPageController.getCustomRelatedEntityIds(entity);
  }, []);

  const getWidgetCustomizationsForEntityIds = useCallback(async eidSet => {
    const response = {};
    const promises = {};
    for (const eid of eidSet) {
      promises[`${LEGO_CONSTANTS.ENTITY_ID_PREFIX}${eid}`] = recordPageController.getWidgetCustomizationForEntityId(eid);
    }
    await Promise.allSettled(Object.values(promises));
    for (const eid of eidSet) {
      promises[`${LEGO_CONSTANTS.ENTITY_ID_PREFIX}${eid}`]
        ?.then(res => {
          response[`${LEGO_CONSTANTS.ENTITY_ID_PREFIX}${eid}`] = { loaded: true, value: res?.response?.precedence_of_schema_fields ?? [] };
        })
        .catch(() => {
          response[`${LEGO_CONSTANTS.ENTITY_ID_PREFIX}${eid}`] = { loaded: true, value: [] };
        });
    }
    return response;
  }, []);

  const __getRecordsLookupById = useCallback(
    async (nativeIdentifer, nativeObjectIds = []) => {
      try {
        if (nativeObjectIds.length === 0) return CommonUtils.buildErrorResponse();
        const config = nativeObjectConfigs[configType][nativeIdentifer];
        const response = await getNativeRecordsMC(nativeIdentifer, nativeObjectIds, configType);
        if (!response.success) return CommonUtils.buildErrorResponse();
        const associations = response.response[config.responseKey].reduce((map, item) => {
          return { ...map, [item[config.ucrEnabledIdKey]]: item };
        }, {});
        return CommonUtils.buildResponse(true, associations);
      } catch (e) {
        console.error(e);
        return CommonUtils.buildErrorResponse(e);
      }
    },
    [getNativeRecordsMC, configType]
  );

  const getRecordsLookupById = useCallback(
    async (nativeIdentifer, nativeObjectIds = []) => {
      const associations = await __getRecordsLookupById(nativeIdentifer, nativeObjectIds);
      return { context: nativeIdentifer, response: associations.response, success: associations.success };
    },
    [__getRecordsLookupById]
  );

  const getAllNativeObjectAssociations = useCallback(
    async (nativeIdentifierToIdsLookup = {}) => {
      const promises = [];
      const nativeAssociations = {};
      for (const [nativeIdentifer, nativeObjectIds] of Object.entries(nativeIdentifierToIdsLookup)) {
        promises.push(getRecordsLookupById(nativeIdentifer, nativeObjectIds));
      }
      const associationResponseArray = await Promise.allSettled(promises);
      for (let index = 0; index < associationResponseArray.length; index++) {
        const associationResponse = associationResponseArray[index];
        if (associationResponse.value.success) {
          nativeAssociations[associationResponse.value.context] = associationResponse.value.response;
        }
      }
      return nativeAssociations;
    },
    [getRecordsLookupById]
  );

  const getRecordWithAssociations = useCallback(
    async (entity, displayId) => {
      const response = await recordPageController.getRecord(entity.id, displayId, { include: 'associations' });
      if (!response.success) return response;
      const nativeFields = recordPageController.getNativeRelationshipFields(entity);
      const nativeIdentifierToIdsLookup = recordPageController.getAssociatedNativeObjectIdsForRecord(response.response.data, nativeObjectData, nativeFields);
      response.response.nativeAssociations = await getAllNativeObjectAssociations(nativeIdentifierToIdsLookup);
      return response;
    },
    [nativeObjectData, getAllNativeObjectAssociations]
  );

  const fetchRecord = useCallback(
    async entity => {
      let toUpdate = {};
      if (!nativeObjectData.loaded) return toUpdate;
      const recordResponse = await getRecordWithAssociations(entity, displayId);
      if (!recordResponse.success) {
        return { error: recordResponse.errorMessage };
      }
      toUpdate = { ...toUpdate, record: recordResponse.response };
      return toUpdate;
    },
    [displayId, getRecordWithAssociations, nativeObjectData]
  );

  const fetchRecordAndEntityIfNull = useCallback(
    async __entity => {
      let toUpdate = {};
      if (!nativeObjectData.loaded) return toUpdate;
      let entityResponse = null;
      if (!__entity) {
        entityResponse = await recordPageController.getEntityInformation(id);
        if (!entityResponse.success) {
          return { error: entityResponse?.errorMessage };
        }
        const { entity, associatedEntities } = entityResponse.response;
        toUpdate = { ...toUpdate, entity, associatedEntities };
      }
      const recordResponse = await getRecordWithAssociations(__entity ?? toUpdate.entity, displayId, nativeObjectData);
      if (!recordResponse.success) {
        return { error: recordResponse.errorMessage };
      }
      toUpdate = { ...toUpdate, record: recordResponse.response };
      return toUpdate;
    },
    [displayId, id, nativeObjectData, getRecordWithAssociations]
  );

  const getEntityAssociations = useCallback(async () => {
    const entityAssociationsResponse = await recordPageController.getEntityAssociations(id, SCHEMA_TYPE.CUSTOM);
    if (entityAssociationsResponse.success) {
      return { isLoading: false, entityAssociations: entityAssociationsResponse.response?.associations };
    } else {
      return { isLoading: false, entityAssociations: [] };
    }
  }, [id]);

  const getInverseAssociationResolvedRecord = useCallback(
    async (inverseAssociationCORecordLookupByFieldName = {}, enabledNativeIdsLookup) => {
      try {
        const inverseAssociationNativeRecord = {};
        const promises = {};
        const recordIds = {};
        const nativeSchemaIds = Object.keys(enabledNativeIdsLookup);
        for (let i = 0; i < nativeSchemaIds.length; i++) {
          const fieldsLookup = inverseAssociationCORecordLookupByFieldName[nativeSchemaIds[i]];
          const fields = Object.keys(fieldsLookup);
          recordIds[nativeSchemaIds[i]] = [];
          for (const field of fields) {
            const __records = fieldsLookup[field];
            recordIds[nativeSchemaIds[i]].push(...__records.map(record => record.source_record_id));
          }
          promises[nativeSchemaIds[i]] = getNativeRecordsMC(enabledNativeIdsLookup[nativeSchemaIds[i]].context, recordIds[nativeSchemaIds[i]], configType);
        }
        await Promise.allSettled(Object.values(promises));
        for (let i = 0; i < nativeSchemaIds.length; i++) {
          promises[nativeSchemaIds[i]].then(res => {
            if (res.success) {
              inverseAssociationNativeRecord[nativeSchemaIds[i]] = res?.response;
            }
          });
        }
        return CommonUtils.buildResponse(true, inverseAssociationNativeRecord);
      } catch (error) {
        console.error(error);
        return CommonUtils.buildErrorResponse(error);
      }
    },
    [getNativeRecordsMC, configType]
  );

  const getInverseEntityAssociations = useCallback(async () => {
    if (!nativeObjectData.loaded) {
      return {
        isLoading: false,
        inverseEntityAssociations: [],
        nativeIdAssociationLookup: {},
        inverseAssociationRecordsLookup: {},
        inverseAssociationResolvedRecordsLookup: {},
      };
    }
    const entityAssociationsResponse = await recordPageController.getInverseEntityAssociations(id);
    if (entityAssociationsResponse.success) {
      const inverseEntityAssociations = entityAssociationsResponse.response?.inverse_associations;
      const nativeIdAssociationLookup = inverseEntityAssociations.reduce((map, association) => {
        map[association.source_object_id] = map[association.source_object_id] ?? {};
        map[association.source_object_id][association.source_field_name] = map[association.source_object_id][association.source_field_name] ?? [];
        map[association.source_object_id][association.source_field_name].push(association);
        return { ...map };
      }, {});
      const nativeIdsInResponse = Object.keys(nativeIdAssociationLookup);
      const enabledNativeIdsLookup = Object.fromEntries(Object.entries(nativeObjectData.idLookup ?? {}).filter(([id]) => nativeIdsInResponse.includes(id)));
      const recordAssociationResponse = await recordPageController.getInverseAssociationRecord(id, displayId, enabledNativeIdsLookup, nativeIdAssociationLookup);
      const inverseAssociationCORecordsByFieldName = recordAssociationResponse.response;
      const resolvedRecordAssociationResponse = await getInverseAssociationResolvedRecord(inverseAssociationCORecordsByFieldName, enabledNativeIdsLookup);
      const inverseAssociationNativeRecord = resolvedRecordAssociationResponse.response;

      return {
        isLoading: false,
        inverseEntityAssociations,
        nativeIdAssociationLookup,
        inverseAssociationRecordsLookup: inverseAssociationCORecordsByFieldName,
        inverseAssociationResolvedRecordsLookup: inverseAssociationNativeRecord,
      };
    } else {
      return { isLoading: false, inverseEntityAssociations: [], nativeIdAssociationLookup: {}, inverseAssociationRecordsLookup: {}, inverseAssociationResolvedRecordsLookup: {} };
    }
  }, [displayId, getInverseAssociationResolvedRecord, id, nativeObjectData.idLookup, nativeObjectData.loaded]);

  const getForwardAssociations = useCallback(async (entity, record) => {
    return await recordPageController.getRecordsForEntities(entity, record);
  }, []);

  const getCoAssociationRecords = useCallback(
    async (associations, record) => {
      try {
        const nativeRecordIdLookup = Object.values(nativeObjectData.idLookup).reduce((map, schema) => {
          return { ...map, [schema.context]: [] };
        }, {});
        const coRecordLookup = {};
        const promises = [];
        const associationLookup = associations.reduce((map, association) => {
          return { ...map, [association.schema.id]: association.schema };
        }, {});
        for (const index in associations) {
          const associatedSchema = associations?.[index]?.schema;
          const fieldNames = associations?.[index]?.field_names;
          const params = {
            page_size: LEGO_CONSTANTS.WIDGET_PAGE_SIZE + 1,
            include: 'associationsExpand',
          };
          for (const fieldName of fieldNames) {
            const fieldParams = { [fieldName]: record?.display_id, ...params };
            promises.push(recordPageController.__getCoAssociationRecords(associatedSchema.id, fieldName, fieldParams));
          }
        }
        const coAssRecordsResponse = await Promise.allSettled(promises);
        const coAssRecordsResponseList = coAssRecordsResponse
          .filter(r => r.status === 'fulfilled')
          .map(r => r.value)
          .filter(r => r.success)
          .map(r => r.response);
        coAssRecordsResponseList.forEach(response => {
          const { schemaId, fieldName, recordAssociations } = response;
          const coRecordAssociations = recordAssociations.records;
          Object.keys(coRecordAssociations ?? {}).forEach((map, recordIndex) => {
            const record = coRecordAssociations[recordIndex];
            const nativeFields = recordPageController.getNativeRelationshipFields(associationLookup[schemaId]);
            const nativeIdentifierToIdsLookup = recordPageController.getAssociatedNativeObjectIdsForRecord(record.data, nativeObjectData, nativeFields);
            for (const [nativeIdentifer, nativeObjectIds] of Object.entries(nativeIdentifierToIdsLookup)) {
              nativeRecordIdLookup[nativeIdentifer] = [...(nativeRecordIdLookup[nativeIdentifer] ?? []), ...nativeObjectIds];
            }
            const { enrichedRecordData: details } = recordPageController.getEnrichRecordData(
              associationLookup[schemaId],
              record,
              nativeObjectData,
              translationFn,
              language,
              timezone,
              configType
            );
            for (let i = 0; i < details.length; i++) {
              if (details[i].fieldType === ENTITY_FIELD_TYPE.RELATIONSHIP) {
                if (details[i].displayValue?.value) {
                  record.data[details[i].fieldName] = details[i].displayValue.value;
                }
              }
            }
          }, {});
          coRecordLookup[schemaId] = coRecordLookup[schemaId] ?? {};
          coRecordLookup[schemaId][fieldName] = { records: coRecordAssociations };
        });
        const nativeAssociations = await getAllNativeObjectAssociations(nativeRecordIdLookup);
        coAssRecordsResponseList.forEach(response => {
          const { schemaId, recordAssociations } = response;
          const coRecordAssociations = recordAssociations.records;
          Object.keys(coRecordAssociations ?? {}).forEach((map, recordIndex) => {
            const record = coRecordAssociations[recordIndex];
            const nativeFieldNamesToContextLookup = recordPageController.getNativeRelationshipFields(associationLookup[schemaId]).reduce((map, field) => {
              const context = nativeObjectData?.idLookup[field.related_entity_id]?.context;
              return { ...map, [field.name]: context };
            }, {});
            Object.keys(record?.data ?? {}).forEach(fieldName => {
              if (nativeFieldNamesToContextLookup[fieldName]) {
                const value = record.data[fieldName];
                const configs = nativeObjectConfigs[configType][nativeFieldNamesToContextLookup[fieldName]];
                record.data[fieldName] = nativeAssociations?.[nativeFieldNamesToContextLookup[fieldName]]?.[value]?.[configs.defaultDisplayField] ?? record.data[fieldName];
              }
            });
          }, {});
        });
        return CommonUtils.buildResponse(true, coRecordLookup);
      } catch (error) {
        console.error(error);
        return CommonUtils.buildErrorResponse(error);
      }
    },
    [nativeObjectData, getAllNativeObjectAssociations, translationFn, language, timezone, configType]
  );

  return useMemo(() => {
    return {
      fetchRecordAndEntityIfNull,
      getEntityAssociations,
      getRelatedCustomEntityIds,
      getWidgetCustomizationsForEntityIds,
      fetchRecord,
      getInverseEntityAssociations,
      getForwardAssociations,
      getCoAssociationRecords,
      getAllNativeObjectAssociations,
      getRecordsLookupById,
    };
  }, [
    fetchRecordAndEntityIfNull,
    getEntityAssociations,
    getRelatedCustomEntityIds,
    getWidgetCustomizationsForEntityIds,
    fetchRecord,
    getInverseEntityAssociations,
    getForwardAssociations,
    getCoAssociationRecords,
    getAllNativeObjectAssociations,
    getRecordsLookupById,
  ]);
};

export default useRecordsPage;
