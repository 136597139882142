import React, { useState, useCallback, useContext, useEffect, useMemo } from 'react';
import { useRecordsPage } from 'hooks';
import { useWidgetContextUpdater } from './widget-context';
const BackwardRefWidgetContext = React.createContext();
export const BackwardRefWidgetUpdaterContext = React.createContext();

const BackwardRefProvider = props => {
  const { loadWidgetCustomizations } = useWidgetContextUpdater();
  const [state, setState] = useState({
    isLoading: true,
    entityAssociations: [],
  });
  const { getEntityAssociations } = useRecordsPage();

  const loadAssociations = useCallback(
    async (forceReload = false) => {
      if (forceReload) {
        setState(prev => {
          return { ...prev, isLoading: true };
        });
      }
      const response = await getEntityAssociations();
      loadWidgetCustomizations(response.entityAssociations.map(association => association.schema.id));
      setState(response);
    },
    [getEntityAssociations, loadWidgetCustomizations]
  );

  useEffect(() => {
    loadAssociations();
  }, [loadAssociations]);

  const updateFunctions = useMemo(() => {
    return {
      loadAssociations,
      setState,
    };
  }, [loadAssociations, setState]);

  return (
    <BackwardRefWidgetContext.Provider value={state}>
      <BackwardRefWidgetUpdaterContext.Provider value={updateFunctions}>{props.children}</BackwardRefWidgetUpdaterContext.Provider>
    </BackwardRefWidgetContext.Provider>
  );
};

const useBackwardRefState = () => {
  const state = useContext(BackwardRefWidgetContext);
  if (!state) {
    throw new Error('useBackwardRefState must be used within a BackwardRefProvider');
  }
  return state;
};

const useBackwardRefUpdater = () => {
  const { setState, loadAssociations } = useContext(BackwardRefWidgetUpdaterContext);
  if (!setState || !loadAssociations) {
    throw new Error('useBackwardRefUpdater must be used within a BackwardRefProvider');
  }

  return { setState, loadAssociations };
};

export { BackwardRefProvider, useBackwardRefState, useBackwardRefUpdater };
