import React from 'react';
import { SEARCH_SERVICE_TYPE, LEGO_CONSTANTS } from 'constants/index';
import Ticket from './Ticket';
import Contact from './Contact';
import EntityRecord from './EntityRecord';

const SelectedRecord = ({ relatedEntity, record, searchType, displayDetails }) => {
  const getContent = record => {
    switch (searchType) {
      case LEGO_CONSTANTS.TICKET: {
        return <Ticket record={record} />;
      }
      case LEGO_CONSTANTS.CONTACT: {
        return <Contact record={record} />;
      }
      case SEARCH_SERVICE_TYPE.ENTITY_RECORD: {
        return <EntityRecord relatedEntity={relatedEntity} record={record} displayDetails={displayDetails} />;
      }
      default:
        return <></>;
    }
  };

  return getContent(record);
};

export default SelectedRecord;
