import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FwButton, FwDataTable, ToastController } from '@freshworks/crayons/react';
import { LEGO_CONSTANTS, SERVICE_TYPE, PERMISSION_TYPE, MESSAGE_CHANNEL_COMM_TYPE } from 'constants/index';
import getService from 'services/serviceDiscovery';
import { useFreshdeskContextState, useNativeObjectsUpdater } from '../../store';
import { DeleteModal } from 'UI';
import { CommonUtils } from 'utils';
import FormBuildController from 'routes/create/formbuilder/FormBuildController';
import EntityBasicDetails from 'components/EntityBuilder/BasicDetails/EntityBasicDetails';
import EntityListHeader from './EntityListHeader/EntityListHeader';
import styles from './EntityList.module.css';
import BlankState from 'UI/BlankState/BlankState';
import emptyCustomObjectImage from '../../images/empty-custom-objects.svg';
import { InteractivityBeacon } from 'react-analytics-collector';
import { useMessageChannelAdapter } from 'hooks';

export default function EntityList({ entities, fetchEntities, error, idLookup }) {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [entity, setEntity] = useState(null);
  const deleteModalRef = useRef(null);
  const toast = ToastController();
  const navigate = useNavigate();
  const { getSchemaPermissions, getRecordPermissions, getUserDetails } = useFreshdeskContextState();
  const { loadNativeObjectsData } = useNativeObjectsUpdater();
  const entityPermissions = getSchemaPermissions();
  const recordPermissions = getRecordPermissions();
  const language = getUserDetails()?.locale?.language;
  const timezone = getUserDetails()?.locale?.timezone;
  const { onSchemaCRUD } = useMessageChannelAdapter();
  const jsonPreset = FormBuildController.getInstance().jsonPresetSchema;
  const { t } = useTranslation();
  FormBuildController.getInstance().i18nTranslate = t;

  const iconSrc = FormBuildController.getInstance().iconCDNUrl;
  const relatedEntitiesArray = entities?.map(entity => ({ id: entity.id, name: entity.name }));
  const relatedEntitiesMap = relatedEntitiesArray?.reduce((obj, item) => {
    return {
      ...obj,
      [item.id]: item.name,
    };
  }, {});

  const ENTITY_COLUMN = [
    {
      key: 'icon',
      text: t('entities.headers.icon'),
      variant: 'icon',
      textAlign: 'center',
      widthProperties: {
        width: '120px',
      },
    },
    {
      key: 'name',
      text: t('entities.headers.objectName'),
      widthProperties: {
        width: '150px',
      },
      customTemplate: (createElement, { href, text }) => {
        return createElement(
          'a',
          {
            style: {
              color: '#2C5CC5',
              textDecoration: 'none',
              fontWeight: '600',
              cursor: 'pointer',
            },
            onClick: e => {
              e.preventDefault();
              navigate(href);
            },
          },
          text
        );
      },
    },
    {
      key: 'description',
      text: t('entities.headers.description'),
      widthProperties: {
        width: '500px',
        overflow: 'hidden',
        wordWrap: 'break-word',
      },
    },
    {
      key: 'associated_objects',
      text: t('entities.headers.relatedObjects'),
      widthProperties: {
        width: '300px',
      },
    },
    {
      key: 'created_time',
      text: t('entities.headers.createdAt'),
      widthProperties: {
        width: '164px',
      },
    },
  ];

  const openDeleteModalHandler = entity => {
    setEntity(entity);
    deleteModalRef?.current?.open();
  };

  const onDeleteEntityHandler = async id => {
    const service = getService(SERVICE_TYPE.ENTITY);
    service
      .delete({ id })
      .then(() => {
        const context = { action: MESSAGE_CHANNEL_COMM_TYPE.DELETED, resource: MESSAGE_CHANNEL_COMM_TYPE.RESOURCES.SCHEMA, parameters: { id } };
        onSchemaCRUD(context);
        deleteModalRef?.current?.close();
        toast.trigger({
          type: 'success',
          content: t('entities.deleteCustomObjectWithSuccess'),
        });
        setEntity(null);
        fetchEntities();
      })
      .catch(({ data: { message, errors } }) => {
        if (errors && errors[0].message === LEGO_CONSTANTS.DELETE_CUSTOM_OBJECTS_WITH_RECORDS_ERROR) {
          deleteModalRef?.current?.close();
          toast.trigger({
            type: 'error',
            content: t('entities.deleteCustomObjectWithRecordsError'),
          });
          setEntity(null);
        } else {
          deleteModalRef?.current?.close();
          toast.trigger({
            type: 'error',
            content: message ?? t('entities.deleteCustomObjectWithResponseFailure'),
          });
          setEntity(null);
        }
      });
  };

  const getAssociatedObjectsList = objects => {
    if (!objects) return LEGO_CONSTANTS.EMPTY_DEFAULT_DATA;
    const associatedObjectsArray = objects
      .filter(object => object.type === LEGO_CONSTANTS.RELATIONSHIP)
      ?.map(object => relatedEntitiesMap[object.related_entity_id] ?? idLookup[object.related_entity_id]?.name)
      ?.filter(Boolean);
    const associatedObjects = [...new Set(associatedObjectsArray)]?.join(', ');
    return associatedObjects.length === 0 ? LEGO_CONSTANTS.EMPTY_DEFAULT_DATA : associatedObjects;
  };

  const ModalDescription = ({ entity }) => t('entities.deleteEntityModalDescriptionWithNoAssociatedObjects', { entityName: entity?.title ?? entity?.name?.text });
  const getDisplayIdWiseEntityData = entities => {
    const sortedByCreatedAtEntities = entities?.sort((a, b) => b.created_time - a.created_time);
    return sortedByCreatedAtEntities?.map(entity => {
      const iconName = CommonUtils.getIconNamefromUrl(entity?.icon_link);
      const rowInfo = {
        ...entity,
        created_time: CommonUtils.timeSince(entity.created_time, t, language ?? LEGO_CONSTANTS.US_TIME_ZONE, timezone),
        icon: { name: iconName || jsonPreset.iconSet?.[0].name || '', src: iconSrc, color: '#12344D', size: 18 },
        associated_objects: getAssociatedObjectsList(entity?.fields),
      };
      rowInfo.name = {
        text: rowInfo.name,
        href: `${LEGO_CONSTANTS.ROUTE_PREFIX}/schemas/edit/${entity.id}`,
      };
      return rowInfo;
    });
  };

  const getRowActions = () => {
    const ROW_ACTIONS = [];

    entityPermissions.isPresent(PERMISSION_TYPE.UPDATE) &&
      ROW_ACTIONS.push({
        name: t('entities.editObject'),
        iconName: 'edit',
        handler: entity => navigate(`${LEGO_CONSTANTS.ROUTE_PREFIX}/schemas/edit/${entity.id}`),
      });
    entityPermissions.isPresent(PERMISSION_TYPE.READ) &&
      recordPermissions.isPresent(PERMISSION_TYPE.READ) &&
      ROW_ACTIONS.push({
        name: t('entities.viewRecords'),
        iconName: 'visible',
        handler: entity => navigate(`${LEGO_CONSTANTS.ROUTE_PREFIX}/schemas/${entity.id}/records`),
      });
    entityPermissions.isPresent(PERMISSION_TYPE.DELETE) &&
      ROW_ACTIONS.push({
        name: t('entities.deleteObject'),
        iconName: 'delete',
        handler: entity => openDeleteModalHandler(entity),
      });

    return ROW_ACTIONS;
  };

  const onCreateNewObjectHandler = () => {
    setShowCreateModal(true);
  };

  const onSubmitBasicDetailsHandler = async objDetail => {
    // Create new custom object
    const objApiResponse = await FormBuildController.getInstance().createNewEntity(objDetail);
    const boolSuccess = objApiResponse?.success;
    setShowCreateModal(false);

    if (boolSuccess) {
      toast.trigger({
        type: 'success',
        content: t('createEntity.toast.newEntity.success'),
      });
      const context = {
        action: MESSAGE_CHANNEL_COMM_TYPE.CREATED,
        resource: MESSAGE_CHANNEL_COMM_TYPE.RESOURCES.SCHEMA,
        parameters: { id: objApiResponse.response.id },
      };
      onSchemaCRUD(context);
      loadNativeObjectsData();
      navigate(`${LEGO_CONSTANTS.ROUTE_PREFIX}/schemas/edit/${objApiResponse?.response.id}`);
    } else {
      toast.trigger({
        type: 'error',
        content: objApiResponse.errorMessage,
      });
    }
  };

  const onCloseBasicDetailsHandler = () => {
    setShowCreateModal(false);
  };

  const ROW_ACTIONS = getRowActions();

  useEffect(() => {
    FormBuildController.getInstance().destroy();
  }, []);

  const areEntitiesPresent = entities && entities.length > 0;

  const blankHeaderDetails = error => {
    if (error) {
      if (error?.name === LEGO_CONSTANTS.ERRORS.PERMISSION) {
        return {
          heading: t('entities.error.permissionErrorMessage'),
          description: '',
        };
      } else if (error?.error_type === LEGO_CONSTANTS.RESOURCE_NOT_FOUND && error?.message === LEGO_CONSTANTS.TENANT_NOT_FOUND) {
        return {
          heading: t('entities.customObjects'),
          description: t('entities.createCustomObjectDescription'),
        };
      } else {
        return {
          header: error?.message,
          description: '',
        };
      }
    }
    return {
      heading: t('entities.customObjects'),
      description: t('entities.createCustomObjectDescription'),
    };
  };

  return (
    <>
      {areEntitiesPresent ? (
        <>
          <EntityListHeader onCreateNewObjectHandler={onCreateNewObjectHandler} />
          <div className={styles['entities-container']}>
            <InteractivityBeacon />
            <FwDataTable columns={ENTITY_COLUMN} rowActions={ROW_ACTIONS} rows={getDisplayIdWiseEntityData(entities)} label={t('entities.customObjects')} />
          </div>
          <DeleteModal
            ref={deleteModalRef}
            onDeleteHandler={e => {
              e.preventDefault();
              onDeleteEntityHandler(entity.id);
            }}
            heading={`${t('entities.deleteEntityModalHeading', { entityName: entity?.title ?? entity?.name?.text })}`}
            description={<ModalDescription entity={entity} />}
          />
        </>
      ) : (
        <>
          <InteractivityBeacon />
          <BlankState
            heading={blankHeaderDetails(error).heading}
            description={blankHeaderDetails(error).description}
            showActions={entities !== null}
            singleAction={true}
            image={emptyCustomObjectImage}
            blankContainer={true}
          >
            <FwButton onFwClick={onCreateNewObjectHandler} color="primary">
              {t('entities.createObject')}
            </FwButton>
          </BlankState>
        </>
      )}
      {showCreateModal && (
        <EntityBasicDetails
          iconPrefixUrl={FormBuildController.getInstance().iconCDNUrl}
          entitySchema={{ icon_link: jsonPreset?.iconSet?.[0].name }}
          isSlider={false}
          jsonPreset={jsonPreset}
          isOpen={showCreateModal}
          titleText={t('createEntity.basicDetailsCreateHeader')}
          submitText={t('createEntity.basicDetailsCreateEntityBtn')}
          cancelText={t('createEntity.basicDetailsCancelBtn')}
          onSubmit={onSubmitBasicDetailsHandler}
          onClose={onCloseBasicDetailsHandler}
        />
      )}
    </>
  );
}
