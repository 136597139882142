import EntityController from './EntityController';
import { PERMISSION_TYPE, SERVICE_TYPE, LEGO_CONSTANTS } from 'constants/index';
import getService from 'services/serviceDiscovery';
class RecordPageController extends EntityController {
  constructor() {
    super();
    this.entityAssociationService = getService(SERVICE_TYPE.ENTITY_ASSOCATION);
    this.entityRecordAssociationService = getService(SERVICE_TYPE.ENTITY_RECORD_ASSOCATION);
  }

  getEntityInformation = async id => {
    const response = { entity: null, associatedEntities: null };
    const entityResponse = await this.getEntity(id);
    if (!entityResponse.success) return entityResponse;
    response.entity = entityResponse.response;
    const associatedEntitiesResponse = await this.getAssociatedEntites(response.entity);
    if (associatedEntitiesResponse.success) response.associatedEntities = associatedEntitiesResponse.response;
    return this.buildResponse(true, response);
  };

  __getRecord = async (id, displayId, params = {}) => {
    if (!this.hasRecordPermission(PERMISSION_TYPE.READ)) throw this.constructErrorObject(LEGO_CONSTANTS.NO_RECORD_READ_PERMISSION);
    return await this.recordService.get({ id, displayId }, params);
  };

  getRecord = async (id, displayId, params = {}) => {
    try {
      const record = await this.__getRecord(id, displayId, params);
      return this.buildResponse(true, record);
    } catch (error) {
      console.error(error);
      return this.buildErrorResponse(error);
    }
  };

  getAssociatedNativeObjectIdsForRecord = (record, nativeObjectData, nativeFields) => {
    const nativeIdentifierToIdsLookup = {};
    const nameToNativeLookup = nativeFields.reduce((map, field) => {
      let nativeIdentifier = null;
      if (nativeObjectData?.idLookup[field.related_entity_id]) {
        nativeIdentifier = nativeObjectData?.idLookup[field.related_entity_id]?.context;
      }
      return { ...map, [field.name]: nativeIdentifier };
    }, {});
    for (const fieldName of Object.keys(record)) {
      if (!record[fieldName] || !nameToNativeLookup[fieldName]) continue;
      nativeIdentifierToIdsLookup[nameToNativeLookup[fieldName]] = nativeIdentifierToIdsLookup[nameToNativeLookup[fieldName]] ?? [];
      nativeIdentifierToIdsLookup[nameToNativeLookup[fieldName]].push(record[fieldName]);
    }
    return nativeIdentifierToIdsLookup;
  };

  getRecordsForEntities = async (entity, record) => {
    const entityIdLookupByName = this.getRelatedEntityIdLookup(this.getCustomRelationshipFields(entity));
    const displayIdToEntityIdLookup = this.__getEntityIdByDisplayIdLookup(record, entityIdLookupByName);
    return await this.getRecordsInBulk(displayIdToEntityIdLookup);
  };

  __getEntityAssociations = async params => {
    if (!this.hasEntityPermission(PERMISSION_TYPE.READ)) throw this.constructErrorObject(LEGO_CONSTANTS.NO_SCHEMA_READ_PERMISSION);
    return await this.entityAssociationService.getAll({}, params);
  };

  getEntityAssociations = async (targetObjectId, targetObjectType) => {
    try {
      const entityAssociations = await this.__getEntityAssociations({ target_object_id: targetObjectId, target_object_type: targetObjectType });
      return this.buildResponse(true, entityAssociations);
    } catch (error) {
      console.error(error);
      return this.buildErrorResponse(error);
    }
  };

  __getInverseEntityAssociations = async params => {
    if (!this.hasEntityPermission(PERMISSION_TYPE.READ)) throw this.constructErrorObject(LEGO_CONSTANTS.NO_SCHEMA_READ_PERMISSION);
    const apiService = getService(SERVICE_TYPE.ENTITY_INVERSE_ASSOCIATION);
    return await apiService.getAll({}, params);
  };

  getInverseEntityAssociations = async entityId => {
    try {
      const entityAssociations = await this.__getInverseEntityAssociations({ schema_id: entityId });
      return this.buildResponse(true, entityAssociations);
    } catch (error) {
      console.error(error);
      return this.buildErrorResponse(error);
    }
  };

  __getInverseAssociationRecord = async (nativeSchemaId, fieldName, params = {}) => {
    try {
      const apiService = getService(SERVICE_TYPE.ENTITY_INVERSE_RECORD_ASSOCIATION);
      const entityAssociations = await apiService.getAll({}, params);
      return this.buildResponse(true, { nativeSchemaId, fieldName, entityAssociations });
    } catch (error) {
      console.error(error);
      return this.buildErrorResponse(error);
    }
  };

  getInverseAssociationRecord = async (entityId, recordDisplayId, enabledNativeIdsLookup, nativeIdAssociationLookup) => {
    try {
      const inverseAssociationCORecord = {};
      const promises = [];
      const nativeSchemaIds = Object.keys(enabledNativeIdsLookup);
      for (let i = 0; i < nativeSchemaIds.length; i++) {
        const nativeSchemaId = nativeSchemaIds[i];
        const fields = Object.keys(nativeIdAssociationLookup[nativeSchemaId]);
        for (const field of fields) {
          promises.push(
            this.__getInverseAssociationRecord(nativeSchemaId, field, {
              schema_id: entityId,
              source_object_id: nativeSchemaId,
              display_id: recordDisplayId,
              source_field_name: field,
              page_size: LEGO_CONSTANTS.REVERSE_WIDGET_PAGE_SIZE + 1,
            })
          );
        }
      }
      const inverseAssRecordsResponse = await Promise.allSettled(promises);
      const inverseAssRecordsResponseList = inverseAssRecordsResponse
        .filter(r => r.status === 'fulfilled')
        .map(r => r.value)
        .filter(r => r.success)
        .map(r => r.response);
      inverseAssRecordsResponseList.forEach(response => {
        const { nativeSchemaId, fieldName, entityAssociations } = response;
        const inverseRecordAssociations = entityAssociations.inverse_record_associations;
        inverseAssociationCORecord[nativeSchemaId] = inverseAssociationCORecord[nativeSchemaId] ?? {};
        inverseAssociationCORecord[nativeSchemaId][fieldName] = inverseRecordAssociations;
      });
      return this.buildResponse(true, inverseAssociationCORecord);
    } catch (error) {
      console.error(error);
      return this.buildErrorResponse(error);
    }
  };

  __getCoAssociationRecords = async (schemaId, fieldName, fieldParams = {}) => {
    try {
      const recordAssociations = await recordPageController.getEntityRecords({ id: schemaId }, fieldParams);
      return this.buildResponse(true, { schemaId, fieldName, recordAssociations: recordAssociations.response });
    } catch (error) {
      console.error(error);
      return this.buildErrorResponse(error);
    }
  };

  deleteRecord = async (id, displayId) => {
    try {
      await this.__deleteRecord(id, displayId);
      return this.buildResponse(true);
    } catch (error) {
      console.error(error);
      return this.buildErrorResponse(error);
    }
  };

  __getNativeEntityRecordAssociations = async params => {
    if (!this.hasRecordPermission(PERMISSION_TYPE.READ)) throw this.constructErrorObject(LEGO_CONSTANTS.NO_RECORD_READ_PERMISSION);
    return await this.entityRecordAssociationService.getAll({}, params);
  };

  getNativeEntityRecordAssociations = async (targetObjectId, relatedEntityId, relatedRecordId, pageSize) => {
    try {
      const entityRecordAssociations = await this.__getNativeEntityRecordAssociations({
        schema_id: relatedEntityId,
        display_id: relatedRecordId,
        target_object_id: targetObjectId,
        page_size: pageSize,
      });
      return this.buildResponse(true, entityRecordAssociations);
    } catch (error) {
      console.error(error);
      return this.buildErrorResponse(error);
    }
  };

  __getEntityRecords = async (ids, params) => {
    if (!this.hasRecordPermission(PERMISSION_TYPE.READ)) throw this.constructErrorObject(LEGO_CONSTANTS.NO_RECORD_READ_PERMISSION);
    return await this.recordService.getAll(ids, params);
  };

  getEntityRecords = async (ids, params) => {
    try {
      const records = await this.__getEntityRecords(ids, params);
      return this.buildResponse(true, records);
    } catch (error) {
      console.error(error);
      return this.buildErrorResponse(error);
    }
  };

  __deleteRecord = async (id, displayId) => {
    if (!this.hasRecordPermission(PERMISSION_TYPE.DELETE)) throw this.constructErrorObject(LEGO_CONSTANTS.NO_RECORD_DELETE_PERMISSION);
    return await this.recordService.delete({ id, displayId });
  };

  deleteRecord = async (id, displayId) => {
    try {
      await this.__deleteRecord(id, displayId);
      return this.buildResponse(true);
    } catch (error) {
      console.error(error);
      return this.buildErrorResponse(error);
    }
  };

  __getEntityIdByDisplayIdLookup = (record, entityIdLookupByName = {}) => {
    const lookup = {};
    const entityNames = Object.keys(entityIdLookupByName);
    entityNames.forEach(entityName => {
      const recordKeys = Object.keys(record?.data ?? {});
      recordKeys
        .filter(key => key === entityName)
        .forEach(key => {
          lookup[entityIdLookupByName[key]] = lookup?.[entityIdLookupByName?.[key]] ?? [];
          if (record?.data[key]) lookup[entityIdLookupByName[key]].push(record.data[key]);
        });
    });
    return lookup;
  };
}

export const recordPageController = new RecordPageController();
