import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PageLoader } from 'UI';
import { useMessageChannelAdapter } from 'hooks';
import { SCHEMA_TYPE } from 'constants/index';

/**
 * This component has been introduced as a hack to support and
 * custom / native redirections.
 * Uses:
 *  - Custom redirection: (for changing state in the context and redirecting from RecordPage to RecordPage)
 *      navigate('/redirect', { state: { type: 'CUSTOM', path: '${LEGO_CONSTANTS.ROUTE_PREFIX}/schemas/${schemaId}/records/${displayId}' } });
 *  - Native redirection:
 *      navigate('/redirect', { state: { type: 'NATIVE', resource: 'tickets', parameters: { id: 1 } } });
 */
function Redirect() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { navigate: navigateMC } = useMessageChannelAdapter();
  useEffect(() => {
    const { type, resource, parameters, ...options } = state;
    if (type === SCHEMA_TYPE.CUSTOM) {
      navigate(state?.path);
    } else {
      const context = {
        resource,
        parameters,
        ...options,
      };
      navigateMC(context);
    }
  }, [navigate, state, navigateMC]);

  return <PageLoader />;
}

export default Redirect;
