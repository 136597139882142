const ENTITY_FIELD_TYPE = {
  PARAGRAPH: 'PARAGRAPH',
  PRIMARY: 'PRIMARY',
  RELATIONSHIP: 'RELATIONSHIP',
  TEXT: 'TEXT',
  NUMBER: 'NUMBER',
  DECIMAL: 'DECIMAL',
  DATE: 'DATE',
  DROPDOWN: 'DROPDOWN',
  CHECKBOX: 'CHECKBOX',
  MULTI_SELECT: 'MULTI_SELECT',
  CREATED_AT: 'CREATED_AT',
  UPDATED_AT: 'UPDATED_AT',
};

export default ENTITY_FIELD_TYPE;
