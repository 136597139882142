import { CommonUtils } from 'utils';
import nativeObjectConfigs from 'configs/nativeObjectConfigs';
import { RestService, getParentAppApiPath } from './lib';

// All the parent app related private API calls should go through this adapter
class NativeObjectsPrivateApiAdapter extends RestService {
  getFieldDetailsFor = async (nativeObjIdentifier, configType = 'default') => {
    try {
      const config = nativeObjectConfigs[configType][nativeObjIdentifier];
      const url = getParentAppApiPath(config.fieldsApi);
      const response = await this.sendRequest(url);
      return CommonUtils.buildResponse(true, { meta: nativeObjIdentifier, response });
    } catch (error) {
      console.error(error);
      return CommonUtils.buildErrorResponse(error);
    }
  };

  getRecords = async (nativeObjIdentifier, recordIds = [], configType = 'default') => {
    try {
      const config = nativeObjectConfigs[configType][nativeObjIdentifier];
      const __recordIds = config.hasBigIntegerIds ? recordIds.map(id => CommonUtils.convertValueToBigIntString(id)) : recordIds;
      const params = { [config.bulkGetIdParamsKey]: __recordIds, ...config.bulkGetParams };
      const url = getParentAppApiPath(config.bulkGetApi, {}, params);
      const response = await this.sendRequest(url);
      return CommonUtils.buildResponse(true, response);
    } catch (error) {
      console.error(error);
      return CommonUtils.buildErrorResponse(error);
    }
  };

  search = async (nativeObjIdentifier, params = {}, configType = 'default') => {
    try {
      const config = nativeObjectConfigs[configType][nativeObjIdentifier];
      const url = getParentAppApiPath(config.searchApi, {}, params);
      const response = await this.sendRequest(url);
      return CommonUtils.buildResponse(true, response);
    } catch (error) {
      console.error(error);
      return CommonUtils.buildErrorResponse(error);
    }
  };
}

export const nativeObjectsPrivateApiAdapter = new NativeObjectsPrivateApiAdapter();
