import { SERVICE_TYPE, LEGO_CONSTANTS } from 'constants/index';
import { cloneDeep } from 'lodash-es';
import { propertyStore } from 'store';
import getService from 'services/serviceDiscovery';
import JsonEntityPresetSchema from './assets/create-entity-preset';
import { CommonUtils } from 'utils';
export default class FormBuildController {
  constructor() {
    this._entityID = -1;
    this._dictLookupTargets = null;
    this._arrCustomizedWidgetFields = null;
  }

  // singleton class instance
  static getInstance() {
    if (!this.instance) {
      this.instance = new FormBuildController();
    }
    return this.instance;
  }

  cloneObject(objSource) {
    try {
      if (objSource) {
        return cloneDeep(objSource);
      }
      return {};
    } catch (error) {
      console.error('Error cloning object - ' + error);
      return {};
    }
  }

  get i18nTranslate() {
    return this._i18nTranslate;
  }

  set i18nTranslate(value) {
    this._i18nTranslate = value;
  }

  // get the CDN icon prefix URL
  get iconCDNUrl() {
    return `${propertyStore.get(LEGO_CONSTANTS.CDN_PATH)}/${LEGO_CONSTANTS.ICONS}`;
  }

  // return all the default field types supported in "form-builder-field-types.json"
  get jsonPresetSchema() {
    return this.cloneObject(JsonEntityPresetSchema);
  }

  // returns the custom object schema returned from server
  get jsonApiResponse() {
    if (this._dictEntity) {
      if (!this._dictEntity?.fields?.length > 0) {
        const objDefaultField = this.getDefaultPrimaryFieldSchema(this._dictEntity?.name);
        this._dictEntity = { ...this._dictEntity, fields: [objDefaultField] };
      }
      return { ...this._dictEntity };
    }
    return null;
  }

  // returns the custom object id
  get entityID() {
    return this._entityID;
  }

  // check if the entity id is valid
  get isValidEntityID() {
    if (this._entityID !== undefined && !isNaN(this._entityID) && this._entityID > 0) {
      return true;
    }
    return false;
  }

  get customizeWidgetFields() {
    this.removeCustomisedFieldIfNotPresentInEntity();
    return this.cloneObject(this._arrCustomizedWidgetFields);
  }

  destroy = () => {
    this._entityID = -1;
    this._dictEntity = null;
    this._arrCustomizedWidgetFields = null;
  };

  hasCustomProperty = (objSource, strProperty) => {
    if (objSource && strProperty && strProperty !== '' && Object.prototype.hasOwnProperty.call(objSource, strProperty)) {
      return true;
    }
    return false;
  };

  // Get default field schema based on the type from the presets json passed
  getDefaultPrimaryFieldSchema = (strFieldName = '') => {
    try {
      const objFieldSchema = cloneDeep(this.jsonPresetSchema.defaultPrimaryFieldBuilderSchema);
      objFieldSchema.label = this._i18nTranslate ? this._i18nTranslate('createEntity.primaryFieldDefaultLabel') : 'Name'; // strFieldName;
      objFieldSchema.isNew = true;
      objFieldSchema.id = 'new-field';
      return objFieldSchema;
    } catch (error) {}
    return null;
  };

  // Get default field schema based on the type from the presets json passed
  getDefaultFieldSchema = (strType, isPrimary = false, strFieldName = '') => {
    try {
      let objDefaultFieldSchema;
      if (isPrimary) {
        objDefaultFieldSchema = cloneDeep(this.jsonPresetSchema.defaultPrimaryFieldBuilderSchema);
        objDefaultFieldSchema.label = strFieldName;
      } else {
        objDefaultFieldSchema = cloneDeep(this.jsonPresetSchema.fieldTypes[strType]);
      }
      objDefaultFieldSchema.isNew = true;
      objDefaultFieldSchema.id = 'new-field';
      return objDefaultFieldSchema;
    } catch (error) {}
    return null;
  };

  // format to return to the form builder after api calls
  buildResponseObject = (boolSuccess = false, objResponse = null, errorMessage = '') => {
    return { success: boolSuccess, response: objResponse, errorMessage: errorMessage };
  };

  // function to build error message and return the error to be displayed
  buildErrorResponseObject = (objError, objResponse = null) => {
    const objErrorData = objError && objError.data ? objError.data : null;
    let strErrorMessage = '';
    if (
      this.hasCustomProperty(objErrorData, 'errors') &&
      objErrorData.errors &&
      objErrorData.errors.length > 0 &&
      this.hasCustomProperty(objErrorData.errors[0], 'message') &&
      objErrorData.errors[0].message !== ''
    ) {
      strErrorMessage = objErrorData.errors[0].message;
    } else if (this.hasCustomProperty(objErrorData, 'message') && objErrorData.message && objErrorData.message !== '') {
      strErrorMessage = objErrorData.message;
    }
    return this.buildResponseObject(false, objResponse, strErrorMessage);
  };

  // function to check if the widget ids are present in the Entity fields
  removeCustomisedFieldIfNotPresentInEntity = () => {
    if (!this._arrCustomizedWidgetFields || this._arrCustomizedWidgetFields.length <= 0) {
      return null;
    }

    if (this._dictEntity && this._dictEntity?.fields) {
      const arrFields = this._dictEntity.fields;
      const intLength = this._arrCustomizedWidgetFields.length;
      for (let i1 = intLength - 1; i1 >= 0; i1--) {
        const strWidgetFieldID = this._arrCustomizedWidgetFields[i1];
        const intWidgetFieldIndex = arrFields.findIndex(el => el.id === strWidgetFieldID);
        if (intWidgetFieldIndex === -1) {
          this._arrCustomizedWidgetFields.splice(i1, 1);
        }
      }
    }
  };

  // function to bring the primary field on top
  bringPrimaryFieldOnTop = arrFields => {
    if (arrFields && arrFields.length > 1) {
      if (arrFields[0].type !== 'PRIMARY') {
        const intPrimaryIndex = arrFields.findIndex(el => el.type === 'PRIMARY');
        if (intPrimaryIndex > 0) {
          const arrPrimaryField = arrFields.splice(intPrimaryIndex, 1);
          arrFields.unshift(arrPrimaryField[0]);
        }
      }
    }
    return arrFields;
  };

  // get Existing custom object details
  fetchEntity = async numID => {
    if (numID > 0) {
      this._entityID = parseInt(numID);

      try {
        const apiService = getService(SERVICE_TYPE.ENTITY);
        let objApiResponse = await apiService.get({ id: numID });
        if (this.hasCustomProperty(objApiResponse, 'id') && !isNaN(objApiResponse.id) && objApiResponse.id > 0) {
          this._entityID = objApiResponse.id;
          // condition to check if the primary field is added
          if (!objApiResponse?.fields?.length > 0) {
            const objDefaultField = this.getDefaultPrimaryFieldSchema(objApiResponse?.name);
            objApiResponse = { ...objApiResponse, fields: [objDefaultField] };
          }
          this.bringPrimaryFieldOnTop(objApiResponse.fields);
          this._dictEntity = { ...objApiResponse };
          return this.buildResponseObject(true, { type: 'ENTITY', value: objApiResponse.id });
        } else {
          this._entityID = -1;
          return this.buildResponseObject(false, { type: 'ENTITY', value: null });
        }
      } catch (error) {
        this._entityID = -1;
        console.error('Error fetching entity - ' + error);
        return this.buildErrorResponseObject(error, { type: 'ENTITY', value: null });
      }
    }
  };

  // update the entity icon link url
  updateEntityIcon = (objEntity, strIcon) => {
    if (objEntity && strIcon && strIcon !== '') {
      const arrIconUrl = strIcon.split('/');
      if (arrIconUrl && arrIconUrl.length > 0) {
        const arrIconName = arrIconUrl[arrIconUrl.length - 1].split('.');
        objEntity.icon_link = `${this.iconCDNUrl}/${arrIconName[0]}.svg`;
      }
    }
  };

  // delete local field which was added if there are no fields present
  deleteNewFieldFromPayload = objPayload => {
    if (objPayload) {
      try {
        const arrFields = objPayload.fields;
        const intNewFieldIndex = arrFields.findIndex(e => e.id === 'new-field' && e.isNew === true);
        if (intNewFieldIndex > -1) {
          arrFields.splice(intNewFieldIndex, 1);
        }
      } catch (error) {}
    }
  };

  // update local object basic details
  getEntityPayload = objDetail => {
    const objEntity = objDetail.value;
    const objPayload = this.cloneObject(this._dictEntity);
    objPayload.name = objEntity.name;
    objPayload.description = objEntity.description;
    this.updateEntityIcon(objPayload, objEntity.icon_link);
    this.deleteNewFieldFromPayload(objPayload);
    return objPayload;
  };

  // create a new custom object
  createNewEntity = async objDetail => {
    try {
      this._dictEntity = this.cloneObject(this.jsonPresetSchema.defaultEntitySchema);
      const objPayload = this.getEntityPayload(objDetail);

      const apiService = getService(SERVICE_TYPE.ENTITY);
      let objApiResponse = await apiService.create({}, objPayload);

      if (this.hasCustomProperty(objApiResponse, 'id') && !isNaN(objApiResponse.id) && objApiResponse.id > 0) {
        this._entityID = objApiResponse.id;
        // condition to check if the primary field is added
        if (!objApiResponse?.fields?.length > 0) {
          const objDefaultField = this.getDefaultPrimaryFieldSchema(objApiResponse?.name);
          objApiResponse = { ...objApiResponse, fields: [objDefaultField] };
        }
        this._dictEntity = { ...objApiResponse };
        return this.buildResponseObject(true, { type: 'CREATE_NEW_ENTITY', id: objApiResponse.id, fieldIndex: -1 });
      } else {
        return this.buildResponseObject(false);
      }
    } catch (error) {
      console.error('Error creating entity - ' + error);
      return this.buildErrorResponseObject(error);
    }
  };

  // format name - remove the special characters and replace space with underscore for the name
  formatName = strInput => {
    if (strInput && strInput !== '') {
      const strRemovedSpecialChars = strInput.replace(/[^a-zA-Z0-9 ]/g, '');
      const strFormattedName = strRemovedSpecialChars.split(' ').join('_').toLowerCase();
      return strFormattedName;
    }
  };

  // function to update the entire custom object
  updateEntity = async objPayload => {
    try {
      const apiService = getService(SERVICE_TYPE.ENTITY);
      delete objPayload.id;

      // Fallback to check if the icon is not set to the entity or to set the URL instead of just name
      if (!objPayload.icon_link || objPayload.icon_link === '' || objPayload.icon_link.indexOf('.svg') === -1) {
        this.updateEntityIcon(objPayload, this.jsonPresetSchema.iconSet[0].name);
      }

      // Falback to check if the primary field is not present in the entity
      const arrFields = this.hasCustomProperty(objPayload, 'fields') && objPayload.fields ? objPayload.fields : [];
      if (arrFields && arrFields.length > 0) {
        const arrPrimaryField = arrFields.filter(dataItem => Object.prototype.hasOwnProperty.call(dataItem, 'type') && dataItem.type === 'PRIMARY');
        if (!arrPrimaryField || arrPrimaryField.length === 0) {
          arrFields[0] = { ...arrFields[0], type: 'PRIMARY' };
        }
      }

      let objApiResponse = await apiService.put({ id: this._entityID }, objPayload);
      if (this.hasCustomProperty(objApiResponse, 'id') && !isNaN(objApiResponse.id) && objApiResponse.id > 0) {
        this._entityID = objApiResponse.id;
        // condition to check if the primary field is added
        if (!objApiResponse?.fields?.length > 0) {
          const objDefaultField = this.getDefaultPrimaryFieldSchema(objApiResponse?.name);
          objApiResponse = { ...objApiResponse, fields: [objDefaultField] };
        }
        this._dictEntity = { ...objApiResponse };
        return this.buildResponseObject(true, { type: 'UPDATE_ENTITY', id: objApiResponse.id });
      } else {
        return this.buildResponseObject(false);
      }
    } catch (error) {
      return this.buildErrorResponseObject(error);
    }
  };

  // function to create new field / update existing field details
  getFieldPayload = objDetail => {
    const objFormValues = this.cloneObject(objDetail.value);
    const boolNewField = objDetail.isNew;
    const strFieldType = objFormValues.type;
    const isPrimaryField = !!(this.hasCustomProperty(objFormValues, 'isPrimaryField') && objFormValues.isPrimaryField === true);

    const intIndex = this.hasCustomProperty(objDetail, 'index') ? objDetail.index : -1;
    let intAddedIndex = intIndex;
    let objUpdatedField = null;

    let objPayload = this.cloneObject(this._dictEntity);
    this.deleteNewFieldFromPayload(objPayload);

    if (boolNewField) {
      objUpdatedField = this.jsonPresetSchema.defaultFieldSchema;
      if (strFieldType === 'DECIMAL') {
        objUpdatedField.searchable = false;
      }
    } else {
      if (this.hasCustomProperty(objPayload, 'fields') && objPayload.fields && intAddedIndex >= 0 && intAddedIndex < objPayload.fields.length) {
        objUpdatedField = objPayload.fields[intAddedIndex];
      } else {
        console.error('Field not found in entity object..');
        return null;
      }
    }

    const strFieldLabel = objFormValues.name;
    let boolUpdateUniqueValue = true;
    if (strFieldType === 'RELATIONSHIP') {
      // update lookup relationship data if its a new Field
      if (boolNewField) {
        const objRelationshipValues = objFormValues.relationship;
        const objRelatedEntity = this._dictLookupTargets[objRelationshipValues.target];
        objUpdatedField.related_entity_id = objRelatedEntity.id;
        objUpdatedField.relationship_name = this.formatName(strFieldLabel); // needs to be unique within the entity
        objUpdatedField.child_relationship_name = `${this.formatName(objPayload.name)}_${new Date().getTime()}`; // needs to be unique within the entity
        objUpdatedField.field_options.unique = objRelationshipValues.relationship === 'one_to_one';

        // set meta for native target objects
        if (this.hasCustomProperty(objRelatedEntity, 'isNative') && objRelatedEntity.isNative) {
          objUpdatedField.field_options.related_object_type = 'NATIVE';
        }
      }
      boolUpdateUniqueValue = false;
    }

    objUpdatedField.type = !isPrimaryField ? strFieldType : 'PRIMARY';
    objUpdatedField.label = strFieldLabel;
    objUpdatedField.required = objFormValues.required;
    objUpdatedField.filterable = this.hasCustomProperty(objFormValues, 'filterable') ? objFormValues.filterable : false;
    objUpdatedField.choices = this.hasCustomProperty(objFormValues, 'choices') && objFormValues.choices && objFormValues.choices.length > 0 ? [...objFormValues.choices] : [];

    if (boolUpdateUniqueValue) {
      objUpdatedField.field_options.unique = this.hasCustomProperty(objFormValues, 'unique') ? objFormValues.unique : false;
    }

    let arrFields = this.hasCustomProperty(objPayload, 'fields') && objPayload.fields ? objPayload.fields : [];
    if (boolNewField) {
      // validate if the array is empty or the passed index is invalid - if true -push the element at the end of the array
      if (arrFields.length === 0 || intIndex < 0 || intIndex > arrFields.length) {
        arrFields = [...arrFields, objUpdatedField];
        objPayload = { ...objPayload, fields: arrFields };
        intAddedIndex = arrFields.length - 1;
      } else {
        // store the element at the passed index
        arrFields = [...arrFields.slice(0, intIndex), objUpdatedField, ...arrFields.slice(intIndex)];
        objPayload = { ...objPayload, fields: arrFields };
        intAddedIndex = intIndex;
      }
    }
    return objPayload;
  };

  // Function to change the position of the fields in the local store
  getRepositionFieldPayload = objDetail => {
    const intSourceIndex = objDetail.sourceIndex;
    const intTargetIndex = objDetail.targetIndex;
    if (intSourceIndex === intTargetIndex) {
      return { success: false };
    }

    try {
      let objPayload = this.cloneObject(this._dictEntity);
      const arrFields = [...objPayload.fields];
      const objField = arrFields.splice(intSourceIndex, 1)[0];
      arrFields.splice(intTargetIndex, 0, objField);
      objPayload = { ...objPayload, fields: arrFields };
      return { success: true, payload: objPayload };
    } catch (error) {
      console.error('Error in FormBuildController.js - repositionLocalField - ' + error);
    }
    return { success: false };
  };

  // Function to delete the field item at the specified index
  getDeleteFieldPayload = objDetail => {
    const intIndex = objDetail.index;
    if (intIndex < 0) {
      return { success: false, fieldLabel: '' };
    }

    try {
      let objPayload = this.cloneObject(this._dictEntity);
      const arrFields = [...objPayload.fields];
      const strFieldLabel = arrFields[intIndex].label;
      const strFieldId = arrFields[intIndex].id;
      const isFieldCustomized = this._arrCustomizedWidgetFields && strFieldId && strFieldId !== '' && this._arrCustomizedWidgetFields.indexOf(strFieldId) > -1;

      arrFields.splice(intIndex, 1);
      objPayload = { ...objPayload, fields: arrFields };
      return { success: true, payload: objPayload, fieldLabel: strFieldLabel, refreshCustomizedWidgets: isFieldCustomized };
    } catch (error) {
      console.error('Error in FormBuildController.js - deleteLocalFieldAtIndex - ' + error);
    }
    return { success: false, fieldLabel: '', refreshCustomizedWidgets: false };
  };

  // function to save the customised widget fields
  updateCustomisedWidgetFields = async objPayload => {
    if (this._entityID > 0) {
      try {
        const apiService = getService(SERVICE_TYPE.ENTITY);
        const objApiResponse = await apiService.saveCustomisedWidgets({ id: this._entityID }, objPayload);

        if (objApiResponse?.precedence_of_schema_fields && objApiResponse.precedence_of_schema_fields.length > 0) {
          this._arrCustomizedWidgetFields = this.cloneObject(objApiResponse.precedence_of_schema_fields);
          this.removeCustomisedFieldIfNotPresentInEntity();
          return this.buildResponseObject(true, { type: 'WIDGET', value: objApiResponse.precedence_of_schema_fields });
        } else {
          return this.buildResponseObject(false, { type: 'WIDGET', value: null });
        }
      } catch (error) {
        console.error('Error fetching widgets - ' + error);
        return this.buildErrorResponseObject(error, { type: 'WIDGET', value: null });
      }
    }
    return null;
  };

  // get all the widget fields for the entity
  fetchEntityWidgetFields = async numID => {
    if (numID > 0) {
      try {
        const apiService = getService(SERVICE_TYPE.ENTITY);
        const objApiResponse = await apiService.customisedWidgets({ id: numID });

        if (objApiResponse?.precedence_of_schema_fields && objApiResponse.precedence_of_schema_fields.length > 0) {
          this._arrCustomizedWidgetFields = this.cloneObject(objApiResponse.precedence_of_schema_fields);
          this.removeCustomisedFieldIfNotPresentInEntity();
          return this.buildResponseObject(true, { type: 'WIDGET', value: this._arrCustomizedWidgetFields });
        } else {
          return this.buildResponseObject(false, { type: 'WIDGET', value: null });
        }
      } catch (error) {
        console.error('Error fetching widgets - ' + error);
        return this.buildErrorResponseObject(error, { type: 'WIDGET', value: null });
      }
    }
    return null;
  };

  // get Existing target objects for lookup relationship
  fetchLookupTargetObjects = async nativeObjectData => {
    try {
      this._dictLookupTargets = {};
      const apiService = getService(SERVICE_TYPE.ENTITY);
      const arrApiResponse = await apiService.getAll();
      const nativeIds = Object.keys(nativeObjectData.idLookup);
      const arrEntityObjects = nativeIds.map(nativeId => {
        const nativeSchema = nativeObjectData.idLookup[nativeId];
        this._dictLookupTargets[nativeId] = { ...nativeSchema, isNative: true };
        const text = CommonUtils.humanizeString(nativeObjectData.idLookup[nativeId].name);
        return { value: Number(nativeId), text, isNative: true };
      });
      for (const schemaIndex in arrApiResponse.schemas) {
        const schema = arrApiResponse.schemas[schemaIndex];
        if (schema.id !== this._entityID) {
          arrEntityObjects.push({ value: schema.id, text: schema.name, isNative: false });
          this._dictLookupTargets[schema.id] = { ...schema, isNative: false };
        }
      }
      return this.buildResponseObject(true, { type: 'LOOKUP_TARGETS', value: arrEntityObjects });
    } catch (error) {
      console.error('Error fetching lookup relationships - ' + error);
      return this.buildErrorResponseObject(error, { type: 'LOOKUP_TARGETS', value: null });
    }
  };
}
