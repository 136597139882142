import { LEGO_CONSTANTS } from 'constants/index';
import { CommonUtils } from 'utils';

const ORIGIN = window.parent.origin;
const COUI_API_BASE_PATH = LEGO_CONSTANTS.COUI_API_PREFIX;

const removeExtraFowrardSlashes = url => {
  return url.replace(/([^:])(\/\/+)/g, '$1/').replace(/\/+$/, '');
};

export const getParentAppApiPath = (path, ids = {}, params = {}) => {
  return getPath(path, ids, params);
};

export const replacePathWithProductSuffix = path => {
  if (path && path.includes('api/_/custom_objects')) {
    return path.replace('api/_/custom_objects', window.productSuffix);
  }
  return path;
};

export const getPath = (route, args = {}, params = {}) => {
  let path = window.productSuffix ? replacePathWithProductSuffix(route) : route;
  const placeholders = path.match(/\$(.*?)\$/g);
  if (placeholders) {
    placeholders.forEach(function (placeholder) {
      const phText = placeholder.substring(1, placeholder.length - 1);
      args[phText] = args[phText] ? args[phText] : '';
      path = path.replace(placeholder, args[phText]);
    });
  }
  let queryParams = '';
  const { queryString = '', ...otherParams } = params;
  if (otherParams) {
    Object.keys(otherParams).forEach(key => {
      queryParams += `&${key}=${otherParams[key]}`;
    });
  }
  queryParams = queryParams !== '' ? `?${queryParams.substring(1)}` : '';
  if (queryString !== '') {
    if (queryParams !== '') {
      queryParams = `${queryParams}&${queryString}`;
    } else {
      queryParams = `?${queryString}`;
    }
  }
  const encodedBasePathpoint = encodeURI(window.basepath ? `${ORIGIN}${window.basepath}/${path}` : `${ORIGIN}/${path}`);
  const fullPath = `${encodedBasePathpoint}${queryParams}`;
  const endpoint = removeExtraFowrardSlashes(fullPath);
  return CommonUtils.replaceAll(endpoint, '%3Cnull%3E', '%00');
};

export const API = {
  ENTITIES: `${COUI_API_BASE_PATH}/schemas/$id$`,
  ENTITIES_NATIVE: `${COUI_API_BASE_PATH}/schemas/native`,
  ENTITIES_COUNT: `${COUI_API_BASE_PATH}/schemas/count`,
  ENTITIES_SEARCH: `${COUI_API_BASE_PATH}/schemas/search`,
  ENTITY_CUSTOMISED_WIDGETS: `${COUI_API_BASE_PATH}/schemas/$id$/customisations/default`,
  ENTITY_RECORDS: `${COUI_API_BASE_PATH}/schemas/$id$/records/$displayId$`,
  ENTITY_RECORDS_COUNT: `${COUI_API_BASE_PATH}/schemas/$id$/records/count`,
  ENTITY_RECORDS_SEARCH: `${COUI_API_BASE_PATH}/schemas/records/search`,
  ENTITY_RECORDS_BULK: `${COUI_API_BASE_PATH}/schemas/$id$/records/bulk`,
  ENTITY_ASSOCIATIONS: `${COUI_API_BASE_PATH}/schemas/$id$/associations`,
  ENTITY_RECORD_ASSOCIATIONS: `${COUI_API_BASE_PATH}/schemas/$id$/records/$displayId$/associations`,
  ENTITY_INVERSE_ASSOCIATIONS: `${COUI_API_BASE_PATH}/schemas/inverse-associations`,
  ENTITY_INVERSE_RECORD_ASSOCIATIONS: `${COUI_API_BASE_PATH}/schemas/records/inverse-associations`,
  IMPORT_RECORDS_UPLOAD: `${COUI_API_BASE_PATH}/schemas/$id$/records/imports`,
  IMPORT_RECORDS_LISTING: `${COUI_API_BASE_PATH}/schemas/$id$/records/imports/list`,
  IMPORT_RECORDS_MAPPING: `${COUI_API_BASE_PATH}/schemas/$id$/records/imports/$uploadId$/confirmed`,
  IMPORT_RECORDS_PROGRESS: `${COUI_API_BASE_PATH}/schemas/records/imports/$uploadId$/status`,
  ENTITY_RECORDS_SCHEMA_TRANSLATION: `${COUI_API_BASE_PATH}/schemas/$id$/translations/$languageCode$`,
  MANAGE_TRANSLATION_LANGUAGES_STATUS: `${COUI_API_BASE_PATH}/schemas/$id$/translations`,
  MANAGE_TRANSLATION_COMBINED_DATA: `${COUI_API_BASE_PATH}/schemas/translations/languages/$languageCode$`,
};
