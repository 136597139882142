import { SearchService, API, getPath } from '../lib';

class EntitySearchService extends SearchService {
  search = (ids = {}, params = {}) => {
    delete params.context;
    const url = getPath(API.ENTITIES_SEARCH, ids, params);
    return this.sendRequest(url, 'GET');
  };
}

export const entitySearchService = new EntitySearchService();
