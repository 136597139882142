import React, { Fragment, useRef, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FwIcon, FwModal, FwModalTitle, FwModalContent } from '@freshworks/crayons/react';

const DeleteModal = (props, ref) => {
  const { t } = useTranslation();
  const { heading, description, onDeleteHandler } = props;
  const deleteRef = useRef(null);
  const [disableSubmit, setDisableSubmit] = useState(false);

  const open = () => {
    setDisableSubmit(false);
    deleteRef.current.open();
  };

  const close = () => {
    deleteRef.current.close();
  };

  useImperativeHandle(ref, () => {
    return {
      open,
      close,
    };
  });

  const onSubmitHandler = e => {
    setDisableSubmit(true);
    onDeleteHandler(e);
  };

  return (
    <Fragment>
      <FwModal
        onFwSubmit={onSubmitHandler}
        size="standard"
        submitColor="danger"
        ref={deleteRef}
        submitText={t('deleteModal.confirm')}
        submitDisabled={disableSubmit}
        cancelText={t('deleteModal.cancel')}
        id="delete-fw-modal"
      >
        <FwModalTitle>
          <FwIcon slot="before-label" name="delete" className="fw-mr-8"></FwIcon>
          {heading}
        </FwModalTitle>
        <FwModalContent>{description}</FwModalContent>
      </FwModal>
    </Fragment>
  );
};

export default React.forwardRef(DeleteModal);
