import React, { useState, useCallback, useMemo, useContext } from 'react';
import { useRecordPageState } from './record-page-context';
import { useRecordsPage } from 'hooks';

export const WidgetContext = React.createContext();
const WidgetUpdaterContext = React.createContext();

const WidgetContextProvider = props => {
  const { entity } = useRecordPageState();
  const [fieldOrderForEntity, setFieldOrderForEntity] = useState({});

  const { getRelatedCustomEntityIds, getWidgetCustomizationsForEntityIds } = useRecordsPage();

  const loadWidgetCustomizations = useCallback(
    async (backwardRefEntityIds = []) => {
      const forwardRefEntityIds = getRelatedCustomEntityIds(entity);
      const entityIdSet = new Set([...forwardRefEntityIds, ...backwardRefEntityIds]);
      const entityIds = Array.from(entityIdSet);
      const fieldOrderForEntity = await getWidgetCustomizationsForEntityIds(entityIds);
      setFieldOrderForEntity(fieldOrderForEntity);
    },
    [entity, getRelatedCustomEntityIds, getWidgetCustomizationsForEntityIds]
  );

  const updateFunctions = useMemo(() => {
    return {
      loadWidgetCustomizations,
      setFieldOrderForEntity,
    };
  }, [setFieldOrderForEntity, loadWidgetCustomizations]);

  return (
    <WidgetContext.Provider value={fieldOrderForEntity}>
      <WidgetUpdaterContext.Provider value={updateFunctions}>{props.children}</WidgetUpdaterContext.Provider>
    </WidgetContext.Provider>
  );
};

const useWidgetContextState = () => {
  const fieldOrderForEntity = useContext(WidgetContext);
  if (!fieldOrderForEntity) {
    throw new Error('useWidgetContextState must be used within a WidgetContextProvider');
  }
  return fieldOrderForEntity;
};

const useWidgetContextUpdater = () => {
  const { setFieldOrderForEntity, loadWidgetCustomizations } = useContext(WidgetUpdaterContext);
  if (!setFieldOrderForEntity || !loadWidgetCustomizations) {
    throw new Error('useWidgetContextUpdater must be used within a WidgetContextProvider');
  }

  return { setFieldOrderForEntity, loadWidgetCustomizations };
};

export { WidgetContextProvider, useWidgetContextState, useWidgetContextUpdater };
