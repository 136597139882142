import EntityController from './EntityController';
import getService from '../services/serviceDiscovery';
import { SERVICE_TYPE, PERMISSION_TYPE, LEGO_CONSTANTS, ENTITY_FIELD_TYPE } from 'constants/index';
import { CommonUtils } from 'utils';
class FormRendererController extends EntityController {
  parseSchemaFields = (entity, values, nativeObjectData) => {
    const entityFieldLookup = this.getFieldLookup(entity);
    const nativeIds = Object.keys(nativeObjectData.idLookup);
    return Object.keys(values).reduce((acc, key) => {
      const field = entityFieldLookup[key];
      if (field?.type === ENTITY_FIELD_TYPE.CHECKBOX) {
        let toSave = values[key];
        if (values[key] === null) {
          toSave = false;
        }
        return { ...acc, [key]: toSave };
      }
      if (values[key] !== undefined && values[key] !== '') {
        let toSave = values[key];
        if (values[key] && nativeIds.includes(field?.related_entity_id?.toString())) {
          toSave = BigInt(values[key]);
        }

        if (field.type === ENTITY_FIELD_TYPE.DROPDOWN) {
          toSave = entity?.translatedEntityReverseChoicesLookup?.get(key)?.[key]?.[values[key]] ?? values[key];
        }

        if (field.type === ENTITY_FIELD_TYPE.MULTI_SELECT) {
          toSave = values[key]?.map(itemValue => {
            return entity?.translatedEntityReverseChoicesLookup?.get(key)?.[key]?.[itemValue] ?? itemValue;
          });
        }

        return { ...acc, [key]: toSave };
      }
      return { ...acc, [key]: null };
    }, {});
  };

  __updateRecord = async (ids, payload) => {
    try {
      if (!this.hasRecordPermission(PERMISSION_TYPE.UPDATE)) throw this.constructErrorObject(LEGO_CONSTANTS.NO_RECORD_UPDATE_PERMISSION);
      const service = getService(SERVICE_TYPE.ENTITY_RECORD);
      const record = await service.put(ids, payload);
      return this.buildResponse(true, record);
    } catch (error) {
      console.error(error);
      return this.buildErrorResponse(error.data, false);
    }
  };

  __createRecord = async (ids, payload) => {
    try {
      if (!this.hasRecordPermission(PERMISSION_TYPE.CREATE)) throw this.constructErrorObject(LEGO_CONSTANTS.NO_RECORD_CREATE_PERMISSION);
      const service = getService(SERVICE_TYPE.ENTITY_RECORD);
      const record = await service.create({ id: ids.id }, payload);
      return this.buildResponse(true, record);
    } catch (error) {
      console.error(error);
      return this.buildErrorResponse(error.data, false);
    }
  };

  __saveNativeRecordAssociation = async (ids, payload) => {
    if (!this.hasRecordPermission(PERMISSION_TYPE.CREATE)) throw this.constructErrorObject(LEGO_CONSTANTS.NO_RECORD_CREATE_PERMISSION);
    const service = getService(SERVICE_TYPE.ENTITY_RECORD_ASSOCATION);
    return await service.create(ids, payload).catch(e => console.error(e));
  };

  saveRecord = async (ids, entity, nativeObjectData, isEdit, values, version) => {
    const fields = this.parseSchemaFields(entity, values, nativeObjectData);
    const payload = {
      data: fields,
      version: isEdit ? version : 1,
    };
    const response = isEdit ? await this.__updateRecord(ids, payload) : await this.__createRecord(ids, payload);
    return response;
  };

  getFieldErrors = error => {
    if (error.error_type === LEGO_CONSTANTS.INVALID_INPUT) {
      const fieldErrors = {};
      error.errors.forEach(err => {
        fieldErrors[err.name] = err.message;
      });
      return fieldErrors;
    } else this.getErrorMessage(error);
  };

  getFormSchema = (entity, searchRecordsCallback, t) => {
    const fields = entity?.fields?.map(field => {
      field.placeholder = CommonUtils.getPlaceholdersForFieldTypes(field.type, t);
      if (field.type === ENTITY_FIELD_TYPE.DROPDOWN || field.type === ENTITY_FIELD_TYPE.MULTI_SELECT) {
        const choices = field?.choices?.map(ch => {
          return { ...ch, text: ch.value, value: ch.value };
        });
        return {
          ...field,
          choices,
        };
      } else if (field.type === ENTITY_FIELD_TYPE.TEXT) {
        return {
          ...field,
          maxlength: LEGO_CONSTANTS.MAX_TEXT_LENGTH,
        };
      } else if (field.type === ENTITY_FIELD_TYPE.PARAGRAPH) {
        return {
          ...field,
          resize: 'vertical',
        };
      } else if (field.type === ENTITY_FIELD_TYPE.RELATIONSHIP) {
        return {
          ...field,
          search: async term => {
            return searchRecordsCallback(term, field.related_entity_id);
          },
        };
      } else if (field.type === ENTITY_FIELD_TYPE.DATE) {
        return {
          ...field,
          displayFormat: LEGO_CONSTANTS.DEFAULT_DATE_FORMAT,
          maxYear: new Date().getFullYear() + 50,
          minYear: new Date().getFullYear() - 100,
          clearInput: true,
          showFooter: false,
          readOnly: true,
          fullWidth: true,
        };
      } else if (field.type === ENTITY_FIELD_TYPE.PRIMARY) {
        return {
          ...field,
          type: ENTITY_FIELD_TYPE.TEXT,
          maxlength: LEGO_CONSTANTS.MAX_TEXT_LENGTH,
        };
      } else return field;
    });

    return {
      ...entity,
      fields: fields,
    };
  };
}

export const formRendererController = new FormRendererController();
