import { LEGO_CONSTANTS, ENTITY_FIELD_TYPE } from 'constants/index';
export class CommonUtils {
  static getPrimaryField = entity => {
    if (!entity || entity?.fields?.length === 0) return null;
    const primaryFieldArray = entity?.fields?.filter(field => {
      return field.type === 'PRIMARY';
    });
    return primaryFieldArray?.length > 0 ? primaryFieldArray?.[0] : entity?.fields?.[0];
  };

  static parseTokenFromLink = (link, tokenName) => {
    const urlParams = new URLSearchParams(link);
    const token = urlParams.get(tokenName);
    return token;
  };

  static getFormattedDate = (date, language = LEGO_CONSTANTS.US_TIME_ZONE, format = LEGO_CONSTANTS.DEFAULT_DATE_FORMAT) => {
    if (language === '') language = LEGO_CONSTANTS.US_TIME_ZONE;
    if (!date || isNaN(Number(date))) return '-';
    const DATE = new Date(date);
    //  LEGO-3456 Custom object Record error - DATE Field changes on system timezone
    //  Due to addition of the offset based on the timezone, the DATE field were showing different Dates for the given system data of DATE type
    //  To address the issue timezoneoffset(+/-) is added
    DATE.setMinutes(DATE.getMinutes() + DATE.getTimezoneOffset());
    switch (format) {
      case LEGO_CONSTANTS.DEFAULT_DATE_FORMAT:
        return (
          DATE.toLocaleDateString(language, { day: '2-digit' }) +
          ' ' +
          DATE.toLocaleDateString(language, { month: 'short' }) +
          ' ' +
          DATE.toLocaleDateString(language, { year: 'numeric' })
        );
      default:
        return date;
    }
  };

  static getFormattedDateForDateInString = (date, language = LEGO_CONSTANTS.US_TIME_ZONE, format = LEGO_CONSTANTS.DEFAULT_DATE_FORMAT) => {
    if (language === '') language = LEGO_CONSTANTS.US_TIME_ZONE;
    if (!date || typeof date !== 'string') return '-';
    const DATE = new Date(date);
    DATE.setMinutes(DATE.getMinutes() + DATE.getTimezoneOffset());
    if (isNaN(DATE.valueOf())) return '-';
    if (format === LEGO_CONSTANTS.DEFAULT_DATE_FORMAT) {
      return (
        DATE.toLocaleDateString(language, { day: '2-digit' }) +
        ' ' +
        DATE.toLocaleDateString(language, { month: 'short' }) +
        ' ' +
        DATE.toLocaleDateString(language, { year: 'numeric' })
      );
    }
    return date;
  };

  static timeSince = (dateTimestamp, translationFn, language, timeZone) => {
    const date = new Date(dateTimestamp);
    const today = new Date();
    const seconds = Math.floor((today - date) / 1000);

    let interval = seconds / LEGO_CONSTANTS.TIME.SECONDS_FOR_SIX_DAYS;
    if (interval > 1) {
      return (
        date.toLocaleDateString(language, { timeZone, day: '2-digit' }) +
        ' ' +
        date.toLocaleDateString(language, { timeZone, month: 'short' }) +
        ' ' +
        date.toLocaleDateString(language, { timeZone, year: 'numeric' })
      );
    }
    interval = seconds / LEGO_CONSTANTS.TIME.SECONDS_FOR_ONE_DAY;
    if (interval > 1) {
      return Math.floor(interval) === 1 ? translationFn('general.xDayAgo', { time: 1 }) : translationFn('general.xDaysAgo', { time: Math.floor(interval) });
    }
    interval = seconds / LEGO_CONSTANTS.TIME.SECONDS_FOR_ONE_HOUR;
    if (interval > 1) {
      return Math.floor(interval) === 1 ? translationFn('general.xHourAgo', { time: 1 }) : translationFn('general.xHoursAgo', { time: Math.floor(interval) });
    }
    interval = seconds / LEGO_CONSTANTS.TIME.SECONDS_FOR_ONE_MINUTE;
    if (interval > 1) {
      return Math.floor(interval) === 1 ? translationFn('general.xMinuteAgo', { time: 1 }) : translationFn('general.xMinutesAgo', { time: Math.floor(interval) });
    }
    return seconds < 2 ? translationFn('general.xSecondAgo', { time: 1 }) : translationFn('general.xSecondsAgo', { time: Math.floor(seconds) });
  };

  static parseJSON = string => {
    try {
      return JSON.parse(string);
    } catch (e) {
      return null;
    }
  };

  static getFilterValue = query => {
    if (query === null || typeof query === 'undefined' || !(query instanceof URLSearchParams)) {
      return null;
    }
    const filters = {};
    for (const key of query.keys()) {
      for (const value of query.getAll(key)) {
        const parsedValue = CommonUtils.parseJSON(value);
        if (parsedValue) {
          filters[key] = parsedValue[key];
        }
      }
    }
    return filters;
  };

  static getFilterParamString = query => {
    if (query === null || typeof query === 'undefined' || !(query instanceof URLSearchParams)) {
      return '';
    }
    let filterParamString = '';
    for (const key of query.keys()) {
      for (const value of query.getAll(key)) {
        const parsedValue = CommonUtils.parseJSON(value);
        if (parsedValue) {
          if (parsedValue[key].value && Array.isArray(parsedValue[key].value)) {
            for (const val in parsedValue[key].value) {
              filterParamString += `&${key}=${parsedValue[key].value[val].value}`;
            }
          } else {
            filterParamString += `&${key}=${parsedValue[key].value}`;
          }
        }
      }
    }
    return filterParamString.substring(1);
  };

  static getPlaceholdersForFieldTypes = (type, t) => {
    if (!t) return '';

    if ([ENTITY_FIELD_TYPE.PARAGRAPH, ENTITY_FIELD_TYPE.TEXT, ENTITY_FIELD_TYPE.PRIMARY].includes(type)) {
      return t('placeholders.enterDetails');
    } else if ([ENTITY_FIELD_TYPE.MULTI_SELECT].includes(type)) {
      return t('placeholders.clickToSelect');
    } else if ([ENTITY_FIELD_TYPE.NUMBER, ENTITY_FIELD_TYPE.DECIMAL].includes(type)) {
      return t('placeholders.enterValue');
    } else if ([ENTITY_FIELD_TYPE.DATE].includes(type)) {
      return LEGO_CONSTANTS.DEFAULT_DATE_PLACEHOLDER;
    } else if ([ENTITY_FIELD_TYPE.RELATIONSHIP, ENTITY_FIELD_TYPE.DROPDOWN].includes(type)) {
      return t('placeholders.enterValue');
    } else return '';
  };

  static getIconNamefromUrl = entityUrl => {
    if (!entityUrl) return '';
    let iconUrl;
    try {
      iconUrl = new URL(entityUrl);
    } catch (error) {
      console.error(error);
    }
    const iconPath = iconUrl?.pathname;
    const iconSvg = iconPath?.split('/')?.pop();
    const iconName = iconSvg?.split('.')[0];
    return iconName;
  };

  static downloadCSV = (csvContent, fileName) => {
    const csvData = csvContent;
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, fileName);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', fileName);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };

  static escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }

  static replaceAll(str, find, replace) {
    return str.replace(new RegExp(CommonUtils.escapeRegExp(find), 'g'), replace);
  }

  static buildResponse = (success = false, response = null, errorMessage = null) => {
    return { success, response, errorMessage };
  };

  static getErrorMessage = error => {
    const objErrorData = error && error.data ? error.data : null;
    let strErrorMessage = objErrorData ? objErrorData.message : '';
    if (objErrorData && objErrorData.errors && objErrorData.errors.length > 0) {
      strErrorMessage += ` - ${objErrorData.errors[objErrorData.errors.length - 1].message}`;
    }
    return strErrorMessage;
  };

  static buildErrorResponse = (error, parseError = true) => {
    if (parseError) {
      const strErrorMessage = CommonUtils.getErrorMessage(error);
      return CommonUtils.buildResponse(false, null, strErrorMessage);
    } else {
      return CommonUtils.buildResponse(false, null, error);
    }
  };

  static humanizeString = str => {
    const frags = str.split('_');
    for (let i = 0; i < frags.length; i++) {
      if (i === 0) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1).toLowerCase();
      } else {
        frags[i] = frags[i].toLowerCase();
      }
    }
    return frags.join(' ');
  };

  static isPropValuesEqual = (subject, target, propNames) => propNames.every(propName => subject[propName] === target[propName]);

  static getUniqueItemsByProperties = (items, propNames) => {
    const propNamesArray = Array.from(propNames) ?? [];
    return items?.filter((item, index, array) => index === array.findIndex(foundItem => CommonUtils.isPropValuesEqual(foundItem, item, propNamesArray)));
  };

  static convertValueToBigIntString = item => {
    if (item === null || item === undefined || item === '') {
      return '';
    } else {
      return BigInt(item).toString();
    }
  };
}
