import { RestService, API, getPath } from './lib';

class EntityAssociationService extends RestService {
  getAll = (ids = {}, params = {}) => {
    const url = getPath(API.ENTITY_ASSOCIATIONS, ids, params);
    return this.sendRequest(url);
  };
}

export const entityAssociationService = new EntityAssociationService();
