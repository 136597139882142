import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFreshdeskContextState, useNativeObjectsState } from 'store';
import { entityPageController } from 'controllers';
import { ENTITY_FIELD_TYPE, LEGO_CONSTANTS, SCHEMA_TYPE } from 'constants/index';
import useRecordsPage from './use-records-page';
import nativeObjectConfigs from 'configs/nativeObjectConfigs';
import { getNativeObjectIDForConfigType } from 'components/RecordPage/RecordDetails/Widgets/utils/util';

const useEntityPage = () => {
  const { t: translationFn } = useTranslation();
  const nativeObjectData = useNativeObjectsState();
  const { getSchemaPermissions, getRecordPermissions, getUserDetails, getConfigType } = useFreshdeskContextState();
  const { getRecordsLookupById } = useRecordsPage();
  const language = getUserDetails()?.locale?.language;
  const timezone = getUserDetails()?.locale?.timezone;
  const configType = getConfigType();
  const entityPermissions = useMemo(() => getSchemaPermissions(), [getSchemaPermissions]);
  const recordPermissions = useMemo(() => getRecordPermissions(), [getRecordPermissions]);
  entityPageController.setPermissions(entityPermissions, recordPermissions, configType);

  const prepareRowsForPlatformTable = useCallback(
    async (configType, entityState, recordsList, filterQuery, translationDataForEntities = []) => {
      const { entity, isLoading } = entityState;
      if (!translationFn || !entity || isLoading || !nativeObjectData.loaded) return [];

      const nativeIdLookup = {};
      const nativeFieldNamesLookupToId = entityPageController.getNativeRelationshipFieldNameToIdLookup(entity) ?? {};
      const nativeFieldNames = Object.keys(nativeFieldNamesLookupToId);
      for (const index in recordsList) {
        const record = recordsList[index];
        Object.keys(record.data)
          .filter(fieldName => nativeFieldNames.includes(fieldName))
          .forEach(fieldName => {
            const nativeSchemaId = nativeFieldNamesLookupToId[fieldName];
            nativeIdLookup[nativeSchemaId] = nativeIdLookup[nativeSchemaId] ?? [];
            if (record.data[fieldName]) {
              nativeIdLookup[nativeSchemaId].push(record.data[fieldName]);
            }
          });
      }
      const nativeAssociations = {};
      for (const nativeSchemaId of Object.keys(nativeIdLookup)) {
        const context = nativeObjectData.idLookup[nativeSchemaId].context;
        const nativeIds = nativeIdLookup[nativeSchemaId];
        nativeAssociations[context] = getRecordsLookupById(context, nativeIds);
      }
      const associationResponseArray = await Promise.allSettled(Object.values(nativeAssociations));
      const nativeAssociationLookup = associationResponseArray
        .filter(res => res.status === 'fulfilled')
        .map(res => res.value)
        .reduce((map, item) => {
          return { ...map, [item.context]: item.response };
        }, {});

      const enrichedRecords = {};
      for (const index in recordsList) {
        const record = recordsList[index];
        const { enrichedRecordData } = entityPageController.getEnrichRecordData(entity, record, nativeObjectData, translationFn, language, timezone, configType);
        for (const data of enrichedRecordData) {
          if (
            data.fieldType === ENTITY_FIELD_TYPE.RELATIONSHIP &&
            data.fieldSubType === SCHEMA_TYPE.NATIVE &&
            data.displayValue.value &&
            data.displayValue.value !== LEGO_CONSTANTS.EMPTY_DEFAULT_DATA
          ) {
            const nativeRecordLookupById = nativeAssociationLookup[data.displayValue?.meta];
            const configs = nativeObjectConfigs[configType][data.displayValue?.meta];
            const displayValueToBeConverted = getNativeObjectIDForConfigType(configType, data.displayValue.value);
            const displayDataForNativeContext =
              nativeRecordLookupById?.[displayValueToBeConverted]?.[configs.defaultDisplayField] ??
              nativeRecordLookupById?.[data.displayValue?.value]?.[configs.defaultDisplayField];
            data.displayValue.value = displayDataForNativeContext ?? LEGO_CONSTANTS.EMPTY_DEFAULT_DATA;
          }
        }
        enrichedRecords[record.display_id] = enrichedRecordData;
      }
      return entityPageController.__constructRows(entity, enrichedRecords, translationFn, filterQuery, translationDataForEntities);
    },
    [language, nativeObjectData, timezone, translationFn, getRecordsLookupById]
  );

  return useMemo(() => {
    return {
      prepareRowsForPlatformTable,
    };
  }, [prepareRowsForPlatformTable]);
};

export default useEntityPage;
