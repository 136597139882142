import React, { createContext, useContext, useEffect, useState, useMemo, useCallback } from 'react';
import { useRecordsPage } from 'hooks';

const InverseRefWidgetContext = createContext();
export const InverseRefWidgetUpdaterContext = createContext();

const InverseRefProvider = props => {
  const [state, setState] = useState({
    isLoading: true,
    inverseEntityAssociations: [],
    nativeIdAssociationLookup: {},
    inverseAssociationRecordsLookup: {},
    inverseAssociationResolvedRecordsLookup: {},
  });

  const { getInverseEntityAssociations } = useRecordsPage();

  const loadInverseAssociations = useCallback(
    async (forceReload = false) => {
      if (forceReload) {
        setState(prev => {
          return { ...prev, isLoading: true };
        });
      }
      const response = await getInverseEntityAssociations();
      setState(response);
    },
    [getInverseEntityAssociations]
  );

  useEffect(() => {
    loadInverseAssociations();
  }, [loadInverseAssociations]);

  const updateFunctions = useMemo(() => {
    return {
      loadInverseAssociations,
      setState,
    };
  }, [loadInverseAssociations, setState]);

  return (
    <InverseRefWidgetContext.Provider value={state}>
      <InverseRefWidgetUpdaterContext.Provider value={updateFunctions}>{props.children}</InverseRefWidgetUpdaterContext.Provider>
    </InverseRefWidgetContext.Provider>
  );
};

const useInverseRefState = () => {
  const state = useContext(InverseRefWidgetContext);
  if (!state) {
    throw new Error('useInverseRefState must be used within a InverseRefProvider');
  }
  return state;
};

const useInverseRefUpdater = () => {
  const { setState, loadInverseAssociations } = useContext(InverseRefWidgetUpdaterContext);
  if (!setState || !loadInverseAssociations) {
    throw new Error('useInverseRefUpdater must be used within a InverseRefProvider');
  }

  return { setState, loadInverseAssociations };
};

export { InverseRefProvider, useInverseRefState, useInverseRefUpdater };
