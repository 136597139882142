import React, { useState, useCallback, useContext, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useRecordsPage } from 'hooks';

const initialContextValue = {
  loading: true,
  entity: null,
  associatedEntities: [],
  record: null,
  error: null,
  recordsListData: {},
};

export const RecordPageStateContext = React.createContext(initialContextValue);
const RecordPageUpdaterContext = React.createContext();

const RecordPageProvider = props => {
  const { state } = useLocation();
  const { fetchRecordAndEntityIfNull, fetchRecord: getRecord } = useRecordsPage();
  const { entity, associatedEntities, data: recordsListData } = state ?? { entity: null, associatedEntities: [] };
  const [recordPage, setRecordPage] = useState({ ...initialContextValue, entity, associatedEntities, recordsListData });

  const fetchRecord = useCallback(async () => {
    setRecordPage(prev => {
      return {
        ...prev,
        loading: true,
      };
    });
    const recordData = await getRecord(recordPage.entity);
    setRecordPage(prev => {
      return {
        ...prev,
        ...recordData,
        loading: false,
      };
    });
  }, [recordPage.entity, getRecord]);

  useEffect(() => {
    (async () => {
      setRecordPage(prev => {
        return {
          ...prev,
          loading: true,
        };
      });
      const entityRecordData = await fetchRecordAndEntityIfNull(entity);
      if (Object.keys(entityRecordData).length > 0) {
        setRecordPage(prev => {
          return {
            ...prev,
            ...entityRecordData,
            loading: false,
          };
        });
      }
    })();
  }, [entity, fetchRecordAndEntityIfNull]);

  const updateFunctions = useMemo(() => {
    return {
      fetchRecord,
      setRecordPage,
    };
  }, [fetchRecord, setRecordPage]);

  return (
    <RecordPageStateContext.Provider value={recordPage}>
      <RecordPageUpdaterContext.Provider value={updateFunctions}>{props.children}</RecordPageUpdaterContext.Provider>
    </RecordPageStateContext.Provider>
  );
};

const useRecordPageState = () => {
  const recordPageState = useContext(RecordPageStateContext);
  if (!recordPageState) {
    throw new Error('useRecordPageState must be used within a RecordPageProvider');
  }
  return recordPageState;
};

const useRecordPageUpdater = () => {
  const { setRecordPage, fetchRecord } = useContext(RecordPageUpdaterContext);
  if (!setRecordPage || !fetchRecord) {
    throw new Error('useRecordPageUpdater must be used within a RecordPageProvider');
  }

  return { setRecordPage, fetchRecord };
};

export { RecordPageProvider, useRecordPageState, useRecordPageUpdater };
