import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

const detectionOptions = {
  order: ['htmlTag', 'navigator', 'path', 'querystring'],
  caches: [],
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(Backend) // loads translations from server
  .use(LanguageDetector)
  .init({
    detection: detectionOptions,
    fallbackLng: 'en', // fallback language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    backend: {
      loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}.json`,
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;
