import { RestService, API, getPath } from './lib';
import getSearchServiceImpl from './search/searchServiceDiscovery';
import { SEARCH_SERVICE_TYPE } from 'constants/index';

class EntityService extends RestService {
  get = (ids = {}, params = {}) => {
    this.validate(['id'], ids);
    const url = getPath(API.ENTITIES, ids, params);
    return this.sendRequest(url);
  };

  getAll = (ids = {}, params = {}) => {
    const url = getPath(API.ENTITIES, ids, params);
    return this.sendRequest(url);
  };

  count = (ids = {}, params = {}) => {
    delete params.sort_by;
    delete params.page_size;
    const url = getPath(API.ENTITIES_COUNT, ids, params);
    return this.sendRequest(url);
  };

  put = (ids = {}, payload = {}, params = {}) => {
    this.validate(['id'], ids);
    const url = getPath(API.ENTITIES, ids, params);
    return this.sendRequest(url, 'PUT', payload);
  };

  create = (ids = {}, payload = {}, params = {}) => {
    const url = getPath(API.ENTITIES, ids, params);
    return this.sendRequest(url, 'POST', payload);
  };

  delete = (ids = {}, params = {}) => {
    this.validate(['id'], ids);
    const url = getPath(API.ENTITIES, ids, params);
    return this.sendRequest(url, 'DELETE');
  };

  native = (ids = {}, params = {}) => {
    const url = getPath(API.ENTITIES_NATIVE, ids, params);
    return this.sendRequest(url);
  };

  customisedWidgets = (ids = {}, params = {}) => {
    this.validate(['id'], ids);
    const url = getPath(API.ENTITY_CUSTOMISED_WIDGETS, ids, params);
    return this.sendRequest(url);
  };

  saveCustomisedWidgets = (ids = {}, payload = {}, params = {}) => {
    this.validate(['id'], ids);
    const url = getPath(API.ENTITY_CUSTOMISED_WIDGETS, ids, params);
    return this.sendRequest(url, 'PUT', payload);
  };

  search = (ids = {}, params = {}) => {
    return getSearchServiceImpl(SEARCH_SERVICE_TYPE.ENTITY).search(ids, params);
  };
}

export const entityService = new EntityService();
