import { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

const useQueryParams = () => {
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  const parseQueryParams = useCallback(() => {
    return searchParams;
  }, [searchParams]);

  return { parseQueryParams };
};

export default useQueryParams;
