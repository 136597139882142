import React, { useEffect, useMemo } from 'react';
import getService from 'services/serviceDiscovery';
import { SERVICE_TYPE } from 'constants/index';
import { LegoTable } from 'utils';
import { useLegoTable, useRouteChangeEventChannelEmitter } from 'hooks';
import EntityList from 'components/EntityList/EntityList';
import Suspend from 'UI/Suspend/Suspend';
import { PageLoader } from 'UI';
import { useNativeObjectsState } from 'store';

export default function Entities() {
  useRouteChangeEventChannelEmitter();
  const entityTableInstance = useMemo(() => new LegoTable.Builder().setService(getService(SERVICE_TYPE.ENTITY)).setDataField('schemas').withoutCount().build(), []);
  const { tableState, fetchList: fetchEntities } = useLegoTable(entityTableInstance);
  const nativeObjectData = useNativeObjectsState();
  const { loaded, idLookup } = nativeObjectData;
  useEffect(() => {
    fetchEntities();
  }, [fetchEntities]);
  const { isLoading, records: entities, error } = tableState;

  return (
    <>
      <Suspend isLoading={isLoading || !loaded} fallback={<PageLoader />}>
        <EntityList entities={entities} error={error} idLookup={idLookup} fetchEntities={fetchEntities} />
      </Suspend>
    </>
  );
}
