import React from 'react';
import useLinkModal from 'hooks/use-link-modal';
import LinkModal from 'components/RecordPage/LinkModal/LinkModal';

let WidgetLinkModalContext;
const { Provider } = (WidgetLinkModalContext = React.createContext());

const WidgetLinkModalProvider = ({ children }) => {
  const { handleModal, modalState, onSearchHandler, onSubmitHandler, getEnrichRecordData } = useLinkModal();
  return (
    <Provider value={{ modalState, handleModal, onSearchHandler, onSubmitHandler, getEnrichRecordData }}>
      <LinkModal />
      {children}
    </Provider>
  );
};

export { WidgetLinkModalContext, WidgetLinkModalProvider };
