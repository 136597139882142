import {
  entityService,
  entityRecordService,
  entityAssociationService,
  entityRecordAssociationService,
  entityInverseAssociationService,
  entityInverseRecordAssociationService,
} from 'services';
import { LEGO_CONSTANTS, SERVICE_TYPE } from 'constants/index';

class ServiceDiscovery {
  static getService(name) {
    switch (name) {
      case SERVICE_TYPE.ENTITY:
        return entityService;
      case SERVICE_TYPE.ENTITY_RECORD:
        return entityRecordService;
      case SERVICE_TYPE.ENTITY_ASSOCATION:
        return entityAssociationService;
      case SERVICE_TYPE.ENTITY_RECORD_ASSOCATION:
        return entityRecordAssociationService;
      case SERVICE_TYPE.ENTITY_INVERSE_ASSOCIATION:
        return entityInverseAssociationService;
      case SERVICE_TYPE.ENTITY_INVERSE_RECORD_ASSOCIATION:
        return entityInverseRecordAssociationService;
      default:
        throw new Error(LEGO_CONSTANTS.INVALID_SERVICE);
    }
  }
}

const getService = name => {
  return ServiceDiscovery.getService(name);
};

export default getService;
