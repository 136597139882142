import axios from 'axios';
import { LEGO_CONSTANTS } from 'constants/index';

axios.defaults.xsrfCookieName = undefined;
axios.defaults.xsrfHeaderName = undefined;
class AxiosClient {
  constructor() {
    let sessionCSRFToken = null;
    this.client = axios.create({
      timeout: 10000,
      withCredentials: false,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
      },
    });

    this.client.interceptors.request.use(
      request => {
        if (sessionCSRFToken) {
          request.headers[LEGO_CONSTANTS.X_XSRF_TOKEN.toUpperCase()] = sessionCSRFToken;
        }
        return request;
      },
      error => Promise.reject(error)
    );

    this.client.interceptors.response.use(request => {
      request.transformResponse = [data => data]; // to ensure the Number datatype which falls under UNSAFE_INTEGER is saved with no precision error
      return request;
    });
    this.client.interceptors.response.use(
      response => {
        sessionCSRFToken = response.headers[LEGO_CONSTANTS.X_XSRF_TOKEN];

        return response.data;
      },
      error => Promise.reject(error.response)
    );
  }

  toJson(data) {
    if (data !== undefined) {
      return JSON.stringify(data, (_, v) => (typeof v === 'bigint' ? `${v}n` : v)).replace(/"(-?\d+)n"/g, (_, a) => a);
    }
  }

  sendRequest(requestConfig) {
    const { url: requestUrl, method: requestMethod, body, headers: formDataHeaders } = requestConfig;
    const url = requestUrl ?? '';
    const method = requestMethod ?? 'GET';
    const formDataRequestHeaders = formDataHeaders?.headers;
    const data = formDataRequestHeaders?.[LEGO_CONSTANTS.CONTENT_TYPE] === LEGO_CONSTANTS.FORMDATA_MULTIPART ? body : this.toJson(body ?? {});
    return this.client.request({
      method,
      url,
      data,
      headers: { ...formDataRequestHeaders },
    });
  }
}

export const axiosClient = new AxiosClient();
