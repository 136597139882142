import { RestService, API, getPath } from './lib';

class ManageTranslationService extends RestService {
  uploadYamlFileForLanguage = (ids = {}, payload = {}, params = {}, headers = {}) => {
    const url = getPath(API.ENTITY_RECORDS_SCHEMA_TRANSLATION, ids, params);
    return this.sendRequest(url, 'POST', payload, headers);
  };

  deleteYamlFileForLanguage = (ids = {}, params = {}) => {
    this.validate(['id'], ids);
    const url = getPath(API.ENTITY_RECORDS_SCHEMA_TRANSLATION, ids, params);
    return this.sendRequest(url, 'DELETE');
  };

  downloadYamlFileForLanguage = (ids = {}, params = {}) => {
    return getPath(API.ENTITY_RECORDS_SCHEMA_TRANSLATION, ids, params);
  };

  getStatusForSupportedLanguages = (ids = {}, params = {}) => {
    const url = getPath(API.MANAGE_TRANSLATION_LANGUAGES_STATUS, ids, params);
    return this.sendRequest(url);
  };

  getCombinedTranslationForLanguage = (ids = {}, params = {}) => {
    const url = getPath(API.MANAGE_TRANSLATION_COMBINED_DATA, ids, params);
    return this.sendRequest(url);
  };
}

export const manageTranslationService = new ManageTranslationService();
