import React from 'react';
import { LEGO_CONSTANTS } from 'constants/index';
import { CommonUtils } from 'utils';
import { useFreshdeskContextState, useNativeObjectsState } from 'store';
import styles from './LinkModal.module.css';

const Ticket = ({ record }) => {
  const nativeObjectData = useNativeObjectsState();
  const { getUserDetails } = useFreshdeskContextState();
  const language = getUserDetails()?.locale?.language;
  const ticketStatusChoices = nativeObjectData?.fields?.[LEGO_CONSTANTS.TICKET]
    ?.filter(field => field.name === LEGO_CONSTANTS.WIDGET_COLUMNS.STATUS)
    ?.map(item => item.choices ?? []);

  const ticketStatusIdMap = ticketStatusChoices?.[0]?.reduce((map, choice) => {
    return { ...map, [choice?.value]: choice?.customer_display_name };
  }, {});

  const ticketPriorityChoices = nativeObjectData?.fields?.[LEGO_CONSTANTS.TICKET]
    ?.filter(field => field.name === LEGO_CONSTANTS.WIDGET_COLUMNS.PRIORITY)
    ?.map(item => item.choices ?? []);
  const ticketPriorityIdMap = ticketPriorityChoices?.[0]?.reduce((map, choice) => {
    return { ...map, [choice?.value]: choice?.label };
  }, {});

  const toDisplay = {
    'Id': record?.id,
    'Subject': record?.subject ?? '-',
    'From': record?.requester?.name ?? '-',
    'Created at': CommonUtils.getFormattedDate(new Date(record.created_at), language ?? LEGO_CONSTANTS.US_TIME_ZONE) ?? '-',
    'Status': ticketStatusIdMap?.[record?.status] ?? record?.status ?? '-',
    'Priority': ticketPriorityIdMap?.[record?.priority] ?? record?.priority ?? '-',
  };

  return (
    <div className={styles['data-container']}>
      {Object.keys(toDisplay ?? {}).map(item => {
        return (
          <div key={item} className={styles['data-container--field']}>
            <div className={styles['field-key']}>{item}</div>
            {toDisplay[item] !== null ? (
              <div className={styles['field-value']}>{toDisplay[item]}</div>
            ) : (
              <div className="fw-color-smoke-300">{LEGO_CONSTANTS.EMPTY_DEFAULT_DATA}</div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Ticket;
