export default class Permissions {
  constructor(permissionsArray) {
    this.set(permissionsArray ?? []);
    this.permissionSet = true;
  }

  get = () => {
    return this.permArray;
  };

  set = permissionsArray => {
    this.permArray = permissionsArray;
  };

  areSet = () => {
    return this.permissionSet ?? false;
  };

  isPresent = permission => {
    return this.permArray?.includes(permission);
  };
}
