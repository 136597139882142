import React from 'react';
import { FwSpinner } from '@freshworks/crayons/react';
import styles from './PageLoader.module.css';

const PageLoader = () => {
  const containerStyle = `fw-flex fw-justify-evenly ${styles.container}`;
  const spinnerStyle = 'fw-flex fw-justify-around fw-flex-column fw-m-4';

  return (
    <div className={containerStyle}>
      <div className={spinnerStyle}>
        <FwSpinner size="large" color="#2C5CC5" data-testid="spinner"></FwSpinner>
      </div>
    </div>
  );
};

export default PageLoader;
