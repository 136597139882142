const LEGO_CONSTANTS = {
  ENV_VAR_PREFIX: 'REACT_APP_',
  BASE_PATH: 'BASE_PATH',
  API_VERSION: 'API_VERSION',
  X_XSRF_TOKEN: 'x-xsrf-token',
  PROFILE: 'PROFILE',
  DEV: 'DEV',
  PROD: 'PROD',
  COUI_API_PREFIX: 'api/_/custom_objects',
  FD_SEARCH_API_PREFIX: 'api/_/search',
  CDN_PATH: 'CDN_PATH',
  RELATIONSHIP: 'RELATIONSHIP',
  PRIMARY: 'PRIMARY',
  ICONS: 'icons',
  TRUE: 'true',
  FALSE: 'false',
  DATE: 'DATE',
  DROPDOWN: 'DROPDOWN',
  CHECKBOX: 'CHECKBOX',
  NUMBER: 'NUMBER',
  DEFAULT: 'default',
  ICON_MEASUREMENT: 18,
  ERRORS: {
    PERMISSION: 'Permission',
  },
  MESSAGE_CHANNEL: {
    TARGET_ORIGIN: 'MESSAGE_CHANNEL_TARGET_ORIGIN',
    INIT_MESSAGE: 'init',
    WINDOW_ERROR: 'Unable to find parent window while sending message!',
    PARSING_ERROR: 'Error while parsing response from parent window',
    REQUEST_TIMEOUT: 'Request timed out',
    SUCCESS: 'success',
    FAILED: 'failed',
  },
  INVALID_INPUT: 'INVALID_INPUT',
  PERMISSION_NOT_ALLOWED: 'PERMISSION_NOT_ALLOWED',
  INVALID_SERVICE: 'Invalid service',
  INVALID_SEARCH_SERVICE: 'Invalid search service',
  INVALID_REQUEST_PARAMS: 'Invalid request parameters',
  UPDATE_RECORD_MESSAGE: 'Record updated successfully',
  CREATE_RECORD_MESSAGE: 'Record created successfully',
  UPDATE_RECORD_ERROR: 'Unable to update the record',
  CREATE_RECORD_ERROR: 'Unable to create the record',
  DELETE_RECORD_MESSAGE: 'Record deleted successfully',
  BULK_DELETE_SUCCESS_RECORD_MESSAGE: 'Bulk record deletion successful',
  BULK_DELETE_ERROR_RECORD_MESSAGE: 'Bulk record deletion failed',
  BULK_DELETE_WARNING_RECORD_MESSAGE: 'Bulk record was partially successful',
  DELETE_RECORD_ERROR: 'Unable to delete the record',
  NO_RECORDS_FOUND: 'No records found!',
  NO_RECORDS_FOUND_DESC: "Uh Oh! Looks like you haven't created any records yet",
  INVALID_CUSTOM_OBJECT: 'Invalid Custom Object',
  INVALID_CUSTOM_OBJECT_DESC: 'No such custom object found',
  NO_FIELDS_CREATED: 'No fields created!',
  NO_FIELDS_DESC: 'Uh Oh! Looks like there are no fields in the Custom Object.',
  ASSOCIATED_OBJECTS: 'Linked Tickets Added',
  PAGE_TYPE: {
    NEXT_PAGE: 'next-page',
    PREV_PAGE: 'prev-page',
  },
  ORDER: {
    ASC: 'ASC',
    DESC: 'DESC',
  },
  DEFAULT_PAGE_SIZE: 30,
  WIDGET_PAGE_SIZE: 3,
  REVERSE_WIDGET_PAGE_SIZE: 3,
  WIDGET_CUSTOMISATION_LIMIT: 5,
  SEARCH_PAGE_SIZE: 10,
  NATIVE_TICKET_ID: 1,
  NATIVE_CONTACTS_ID: 2,
  TICKET: 'ticket',
  CONTACT: 'contact',
  COMPANY: 'company',
  CONVERSATION: 'conversation',
  FILTERED_COMPANY_SEARCH: 'filteredCompanySearch',
  ANCHOR: 'anchor',
  EMPTY_DEFAULT_DATA: '- -',
  DEFAULT_DATE_FORMAT: 'dd MMM yyyy',
  DEFAULT_DATE_PLACEHOLDER: 'DD MMM YYYY',
  DELETE_CUSTOM_OBJECTS_WITH_RECORDS_ERROR: 'A custom object with records cannot be deleted. Delete the records first and try again.',
  FETCH_RELATED_RECORDS_ERROR: 'Unable to fetch related records',
  NO_SCHEMA_READ_PERMISSION: 'You do not have the permission to access schema',
  NO_RECORD_READ_PERMISSION: 'You do not have the permission to access record',
  NO_RECORD_DELETE_PERMISSION: 'You do not have the permission to delete record',
  NO_RECORD_UPDATE_PERMISSION: 'You do not have the permission to update record',
  NO_RECORD_CREATE_PERMISSION: 'You do not have the permission to update record',
  RESOURCE_NOT_FOUND: 'RESOURCE_NOT_FOUND',
  TENANT_NOT_FOUND: 'Tenant not found',
  UPDATE_RECORD: 'Update Record',
  NEW_RECORD: 'New Record',
  RECORD: 'record',
  LEGO_TABLE_ERRORS: {
    INVALID_SERVICE_TYPE: 'Invalid service type',
    INVALID_ORDER_BY: 'Invalid value for order by',
    INVALID_VALUE_FOR_FUNCTION: 'Invalid value, should be a function',
  },
  IMPORT_RECORD: {
    SKIP: 'skip',
    UPDATE: 'update',
    CREATE: 'create',
    SAMPLE: 'sample.csv',
    FAILED: 'FAILED',
    IN_PROGRESS: 'IN_PROGRESS',
    IN_QUEUE: 'QUEUED',
    COMPLETE: 'COMPLETE',
    SERVICE_DOWN: 'SERVICE_DOWN',
    NO_MATCH: 'No Match',
    SKIP_DUPLICATE_RECORDS: 'Skip duplicate records',
    UPDATE_DUPLICATE_RECORDS: 'Update duplicate records',
    FILE_UPLOAD: {
      FILES: 'files',
      DROPZONE: 'dropzone',
    },
  },
  RECORDS_LIST: 'RECORDS_LIST',
  ENTITY_LIST: 'ENTITY_LIST',
  CREATED_AT: 'Created At',
  US_TIME_ZONE: 'en-US',
  MAX_TEXT_LENGTH: 100,
  MIN_PRIMARY_FIELD_LENGTH: 2,
  NATIVE_TICKETS: 'Ticket.svg',
  NATIVE_CONTACTS: 'Agent.svg',
  LOOKUP: 'LOOKUP',
  FILTER: 'FILTER',
  COLUMNS_TO_SHOW: 8,
  RECORD_PAGE_UPDATE_ACTIONS: {
    SET_STATE: 'setState',
    FETCH_RELATED_RECORDS: 'fetchRelatedRecords',
  },
  WIDGET_COLUMNS: {
    ID: 'Id',
    NAME: 'Name',
    SUBJECT: 'Subject',
    AVATAR: 'Avatar',
    TITLE: 'Title',
    CONTACT_ID: 'id',
    STATUS: 'status',
    PRIORITY: 'priority',
    REQUESTER: 'requester',
    COMPANY: 'company',
    CONVERSATION: 'conversation',
    CONTACT: 'contact',
    REDIRECTION_ID: 'redirectionId',
    ARCHIVED: 'Archived',
  },
  WIDGET_RELATIONSHIP: {
    MANY_TO_ONE: 'MANY_TO_ONE',
    ONE_TO_ONE: 'ONE_TO_ONE',
  },
  WIDGET_ICONS: {
    TICKETS: 'Ticket.svg',
    CONTACTS: 'Agent.svg',
  },
  NATIVE_WIDGETS: {
    TICKET: 'tickets',
    CONTACT: 'contacts',
    COMPANY: 'companies',
    CONVERSATION: 'conversations',
  },
  ENTITY_ID_PREFIX: 'EID_',
  ROUTE_PREFIX: '/module/custom-objects',
  TIME: {
    SECONDS_FOR_ONE_MINUTE: 60,
    SECONDS_FOR_ONE_HOUR: 3600,
    SECONDS_FOR_ONE_DAY: 86400,
    SECONDS_FOR_SIX_DAYS: 518400,
  },
  UNABLE_TO_GET_ASSOCIATIONS: 'Unable to get Associations',
  UNABLE_TO_FIND_IMPLEMENTATION: 'Unable to find implementation',
  FILTERS: 'filters',
  ENTITY_WITH_COLUMN_NAME_ID: {
    ID: 'id',
    REPLACERPREFIX: '_customer',
  },
  FORMDATA_HEADER: {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  },
  FORMDATA_MULTIPART: 'multipart/form-data',
  CONTENT_TYPE: 'Content-Type',
  MANAGE_TRANSLATION: {
    FILE_UPLOADER: {
      MAX_FILE_SIZE_LIMIT: 5,
      ACCEPT_FILE_TYPE: '.yml',
    },
    FILE_ICON_WIDTH: 26,
    FILE_ICON_HEIGHT: 34,
  },
  DEFAULT_LANGAUGE_DOWNLOAD: 'default',
  MANAGE_TRANSLATION_CHOICE: 'choice_',
  CREATED: 'CREATED',
  DEFAULT_TRANSLATED_WORD: '<Insert translation>',
  FILTER_FIELD_QUERY_LIMIT: 10,
  IMPORT_PROGRESS_TIME_LIMIT: 5000,
};

export default LEGO_CONSTANTS;
