import React, { useState, useEffect } from 'react';

const MessageChannelContext = React.createContext({
  port1: null,
  port2: null,
});

export const MessageChannelContextProvider = ({ children }) => {
  const [portInformation, setPortInformation] = useState({
    port1: null,
    port2: null,
  });

  useEffect(() => {
    const channel = new MessageChannel();
    setPortInformation({
      port1: channel.port1,
      port2: channel.port2,
    });
  }, [setPortInformation]);

  return <MessageChannelContext.Provider value={portInformation}>{children}</MessageChannelContext.Provider>;
};

export default MessageChannelContext;
