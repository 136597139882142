import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { LEGO_CONSTANTS } from 'constants/index';
import useMessageChannelAdapter from './use-message-channel-adapter';

export const useRouteChangeEventChannelEmitter = (emitOnMount = true) => {
  const location = useLocation();
  const { emitRouteChangeEvent: emitRouteChangeEventMC } = useMessageChannelAdapter();

  const emitRouteChangeEvent = useCallback(
    newRoute => {
      const context = { resource: newRoute ?? location.pathname.replace(LEGO_CONSTANTS.ROUTE_PREFIX, '') };
      emitRouteChangeEventMC(context);
    },
    [location.pathname, emitRouteChangeEventMC]
  );

  useEffect(() => {
    if (emitOnMount) {
      emitRouteChangeEvent();
    }
  }, [emitOnMount, emitRouteChangeEvent]);

  return { emitRouteChangeEvent };
};
