import React from 'react';
import styles from './BlankState.module.css';

const BlankState = ({ heading, description, showActions, children, image, imageWidth = 210, imageHeight = 210, blankContainer = false, headingMargin = true }) => {
  return (
    <div className={blankContainer ? styles.container : ''}>
      <div className={styles['blank-state']}>
        <div className={styles['blank-state-container']}>
          <div className={styles['blank-state-container-image']}>
            <img alt="blankState" width={imageWidth} height={imageHeight} src={image} />
          </div>
          <div className={heading && headingMargin ? 'fw-mt-20' : ''}>
            <h2 className={styles['blank-state-container-heading']}>{heading}</h2>
            <p className={styles['blank-state-container-description']}>{description}</p>
            {showActions && <div className={styles['blank-state-container-actions']}>{children}</div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlankState;
