import {
  createCompanyForSearchPreview,
  createCompanyRecord,
  createContactForSearchPreview,
  createContactRecord,
  createConversationForSearchPreview,
  createConversationRecord,
  createSKUCompanyForSearchPreview,
  createSKUCompanyRecord,
  createSKUContactRecord,
  createTicketForSearchPreview,
  createTicketRecord,
} from 'components/RecordPage/RecordDetails/Widgets/utils/util';
import { LEGO_CONSTANTS, SCHEMA_TYPE } from 'constants/index';
import { CommonUtils } from 'utils';

const ticketConfig = {
  ucrEnabledIdKey: 'id',
  messageChannelSupport: true,
  hasBigIntegerIds: false,
  responseKey: 'tickets',
  fieldResponseKey: 'ticket_fields',
  searchResponseKey: 'tickets',
  defaultDisplayField: 'subject',
  bulkGetIdParamsKey: 'ids',
  bulkGetParams: { include: LEGO_CONSTANTS.WIDGET_COLUMNS.REQUESTER },
  searchParams: { without_archive: true },
  fieldsApi: '/api/_/ticket_fields',
  bulkGetApi: '/api/_/tickets',
  searchApi: '/api/_/search/tickets',
  resolvedData: LEGO_CONSTANTS.NATIVE_WIDGETS.TICKET,
  displayCardNativeObjectTitle: item => `${' '}#${item.displayValue?.value?.id} - ${item.displayValue?.value?.subject}${' '}`,
  getParameterDataForID: item => item,
  linkViewClass: styles => `fw-flex ${styles['widget-font']} ${styles['data-color']} ${styles['long-text-ellipsis']}`,
  variation: 'ticket',
  createRecord: (nativeRecord, label, nativeRecordId, t, language, nativeObjectData) => createTicketRecord(nativeRecord, label, nativeRecordId, t, language, nativeObjectData),
  searchPreview: (selectedRecord, t, language) => createTicketForSearchPreview(selectedRecord, t, language),
  navigateState: {
    isNonPrimaryLink: objData => ({ state: { type: SCHEMA_TYPE.NATIVE, resource: LEGO_CONSTANTS.NATIVE_WIDGETS.TICKET, parameters: { id: [objData?.recordId] } } }),
    isPrimaryLink: objData => ({ state: { type: SCHEMA_TYPE.NATIVE, resource: LEGO_CONSTANTS.TICKET, parameters: { id: objData?.recordId } } }),
    viewAllRecords: objData => ({
      state: { type: SCHEMA_TYPE.NATIVE, resource: LEGO_CONSTANTS.NATIVE_WIDGETS.TICKET, parameters: { [objData.fieldName]: [objData?.recordId] } },
    }),
  },
};

const contactConfig = {
  ucrEnabledIdKey: 'org_contact_id',
  messageChannelSupport: false,
  hasBigIntegerIds: true,
  responseKey: 'contacts',
  fieldResponseKey: 'contact_fields',
  searchResponseKey: 'customers',
  defaultDisplayField: 'name',
  bulkGetIdParamsKey: 'org_contact_ids',
  bulkGetParams: { include: LEGO_CONSTANTS.WIDGET_COLUMNS.COMPANY },
  searchParams: {},
  fieldsApi: '/api/_/contact_fields',
  bulkGetApi: '/api/_/contacts',
  searchApi: '/api/_/search/customers',
  displayCardNativeObjectTitle: item => `${' '} ${item.displayValue?.value?.name}`,
  getParameterDataForID: item => CommonUtils.convertValueToBigIntString(item),
  linkViewClass: styles => `${styles['label-font']}`,
  variation: 'contact',
  createRecord: (nativeRecord, label, nativeRecordId, t, language, nativeObjectData) => createContactRecord(nativeRecord, label, nativeRecordId, t),
  searchPreview: (selectedRecord, t, language) => createContactForSearchPreview(selectedRecord, t, language),
  navigateState: {
    isNonPrimaryLink: objData => ({
      state: { type: SCHEMA_TYPE.NATIVE, resource: LEGO_CONSTANTS.NATIVE_WIDGETS.CONTACT, parameters: { id: BigInt(objData?.recordId).toString() } },
    }),
    isPrimaryLink: objData => ({ state: { type: SCHEMA_TYPE.NATIVE, resource: LEGO_CONSTANTS.CONTACT, parameters: { id: BigInt(objData?.recordId).toString() } } }),
    viewAllRecords: objData => ({
      state: { type: SCHEMA_TYPE.NATIVE, resource: LEGO_CONSTANTS.NATIVE_WIDGETS.CONTACT, parameters: { [objData.fieldName]: [objData?.recordId] } },
    }),
  },
};

const companyConfig = {
  ucrEnabledIdKey: 'org_company_id',
  messageChannelSupport: true,
  hasBigIntegerIds: true,
  responseKey: 'companies',
  fieldResponseKey: 'company_fields',
  searchResponseKey: 'customers',
  defaultDisplayField: 'name',
  bulkGetIdParamsKey: 'ids',
  bulkGetParams: {},
  searchParams: { context: LEGO_CONSTANTS.FILTERED_COMPANY_SEARCH },
  fieldsApi: '/api/_/company_fields',
  bulkGetApi: '/api/_/companies',
  searchApi: '/api/_/search/customers',
  displayCardNativeObjectTitle: item => `${' '} ${item.displayValue?.value?.name}`,
  getParameterDataForID: item => CommonUtils.convertValueToBigIntString(item),
  linkViewClass: styles => `${styles['label-font']}`,
  variation: 'contact', // the variation for company is contact as the view is similar to contact
  createRecord: (nativeRecord, label, nativeRecordId, t, language, nativeObjectData) => createCompanyRecord(nativeRecord, label, nativeRecordId, t),
  searchPreview: (selectedRecord, t, language) => createCompanyForSearchPreview(selectedRecord, t, language),
  navigateState: {
    isNonPrimaryLink: objData => ({
      state: { type: SCHEMA_TYPE.NATIVE, resource: LEGO_CONSTANTS.NATIVE_WIDGETS.CONTACT, parameters: { company: [BigInt(objData?.recordId).toString()] } },
    }),
    isPrimaryLink: objData => ({ state: { type: SCHEMA_TYPE.NATIVE, resource: LEGO_CONSTANTS.COMPANY, parameters: { id: BigInt(objData?.recordId).toString() } } }),
    viewAllRecords: objData => ({
      state: { type: SCHEMA_TYPE.NATIVE, resource: LEGO_CONSTANTS.NATIVE_WIDGETS.COMPANY, parameters: { [objData.fieldName]: [objData?.recordId] } },
    }),
  },
};

const contactSKUConfig = {
  ucrEnabledIdKey: 'mcr_id',
  messageChannelSupport: true, // This will always be true , Product will not provide direct access to the backend API to CO UI
  hasBigIntegerIds: true,
  responseKey: 'contacts',
  fieldResponseKey: 'contact_fields',
  searchResponseKey: 'contacts',
  defaultDisplayField: 'name',
  bulkGetIdParamsKey: 'ids',
  bulkGetParams: { include: LEGO_CONSTANTS.WIDGET_COLUMNS.COMPANY },
  searchParams: {},
  fieldsApi: '/api/_/contact_fields',
  bulkGetApi: '/api/_/contacts',
  searchApi: '/api/_/search/customers',
  displayCardNativeObjectTitle: item => `${' '} ${item.displayValue?.value?.name}`,
  getParameterDataForID: item => CommonUtils.convertValueToBigIntString(item),
  linkViewClass: styles => `${styles['label-font']}`,
  variation: 'contact',
  createRecord: (nativeRecord, label, nativeRecordId, t, language, nativeObjectData) => createSKUContactRecord(nativeRecord, label, nativeRecordId, t),
  searchPreview: (selectedRecord, t, language) => createContactForSearchPreview(selectedRecord, t, language),
  navigateState: {
    isNonPrimaryLink: objData => ({
      state: { type: SCHEMA_TYPE.NATIVE, resource: LEGO_CONSTANTS.NATIVE_WIDGETS.CONTACT, parameters: { id: BigInt(objData?.recordId).toString() } },
    }),
    isPrimaryLink: objData => ({ state: { type: SCHEMA_TYPE.NATIVE, resource: LEGO_CONSTANTS.CONTACT, parameters: { id: BigInt(objData?.recordId).toString() } } }),
    viewAllRecords: objData => ({
      state: { type: SCHEMA_TYPE.NATIVE, resource: LEGO_CONSTANTS.NATIVE_WIDGETS.CONTACT, parameters: { [objData.fieldName]: [objData?.recordId] } },
    }),
  },
};

const companySKUConfig = {
  ucrEnabledIdKey: 'mcr_id',
  messageChannelSupport: true,
  hasBigIntegerIds: true,
  responseKey: 'companies',
  fieldResponseKey: 'company_fields',
  searchResponseKey: 'companies',
  defaultDisplayField: 'name',
  bulkGetIdParamsKey: 'ids',
  bulkGetParams: {},
  searchParams: { context: LEGO_CONSTANTS.FILTERED_COMPANY_SEARCH },
  fieldsApi: '/api/_/company_fields',
  bulkGetApi: '/api/_/companies',
  searchApi: '/api/_/search/customers',
  displayCardNativeObjectTitle: item => `${' '} ${item.displayValue?.value?.name}`,
  getParameterDataForID: item => CommonUtils.convertValueToBigIntString(item),
  linkViewClass: styles => `${styles['label-font']}`,
  variation: 'contact', // the variation for company is contact as the view is similar to contact
  createRecord: (nativeRecord, label, nativeRecordId, t, language, nativeObjectData) => createSKUCompanyRecord(nativeRecord, label, nativeRecordId, t),
  searchPreview: (selectedRecord, t, language) => createSKUCompanyForSearchPreview(selectedRecord, t, language),
  navigateState: {
    isNonPrimaryLink: objData => ({
      state: { type: SCHEMA_TYPE.NATIVE, resource: LEGO_CONSTANTS.NATIVE_WIDGETS.CONTACT, parameters: { company: [BigInt(objData?.recordId).toString()] } },
    }),
    isPrimaryLink: objData => ({ state: { type: SCHEMA_TYPE.NATIVE, resource: LEGO_CONSTANTS.COMPANY, parameters: { id: BigInt(objData?.recordId).toString() } } }),
    viewAllRecords: objData => ({
      state: { type: SCHEMA_TYPE.NATIVE, resource: LEGO_CONSTANTS.NATIVE_WIDGETS.COMPANY, parameters: { [objData.fieldName]: [objData?.recordId] } },
    }),
  },
};

const conversationSKUConfig = {
  ucrEnabledIdKey: 'conversationId',
  messageChannelSupport: true, // This will always be true , //Product will not provide direct access to the backend API to CO UI
  hasBigIntegerIds: true,
  responseKey: 'conversations',
  fieldResponseKey: 'conversation_fields',
  searchResponseKey: 'conversations',
  defaultDisplayField: 'title',
  bulkGetIdParamsKey: 'ids',
  bulkGetParams: { include: LEGO_CONSTANTS.WIDGET_COLUMNS.REQUESTER },
  searchParams: { without_archive: true },
  resolvedData: LEGO_CONSTANTS.NATIVE_WIDGETS.CONVERSATION,
  displayCardNativeObjectTitle: item => `${' '}${item.displayValue?.value?.title}`,
  getParameterDataForID: item => item,
  linkViewClass: styles => `fw-flex ${styles['widget-font']} ${styles['data-color']} ${styles['long-text-ellipsis']}`,
  variation: 'conversation',
  createRecord: (nativeRecord, label, nativeRecordId, t, language, nativeObjectData) =>
    createConversationRecord(nativeRecord, label, nativeRecordId, t, language, nativeObjectData),
  searchPreview: (selectedRecord, t, language) => createConversationForSearchPreview(selectedRecord, t, language),
  navigateState: {
    isNonPrimaryLink: objData => ({
      state: { type: SCHEMA_TYPE.NATIVE, resource: LEGO_CONSTANTS.NATIVE_WIDGETS.CONVERSATION, parameters: { conversation: [BigInt(objData?.recordId).toString()] } },
    }),
    isPrimaryLink: objData => ({ state: { type: SCHEMA_TYPE.NATIVE, resource: LEGO_CONSTANTS.CONVERSATION, parameters: { id: BigInt(objData?.recordId).toString() } } }),
    viewAllRecords: objData => ({
      state: { type: SCHEMA_TYPE.NATIVE, resource: LEGO_CONSTANTS.NATIVE_WIDGETS.CONVERSATION, parameters: { [objData.fieldName]: [objData?.recordId] } },
    }),
  },
};

export default {
  default: {
    ticket: ticketConfig,
    contact: contactConfig,
    company: companyConfig,
  },
  sku: {
    ticket: ticketConfig,
    contact: contactSKUConfig,
    company: companySKUConfig,
    conversation: conversationSKUConfig,
  },
};
