import { RestService, API, getPath } from './lib';
import getSearchServiceImpl from './search/searchServiceDiscovery';
import { SEARCH_SERVICE_TYPE } from 'constants/index';

class EntityRecordService extends RestService {
  get = (ids = {}, params = {}) => {
    this.validate(['id', 'displayId'], ids);
    const url = getPath(API.ENTITY_RECORDS, ids, params);
    return this.sendRequest(url);
  };

  getAll = (ids = {}, params = {}) => {
    this.validate(['id'], ids);
    const url = getPath(API.ENTITY_RECORDS, ids, params);
    return this.sendRequest(url);
  };

  count = (ids = {}, params = {}) => {
    delete params.sort_by;
    delete params.page_size;
    const url = getPath(API.ENTITY_RECORDS_COUNT, ids, params);
    return this.sendRequest(url);
  };

  put = (ids = {}, payload = {}, params = {}) => {
    this.validate(['id', 'displayId'], ids);
    const url = getPath(API.ENTITY_RECORDS, ids, params);
    return this.sendRequest(url, 'PUT', payload);
  };

  create = (ids = {}, payload = {}, params = {}) => {
    this.validate(['id'], ids);
    const url = getPath(API.ENTITY_RECORDS, ids, params);
    return this.sendRequest(url, 'POST', payload);
  };

  delete = (ids = {}, params = {}) => {
    this.validate(['id', 'displayId'], ids);
    const url = getPath(API.ENTITY_RECORDS, ids, params);
    return this.sendRequest(url, 'DELETE');
  };

  search = (ids = {}, params = {}) => {
    return getSearchServiceImpl(SEARCH_SERVICE_TYPE.ENTITY_RECORD).search(ids, params);
  };

  bulk = (ids = {}, params = {}) => {
    this.validate(['id'], ids);
    const url = getPath(API.ENTITY_RECORDS_BULK, ids, params);
    return this.sendRequest(url, 'GET');
  };
}

export const entityRecordService = new EntityRecordService();
