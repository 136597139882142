import { useState, useCallback, useMemo } from 'react';
import { ToastController } from '@freshworks/crayons/react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMessageChannelAdapter, useRecordsPage } from 'hooks';
import { formRendererController, recordPageController } from 'controllers';
import { useFreshdeskContextState, useBackwardRefUpdater, useInverseRefUpdater, useNativeObjectsState } from 'store';
import { SEARCH_SERVICE_TYPE, PERMISSION_TYPE, LEGO_CONSTANTS } from 'constants/index';
import { CommonUtils } from 'utils';
import useSearch from './use-search';
import nativeObjectConfigs from 'configs/nativeObjectConfigs';

const useLinkModal = () => {
  const { t } = useTranslation();
  const { updateNativeObject } = useMessageChannelAdapter();
  const { getAllNativeObjectAssociations } = useRecordsPage();
  const nativeObjectData = useNativeObjectsState();
  const { getSearchServiceType, executeForSearchType } = useSearch();
  const [modalState, setModalState] = useState({
    showModal: false,
    data: {},
  });
  const { loadAssociations: loadCustomBackwardAssociations } = useBackwardRefUpdater();
  const { loadInverseAssociations: loadNativeInverseAssociations } = useInverseRefUpdater();
  const { displayId } = useParams();
  const { getSchemaPermissions, getRecordPermissions, getUserDetails, getNativeObjectPermissions, getConfigType } = useFreshdeskContextState();
  const configType = getConfigType();
  const schemaPermissions = useMemo(() => getSchemaPermissions(), [getSchemaPermissions]);
  const recordPermissions = useMemo(() => getRecordPermissions(), [getRecordPermissions]);
  formRendererController.setPermissions(schemaPermissions, recordPermissions, configType);
  recordPageController.setPermissions(schemaPermissions, recordPermissions, configType);
  const language = getUserDetails()?.locale?.language;
  const timezone = getUserDetails()?.locale?.timezone;

  const handleModal = useCallback(
    (content = false) => {
      if (content) {
        const searchServiceType = getSearchServiceType(Number(content?.relatedEntity?.id));
        setModalState(prev => {
          return {
            data: { ...prev.data, ...content, searchServiceType },
            showModal: !modalState.showModal,
          };
        });
      } else {
        setModalState(prev => {
          return {
            ...prev,
            showModal: !modalState.showModal,
          };
        });
      }
    },
    [getSearchServiceType, modalState.showModal]
  );

  const getEnrichRecordData = useCallback(
    async record => {
      const nativeFields = recordPageController.getNativeRelationshipFields(modalState.data?.relatedEntity);
      const nativeIdentifierToIdsLookup = recordPageController.getAssociatedNativeObjectIdsForRecord(record.data, nativeObjectData, nativeFields);
      record.nativeAssociations = await getAllNativeObjectAssociations(nativeIdentifierToIdsLookup);
      return recordPageController.getEnrichRecordData(modalState.data?.relatedEntity, record, nativeObjectData, t, language, timezone, configType);
    },
    [modalState.data?.relatedEntity, nativeObjectData, getAllNativeObjectAssociations, t, language, timezone, configType]
  );

  const onSearchHandler = useCallback(
    async term => {
      if (term) {
        return await executeForSearchType(
          term,
          modalState.data?.relatedEntity.id,
          CommonUtils.getPrimaryField(modalState.data?.relatedEntity)?.name,
          modalState.data?.searchServiceType,
          { include: 'associations' }
        );
      }
    },
    [executeForSearchType, modalState.data?.relatedEntity, modalState.data?.searchServiceType]
  );

  const onSubmitHandler = useCallback(
    async record => {
      const saveNativeLink = async record => {
        const toast = new ToastController();
        const searchType = modalState.data?.searchServiceType;
        try {
          const nativePermissions = getNativeObjectPermissions()?.[searchType] ?? [];
          if (!nativePermissions.includes(PERMISSION_TYPE.UPDATE)) {
            toast.trigger({
              type: 'error',
              content: t('general.noPermission', { action: PERMISSION_TYPE.UPDATE, context: searchType }),
            });
            return;
          }
          const context = {
            model: searchType,
            id: searchType === LEGO_CONSTANTS.CONTACT ? record.org_contact_id : record.id,
            modelProperties: { [modalState.data?.fieldName]: displayId },
          };
          const response = await updateNativeObject(context);
          if (response.success) {
            loadNativeInverseAssociations(true);
            toast.trigger({
              type: 'success',
              content: t('widgetLinkModal.success'),
            });
          } else {
            toast.trigger({
              type: 'error',
              content: response.error,
            });
          }
        } catch (e) {
          console.error(e);
          toast.trigger({
            type: 'error',
            content: t('widgetLinkModal.error'),
          });
        } finally {
          setModalState({
            showModal: false,
            data: {},
          });
        }
      };

      const saveCustomLink = async record => {
        if (!nativeObjectData.loaded) return;
        const toast = new ToastController();
        try {
          const relatedEntity = modalState.data?.relatedEntity;
          const { display_id: relatedDisplayId, data } = record;
          const values = { ...data, [modalState.data.fieldName]: displayId };
          const response = await formRendererController.saveRecord(
            { id: relatedEntity.id, displayId: relatedDisplayId },
            relatedEntity,
            nativeObjectData,
            true,
            values,
            record.version
          );
          if (!response.success) {
            toast.trigger({ type: 'error', content: t('widgetLinkModal.error') });
          } else {
            toast.trigger({ type: 'success', content: t('widgetLinkModal.success') });
            loadCustomBackwardAssociations(true);
          }
        } catch (e) {
          console.error(e);
          toast.trigger({
            type: 'error',
            content: t('widgetLinkModal.error'),
          });
        } finally {
          setModalState({
            showModal: false,
            data: {},
          });
        }
      };

      if (SEARCH_SERVICE_TYPE.ENTITY_RECORD !== modalState.data?.searchServiceType) {
        saveNativeLink(record);
      } else {
        saveCustomLink(record);
      }
    },
    [
      modalState.data?.searchServiceType,
      modalState.data.fieldName,
      modalState.data?.relatedEntity,
      getNativeObjectPermissions,
      updateNativeObject,
      displayId,
      t,
      loadNativeInverseAssociations,
      nativeObjectData,
      loadCustomBackwardAssociations,
    ]
  );

  const onSubmitLinkRecordHandler = async (record, relatedEntity, fieldName, baseRecordData) => {
    const saveNativeLink = async record => {
      const searchType = relatedEntity?.context;
      const toast = new ToastController();
      try {
        const nativePermissions = getNativeObjectPermissions()?.[searchType] ?? [];
        if (!nativePermissions.includes(PERMISSION_TYPE.UPDATE)) {
          return { success: false, errorMessage: t('general.noPermission', { action: PERMISSION_TYPE.UPDATE, context: searchType }) };
        }
        const ucrEnabledIdKey = nativeObjectConfigs[configType][searchType].ucrEnabledIdKey;
        const context = {
          model: searchType,
          id: record[ucrEnabledIdKey],
          modelProperties: { [fieldName]: baseRecordData.display_id },
        };
        const response = await updateNativeObject(context);
        if (response.success) {
          toast.trigger({ type: 'success', content: t('widgetLinkModal.success') });
          loadNativeInverseAssociations(true);
          return { success: true };
        } else {
          return { success: false, errorMessage: response.error };
        }
      } catch (e) {
        console.error(e);
        return { success: false, errorMessage: t('widgetLinkModal.error') };
      }
    };
    const saveCustomLink = async record => {
      const toast = new ToastController();
      if (!nativeObjectData.loaded) return;
      try {
        const { display_id: relatedDisplayId, data } = record;
        const values = { ...data, [fieldName]: displayId };
        const response = await formRendererController.saveRecord(
          { id: relatedEntity.id, displayId: relatedDisplayId },
          relatedEntity,
          nativeObjectData,
          true,
          values,
          record.version
        );
        if (!response.success) {
          return { success: false, errorMessage: t('widgetLinkModal.error') };
        } else {
          toast.trigger({ type: 'success', content: t('widgetLinkModal.success') });
          loadCustomBackwardAssociations(true);
          return { success: true };
        }
      } catch (e) {
        console.error(e);
        return { success: false, errorMessage: t('widgetLinkModal.error') };
      }
    };

    if (SEARCH_SERVICE_TYPE.ENTITY_RECORD !== relatedEntity?.context) {
      return await saveNativeLink(record);
    } else {
      return await saveCustomLink(record);
    }
  };
  return { handleModal, modalState, onSearchHandler, onSubmitHandler, onSubmitLinkRecordHandler, getEnrichRecordData };
};

export default useLinkModal;
