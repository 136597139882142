import Controller from './Controller';
import { PERMISSION_TYPE, LEGO_CONSTANTS, SCHEMA_TYPE, ENTITY_FIELD_TYPE } from 'constants/index';
import { CommonUtils } from 'utils';
import nativeObjectConfigs from 'configs/nativeObjectConfigs';
import { getNativeObjectIDForConfigType } from 'components/RecordPage/RecordDetails/Widgets/utils/util';
export default class EntityController extends Controller {
  __getEntity = async id => {
    if (!this.hasEntityPermission(PERMISSION_TYPE.READ)) throw this.constructErrorObject(LEGO_CONSTANTS.NO_SCHEMA_READ_PERMISSION);
    return await this.entityService.get({ id });
  };

  getEntity = async id => {
    try {
      const entity = await this.__getEntity(id);
      return this.buildResponse(true, entity);
    } catch (error) {
      console.error(error);
      return this.buildErrorResponse(error);
    }
  };

  __getWidgetCustomizationForEntityId = async id => {
    if (!this.hasEntityPermission(PERMISSION_TYPE.READ)) throw this.constructErrorObject(LEGO_CONSTANTS.NO_SCHEMA_READ_PERMISSION);
    return await this.entityService.customisedWidgets({ id });
  };

  getWidgetCustomizationForEntityId = async id => {
    try {
      const response = await this.__getWidgetCustomizationForEntityId(id);
      return this.buildResponse(true, response);
    } catch (error) {
      console.error(error);
      return this.buildErrorResponse(error);
    }
  };

  getAssociatedEntites = async entity => {
    try {
      const relatedEntityIds = this.getCustomRelatedEntityIds(entity);
      const promises = [];
      for (let index = 0; index < relatedEntityIds.length; index++) promises.push(this.__getEntity(relatedEntityIds[index]));
      const response = await Promise.allSettled(promises);
      const associatedEntites = response.filter(res => res.status === 'fulfilled').map(res => res.value);
      return this.buildResponse(true, associatedEntites);
    } catch (error) {
      console.error(error);
      return this.buildErrorResponse(error);
    }
  };

  __getRelatedEntityIds = entity => {
    return this.getRelationshipFields(entity).map(field => field.related_entity_id);
  };

  getFieldLookup = entity => {
    return entity?.fields.reduce((map, field) => {
      return { ...map, [field.name]: field };
    }, {});
  };

  getCustomRelatedEntityIds = entity => {
    const set = new Set(this.getCustomRelationshipFields(entity)?.map(field => field.related_entity_id));
    return Array.from(set);
  };

  getRelationshipFields = entity => {
    return entity?.fields?.filter(field => field.type === ENTITY_FIELD_TYPE.RELATIONSHIP);
  };

  getNativeRelationshipFields = entity => {
    return this.getRelationshipFields(entity)?.filter(field => field.field_options.related_object_type === SCHEMA_TYPE.NATIVE);
  };

  getCustomRelationshipFields = entity => {
    return this.getRelationshipFields(entity)?.filter(field => field.field_options.related_object_type !== SCHEMA_TYPE.NATIVE);
  };

  getNativeRelationshipFieldNames = entity => {
    return this.getNativeRelationshipFields(entity)?.map(field => field.name);
  };

  getNativeRelationshipFieldNameToIdLookup = entity => {
    return this.getNativeRelationshipFields(entity)?.reduce((map, field) => {
      return { ...map, [field.name]: field.related_entity_id };
    }, {});
  };

  getDateFields = entity => {
    return entity?.fields?.filter(field => field.type === ENTITY_FIELD_TYPE.DATE);
  };

  getMulitselectFields = entity => {
    return entity?.fields?.filter(field => field.type === ENTITY_FIELD_TYPE.MULTI_SELECT);
  };

  getCheckboxFields = entity => {
    return entity?.fields?.filter(field => field.type === ENTITY_FIELD_TYPE.CHECKBOX);
  };

  getParagraphFields = entity => {
    return entity?.fields?.filter(field => field.type === ENTITY_FIELD_TYPE.PARAGRAPH);
  };

  getNumericFields = entity => {
    return entity?.fields?.filter(field => field.type === ENTITY_FIELD_TYPE.NUMBER || field.type === ENTITY_FIELD_TYPE.DECIMAL);
  };

  getRelatedEntityIdLookup = fields => {
    return fields?.reduce(function (map, field) {
      return { ...map, [field.name]: field.related_entity_id };
    }, {});
  };

  areFieldsPresent = entity => {
    return entity?.fields?.length > 0;
  };

  __getRecordsByDisplayIds = async (id, displayIds) => {
    if (!this.hasRecordPermission(PERMISSION_TYPE.READ)) throw this.constructErrorObject(LEGO_CONSTANTS.NO_RECORD_READ_PERMISSION);
    return await this.recordService.bulk({ id }, { display_ids: displayIds, include: 'associations' });
  };

  getRecordsInBulk = async entityIdToDisplayIdsLookup => {
    try {
      const promises = [];
      const entityIdRecords = {};
      const entityIds = Object.keys(entityIdToDisplayIdsLookup);
      for (const entityId of entityIds) {
        const displayIds = entityIdToDisplayIdsLookup[entityId];
        if (displayIds.length === 0) continue;
        promises.push(this.__getRecordsByDisplayIds(entityId, displayIds));
      }
      const response = await Promise.allSettled(promises);
      const records = response
        .filter(res => res.status === 'fulfilled')
        .map(res => res.value)
        .reduce((res, array) => [...res, ...(array?.records ?? [])], []);
      const displayIdToRecordLookup = records.reduce((map, record) => {
        return { ...map, [record.display_id]: record };
      }, {});
      for (const entityId of entityIds) {
        const displayIds = entityIdToDisplayIdsLookup[entityId];
        entityIdRecords[entityId] = displayIds.map(displayId => displayIdToRecordLookup[displayId]);
      }
      return this.buildResponse(true, entityIdRecords);
    } catch (error) {
      console.error(error);
      return this.buildErrorResponse(error);
    }
  };

  getDisplayIdToTitleLookup = (entityIdToRecordsLookup, relatedEntities) => {
    const entityIds = Object.keys(entityIdToRecordsLookup);
    const primaryFieldLookup = relatedEntities.reduce((map, entity) => {
      return { ...map, [entity?.id]: CommonUtils.getPrimaryField(entity)?.name };
    }, {});
    let displayIdToTitleLookup = {};
    for (const entityId of entityIds) {
      const records = entityIdToRecordsLookup[entityId];
      const __lookup = records?.reduce((map, record) => {
        const primaryField = primaryFieldLookup[entityId];
        return { ...map, [record?.display_id]: record?.data?.[primaryField] };
      }, {});
      displayIdToTitleLookup = { ...displayIdToTitleLookup, ...__lookup };
    }
    return displayIdToTitleLookup;
  };

  getEnrichRecordData = (entity, record, nativeObjectData, translationFn, language, timezone, configType = 'default') => {
    if (!nativeObjectData.loaded || !record?.data) return { enrichedRecordData: [], recordUpdateModalInitValues: {} };
    const fieldLookup = this.getFieldLookup(entity);
    const enrichedRecordData = Object.keys(record?.data ?? {})
      ?.sort((r1, r2) => fieldLookup[r1]?.position - fieldLookup[r2]?.position)
      ?.map(fieldName => {
        const field = fieldLookup[fieldName];
        const fieldId = field?.id;
        const fieldLabel = field?.label;
        let actualValue = record.data[fieldName];
        const fieldType = field?.type;
        if (fieldType === ENTITY_FIELD_TYPE.DROPDOWN) {
          actualValue = entity?.translatedEntityChoicesLookup?.get(fieldName)?.[actualValue] ?? actualValue;
        }

        if (fieldType === ENTITY_FIELD_TYPE.MULTI_SELECT) {
          actualValue =
            actualValue?.map(itemValue => {
              return entity?.translatedEntityChoicesLookup?.get(fieldName)?.[itemValue] ?? itemValue;
            }) ?? actualValue;
        }

        const fieldSubType =
          fieldType === ENTITY_FIELD_TYPE.RELATIONSHIP && field.field_options.related_object_type === SCHEMA_TYPE.NATIVE ? SCHEMA_TYPE.NATIVE : SCHEMA_TYPE.CUSTOM;
        const fieldRelatedEntityId = field?.related_entity_id;
        const displayValue = this.getRecordDataDisplayValueByType(
          record,
          fieldRelatedEntityId,
          actualValue,
          fieldType,
          fieldSubType,
          nativeObjectData,
          translationFn,
          language,
          configType
        );
        return { fieldId, fieldRelatedEntityId, fieldLabel, fieldName, actualValue, fieldType, fieldSubType, displayValue };
      });
    const recordUpdateModalInitValues = this.parseRecordUpdateModalInitValues(enrichedRecordData);
    enrichedRecordData.push({
      fieldName: 'created_at',
      fieldLabel: translationFn('general.createdAt'),
      displayValue: CommonUtils.timeSince(new Date(record.created_time), translationFn, language, timezone),
    });
    enrichedRecordData.push({
      fieldName: 'updated_at',
      fieldLabel: translationFn('general.updatedAt'),
      displayValue: CommonUtils.timeSince(new Date(record.updated_time), translationFn, language, timezone),
    });
    return { enrichedRecordData, recordUpdateModalInitValues };
  };

  getRecordDataDisplayValueByType = (record, fieldRelatedEntityId, actualValue, fieldType, fieldSubType, nativeObjectData, translationFn, language, configType) => {
    switch (fieldType) {
      case ENTITY_FIELD_TYPE.CHECKBOX:
        return actualValue === true ? translationFn('general.yes') : translationFn('general.no');
      case ENTITY_FIELD_TYPE.NUMBER:
        return actualValue === null ? LEGO_CONSTANTS.EMPTY_DEFAULT_DATA : actualValue;
      case ENTITY_FIELD_TYPE.DECIMAL:
        return actualValue === null ? LEGO_CONSTANTS.EMPTY_DEFAULT_DATA : actualValue;
      case ENTITY_FIELD_TYPE.RELATIONSHIP:
        if (!actualValue) return LEGO_CONSTANTS.EMPTY_DEFAULT_DATA;
        if (fieldSubType === SCHEMA_TYPE.NATIVE) {
          const context = nativeObjectData?.idLookup[fieldRelatedEntityId] ? nativeObjectData.idLookup[fieldRelatedEntityId]?.context : null;
          const dataToDisplay = getNativeObjectIDForConfigType(configType, actualValue);
          return { value: record?.nativeAssociations?.[context]?.[dataToDisplay] ?? dataToDisplay, meta: context };
        } else {
          const primaryFieldName = record?.associations?.[actualValue]?.metadata.primary_field_name;
          const meta = { ...record?.associations?.[actualValue], display_id: actualValue };
          return { value: record?.associations?.[actualValue]?.data?.[primaryFieldName] ?? actualValue, meta };
        }
      case ENTITY_FIELD_TYPE.DATE:
        return actualValue ? CommonUtils.getFormattedDate(new Date(actualValue), language) : actualValue;
      case ENTITY_FIELD_TYPE.MULTI_SELECT:
        actualValue = actualValue?.filter(val => val);
        return actualValue?.join(', ');
      default:
        return actualValue ?? LEGO_CONSTANTS.EMPTY_DEFAULT_DATA;
    }
  };

  parseRecordUpdateModalInitValues = (enrichedRecordData, configType = 'default') => {
    return enrichedRecordData?.reduce((map, item) => {
      let value = item.actualValue;
      if (item.fieldType === ENTITY_FIELD_TYPE.RELATIONSHIP) {
        if (item.fieldSubType === SCHEMA_TYPE.NATIVE) {
          const configs = nativeObjectConfigs[this.configType ?? configType][item.displayValue.meta];
          if (item?.displayValue?.value && item?.displayValue?.value?.[configs?.defaultDisplayField] && item.actualValue) {
            value = [{ text: item.displayValue?.value?.[configs.defaultDisplayField], value: item.actualValue }];
          } else {
            value = null;
          }
        } else {
          if (item?.displayValue?.value && item.actualValue) {
            value = [{ text: item.displayValue.value, value: item.actualValue }];
          } else {
            value = null;
          }
        }
      }
      if (Array.isArray(value)) {
        value = value.filter(iterm => iterm); // for ommitting null values in multi-select
      }
      return { ...map, [item.fieldName]: value };
    }, {});
  };
}
