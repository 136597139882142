import { RestService, API, getPath } from './lib';

class EntityInverseAssociationService extends RestService {
  getAll = (ids = {}, params = {}) => {
    const url = getPath(API.ENTITY_INVERSE_ASSOCIATIONS, ids, params);
    return this.sendRequest(url);
  };
}

export const entityInverseAssociationService = new EntityInverseAssociationService();
