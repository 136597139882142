import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FwIcon } from '@freshworks/crayons/react';

import styles from './DetailsSection.module.css';

const DetailsSection = props => {
  const { t } = useTranslation();
  const backLinkClassName = `fw-color-azure-800 ${styles['back-button']}`;
  return (
    <div className={styles['details-section']}>
      {typeof props.backLink !== 'undefined' && props.backLink !== '' && props.id && (
        <Link to={props.backLink} className={backLinkClassName}>
          <FwIcon slot="before-label" size={10} color="#2c5cc5" name="chevron-left" className="fw-mr-8"></FwIcon>
          {t('general.viewAll')}
        </Link>
      )}
      {props.children}
    </div>
  );
};

export default DetailsSection;
