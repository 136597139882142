import React from 'react';
import { LEGO_CONSTANTS } from 'constants/index';
import styles from './LinkModal.module.css';

const Contact = ({ record }) => {
  const contactColumns = { Name: '', Company: '', Email: '', Phone: '' };
  Object.keys(record).forEach(key => {
    const val = record[key];
    switch (key) {
      case 'name':
        contactColumns.Name = val ?? '-';
        break;
      case 'email':
        contactColumns.Email = val ?? '-';
        break;
      case 'phone':
        contactColumns.Phone = val ?? '-';
        break;
      case 'company':
        contactColumns.Company = val?.name ?? '-';
        break;
      case 'company_id':
        contactColumns.Company = val ?? '-';
        break;
      default:
        break;
    }
  });
  return (
    <div className={styles['data-container']}>
      {Object.keys(contactColumns ?? {}).map(item => {
        return (
          <div key={item} className={styles['data-container--field']}>
            <div className={styles['field-key']}>{item}</div>
            {contactColumns[item] !== null ? (
              <div className={styles['field-value']}>{contactColumns[item]}</div>
            ) : (
              <div className="fw-color-smoke-300">{LEGO_CONSTANTS.EMPTY_DEFAULT_DATA}</div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Contact;
