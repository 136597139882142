const SERVICE_TYPE = {
  ENTITY: 'entity',
  ENTITY_RECORD: 'entity-record',
  ENTITY_ASSOCATION: 'entity-association',
  ENTITY_RECORD_ASSOCATION: 'entity-record-association',
  ENTITY_INVERSE_ASSOCIATION: 'entity-inverse-association',
  ENTITY_INVERSE_RECORD_ASSOCIATION: 'entity-inverse-record-association',
};

export default SERVICE_TYPE;
