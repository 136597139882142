import React, { Suspense, useEffect, lazy, useMemo, useRef, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TranslationController as i18nController } from '@freshworks/crayons/react';
import { withAnalytics } from 'react-analytics-collector';
import { useMessageChannelAdapter } from 'hooks';
import { PageLoader } from 'UI';
import { useFreshdeskContextState, useFreshdeskContextUpdater } from 'store';
import Entities from 'routes/entities';
import Redirect from 'routes/redirect';
import { PageNotFound } from 'routes/error';
import { LEGO_CONSTANTS } from 'constants/index';
const EntityRoute = lazy(() => import('routes/entity'));
const RecordRoute = lazy(() => import('routes/records'));
const Create = lazy(() => import('routes/create'));

const AppBody = () => {
  const { getAccountBasePath } = useFreshdeskContextState();
  return (
    <>
      <Suspense fallback={<></>}>
        <BrowserRouter basename={getAccountBasePath()}>
          <Routes>
            <Route path="/" element={withAnalytics('schema', Entities, { replace: true, to: `${LEGO_CONSTANTS.ROUTE_PREFIX}/schemas` })} />
            <Route path={`${LEGO_CONSTANTS.ROUTE_PREFIX}`} element={withAnalytics('schema', Entities, { replace: true, to: `${LEGO_CONSTANTS.ROUTE_PREFIX}/schemas` })} />
            <Route path={`${LEGO_CONSTANTS.ROUTE_PREFIX}/schemas/:id/records`} element={withAnalytics('records', EntityRoute)} />
            <Route path={`${LEGO_CONSTANTS.ROUTE_PREFIX}/schemas/:id/records/:displayId`} element={withAnalytics('records.show', RecordRoute)} />
            <Route path={`${LEGO_CONSTANTS.ROUTE_PREFIX}/schemas`} element={withAnalytics('schema', Entities)} />
            <Route path={`${LEGO_CONSTANTS.ROUTE_PREFIX}/schemas/create`} element={withAnalytics('schema.create', Create)} />
            <Route path={`${LEGO_CONSTANTS.ROUTE_PREFIX}/schemas/edit/:id`} element={withAnalytics('schema.edit', Create)} />
            <Route path="/redirect" element={<Redirect />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </BrowserRouter>
      </Suspense>
    </>
  );
};

function App() {
  const { getAccessToParentAppContext } = useMessageChannelAdapter();
  const { i18n } = useTranslation();
  const updateFreshdeshContext = useFreshdeskContextUpdater();
  const { getUserDetails } = useFreshdeskContextState();
  const appLoadingTimeout = useRef(null);
  const [loadApp, setLoadApp] = useState(false);
  const language = getUserDetails()?.locale?.language;
  useEffect(() => {
    (async () => {
      i18nController?.setLang?.(language || 'en-US');
      // platformUII18nController?.setLang?.(language || 'en-US');
      document.documentElement.setAttribute('lang', language || 'en-US');
      await i18n.changeLanguage(language?.includes('-') ? language : i18nController?.langCodeMapping[language] || 'en-US');
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  useEffect(() => {
    (async () => {
      const context = { resource: ['account', 'organisation', 'permissions', 'user', 'native_objects_enabled'] };
      const response = await getAccessToParentAppContext(context);
      if (response.success) {
        const { data } = response.response;
        window.basepath = data.basepath;
        window.productSuffix = data.productSuffix;
        updateFreshdeshContext({ ...data, loaded: true });
      }
    })();
  }, [getAccessToParentAppContext, updateFreshdeshContext]);

  const userId = getUserDetails()?.id;
  useEffect(() => {
    if (userId) {
      setLoadApp(true);
      clearTimeout(appLoadingTimeout.current);
    } else if (!appLoadingTimeout.current) {
      appLoadingTimeout.current = setTimeout(() => {
        setLoadApp(true);
      }, 3000);
    }
  }, [userId]);

  return useMemo(() => {
    return loadApp ? <AppBody /> : <PageLoader />;
  }, [loadApp]);
}

export default App;
