const MESSAGE_CHANNEL_COMM_TYPE = {
  GET_ACCESS: 'GET_ACCESS',
  NAVIGATE: 'NAVIGATE',
  CREATED: 'CREATED',
  UPDATED: 'UPDATED',
  DELETED: 'DELETED',
  UPDATE_NATIVE_OBJECT: 'UPDATE_NATIVE_OBJECT',
  ROUTE_CHANGE: 'ROUTE_CHANGE',
  RESOURCES: {
    SCHEMA: 'schema',
  },
  BULK_GET_NATIVE_OBJECT: 'BULK_GET_NATIVE_OBJECT',
  SEARCH_NATIVE_OBJECT: 'SEARCH_NATIVE_OBJECT',
  GET_NATIVE_OBJECT_FIELDS: 'GET_NATIVE_OBJECT_FIELDS',
  GET_LANGUAGES: 'GET_LANGUAGES',
};

export default MESSAGE_CHANNEL_COMM_TYPE;
