/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FwButton, FwIcon, FwSkeleton } from '@freshworks/crayons/react';
import { ENTITY_FIELD_TYPE, LEGO_CONSTANTS, SCHEMA_TYPE } from '../../../constants';
import { InteractivityBeacon } from 'react-analytics-collector';

import nativeObjectConfigs from 'configs/nativeObjectConfigs';
import styles from './DetailsCard.module.css';

const DetailsCard = props => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { title, details, showEdit, onEditHandler, showDelete, onDeleteHandler, isDetailLoading, configType = 'default' } = props;
  const deleteButtonClass = showEdit && showDelete ? 'fw-ml-8' : '';

  const renderNativeObject = (item, meta) => {
    const nativeObjectUCREnabledKey = nativeObjectConfigs[configType][meta]?.ucrEnabledIdKey;
    if (!nativeObjectUCREnabledKey) {
      return <>{LEGO_CONSTANTS.EMPTY_DEFAULT_DATA}</>;
    }
    if (!item.displayValue?.value?.[nativeObjectUCREnabledKey]) {
      return <>{LEGO_CONSTANTS.EMPTY_DEFAULT_DATA}</>;
    }
    return (
      <div className={nativeObjectConfigs[configType][meta].linkViewClass(styles)}>
        {
          <a
            className={`${styles['link-text-style']}`}
            onClick={e => {
              e.preventDefault();
              navigate('/redirect', {
                state: {
                  type: SCHEMA_TYPE.NATIVE,
                  resource: meta,
                  parameters: { id: nativeObjectConfigs[configType][meta]?.getParameterDataForID(item.displayValue?.value?.[nativeObjectUCREnabledKey]) },
                },
              });
            }}
          >
            {nativeObjectConfigs[configType][meta]?.displayCardNativeObjectTitle(item)}
          </a>
        }
      </div>
    );
  };

  const renderCoObject = item => {
    return (
      <div className={`${styles['label-font']}`}>
        <a
          className={`${styles['link-text-style']}`}
          onClick={e => {
            e.preventDefault();
            navigate('/redirect', {
              state: {
                type: SCHEMA_TYPE.CUSTOM,
                path: `${LEGO_CONSTANTS.ROUTE_PREFIX}/schemas/${item?.fieldRelatedEntityId}/records/${item?.actualValue}`,
              },
            });
          }}
        >
          {`${' '} ${item.displayValue?.value}`}
        </a>
      </div>
    );
  };

  const renderDisplayValue = item => {
    if (item.fieldType === ENTITY_FIELD_TYPE.RELATIONSHIP && item.fieldSubType === SCHEMA_TYPE.NATIVE) {
      const meta = item.displayValue.meta;
      return renderNativeObject(item, meta);
    } else if (item.fieldType === ENTITY_FIELD_TYPE.RELATIONSHIP && item.fieldSubType === SCHEMA_TYPE.CUSTOM) {
      return item.displayValue.value ? renderCoObject(item) : item.displayValue;
    }
    return item.displayValue;
  };

  return (
    <div className={styles['details-card']}>
      {!isDetailLoading && <InteractivityBeacon />}
      <div className={styles['card-header']}>
        {isDetailLoading ? (
          <>
            <FwSkeleton count={1} height="35px" customStyles={{ width: '100px' }} />
            <FwSkeleton count={1} height="35px" customStyles={{ width: '100px' }} />
          </>
        ) : (
          <>
            <div className={styles['header-title']}>{title}</div>
            <div className={styles['header-actions']}>
              {showEdit && (
                <FwButton onFwClick={onEditHandler} color="secondary">
                  <FwIcon slot="before-label" name="edit"></FwIcon>
                  {t('general.editLabel')}
                </FwButton>
              )}
              {showDelete && (
                <FwButton className={deleteButtonClass} onFwClick={onDeleteHandler} color="secondary">
                  <FwIcon slot="before-label" name="delete"></FwIcon>
                  {t('general.deleteLabel')}
                </FwButton>
              )}
            </div>
          </>
        )}
      </div>

      <div className={styles.content}>
        {isDetailLoading ? (
          <FwSkeleton count={4} height="20px" customStyles={{ flex: '0 0 auto', width: '60%', marginTop: '30px' }} />
        ) : (
          details?.map(data => {
            return (
              <div key={data.fieldLabel} className={styles.field}>
                <div className={styles['field-key']}>{data.fieldLabel}</div>
                <div className={styles['field-value']}>
                  {data.displayValue || [ENTITY_FIELD_TYPE.DECIMAL, ENTITY_FIELD_TYPE.NUMBER].includes(data.fieldType)
                    ? renderDisplayValue(data)
                    : LEGO_CONSTANTS.EMPTY_DEFAULT_DATA}
                </div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

export default DetailsCard;
