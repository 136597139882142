import { ENTITY_FIELD_TYPE, LEGO_CONSTANTS } from 'constants/index';
import { cloneDeep, isEmpty } from 'lodash-es';

export const checkForDefaultTranslatedWord = (translatedWord, label) => {
  if (translatedWord !== LEGO_CONSTANTS.DEFAULT_TRANSLATED_WORD) return translatedWord ?? label;
  return label;
};

export const prepareTranslatedEntity = (entity, translatedEntitiesList = []) => {
  const translationForEntity = translatedEntitiesList?.filter(translatedEntity => translatedEntity?.translations?.name?.label === entity?.name);

  if (translationForEntity.length > 0) {
    const entityData = cloneDeep(entity);
    const entityTranslations = translationForEntity[0].translations;
    entityData.name = checkForDefaultTranslatedWord(entityTranslations?.name?.translation, entityData.name);
    entityData.description = checkForDefaultTranslatedWord(entityTranslations?.description?.translation, entityData.description);
    const entityFieldNameTranslatedChoices = new Map();
    const entityFieldNameReverseTranslatedChoices = new Map();
    const entityFieldLabelsTranslationLookup = {};
    entityData?.fields?.forEach(field => {
      const customObjectTranslatedFields = entityTranslations?.fields;
      entityFieldLabelsTranslationLookup[field.label] = checkForDefaultTranslatedWord(customObjectTranslatedFields?.[field.name]?.translation, field.label) ?? field.label;
      field.label = checkForDefaultTranslatedWord(customObjectTranslatedFields?.[field.name]?.translation, field.label) ?? field.label;

      if (field.type === ENTITY_FIELD_TYPE.DROPDOWN || field.type === ENTITY_FIELD_TYPE.MULTI_SELECT) {
        const translatedFieldChoicesForFieldName = prepareMappedTranslationDataChoicesForEntityFieldsOfMultiSelectDropdown(field.name, customObjectTranslatedFields);
        entityFieldNameTranslatedChoices.set(field.name, { ...entityFieldNameTranslatedChoices.get(field.name), ...translatedFieldChoicesForFieldName });

        const translatedReverseFieldChoicesForFieldName = prepareMappedTranslationDataChoicesForEntityFieldsOfMultiSelectDropdownForAddUpdateRecord(
          field.name,
          customObjectTranslatedFields
        );
        entityFieldNameReverseTranslatedChoices.set(field.name, { ...entityFieldNameReverseTranslatedChoices.get(field.name), ...translatedReverseFieldChoicesForFieldName });
        field.choices.forEach(choice => {
          choice.value =
            checkForDefaultTranslatedWord(customObjectTranslatedFields?.[field.name]?.choices?.[LEGO_CONSTANTS.MANAGE_TRANSLATION_CHOICE + choice.id]?.translation, choice.value) ??
            choice.value;
        });
      }
      return field;
    });

    entityData.translatedEntityChoicesLookup = entityFieldNameTranslatedChoices;
    entityData.translatedEntityReverseChoicesLookup = entityFieldNameReverseTranslatedChoices;
    entityData.entityFieldLabelsTranslationLookup = entityFieldLabelsTranslationLookup;
    return entityData;
  }
  return entity;
};

export const prepareMappedTranslationDataForEntityFields = (entity, translatedEntitiesList = []) => {
  const translatedEntity = translatedEntitiesList?.filter(translationDataForEntity => translationDataForEntity?.translations?.name?.label === entity?.name);
  const translatedFields = translatedEntity[0]?.translations?.fields ?? {};
  return Object.entries(translatedFields)?.reduce((acc, [_, value]) => Object.assign(acc, { [value.label]: checkForDefaultTranslatedWord(value.translation, value.label) }), {});
};

export const prepareMappedEntityFieldTypeTranslationChoices = (entity, translationDataForEntities = []) => {
  const translatedColumnData = translationDataForEntities?.filter(translationDataForEntity => translationDataForEntity?.translations?.name?.label === entity?.name);

  const entityFieldTypeTranslatedChoices = new Map();

  if (translatedColumnData.length > 0) {
    const customObjectTranslatedFields = translatedColumnData[0]?.translations?.fields;
    entity.fields.forEach(entityField => {
      if (entityField.type === ENTITY_FIELD_TYPE.MULTI_SELECT || entityField.type === ENTITY_FIELD_TYPE.DROPDOWN) {
        const translatedFieldChoicesForFieldName = prepareMappedTranslationDataChoicesForEntityFieldsOfMultiSelectDropdown(entityField.name, customObjectTranslatedFields);
        entityFieldTypeTranslatedChoices.set(entityField.name, { ...entityFieldTypeTranslatedChoices.get(entityField.name), ...translatedFieldChoicesForFieldName });
      }
    });
  }
  return entityFieldTypeTranslatedChoices;
};

export const prepareTranslatedRecordForEntity = (record, entity) => {
  if (!record.data) return record;
  const fieldLookup = entity?.fields.reduce((map, field) => {
    return { ...map, [field.name]: field };
  }, {});
  const translatedRecordData = Object.keys(record?.data ?? {})?.map(fieldName => {
    const field = fieldLookup[fieldName];
    let actualValue = record.data[fieldName];
    const fieldType = field?.type;
    if (fieldType === ENTITY_FIELD_TYPE.DROPDOWN) {
      actualValue = entity?.translatedEntityChoicesLookup?.get(fieldName)?.[actualValue] ?? actualValue;
    }

    if (fieldType === ENTITY_FIELD_TYPE.MULTI_SELECT) {
      actualValue =
        actualValue?.map(itemValue => {
          return entity?.translatedEntityChoicesLookup?.get(fieldName)?.[itemValue] ?? itemValue;
        }) ?? actualValue;
    }
    return { [fieldName]: actualValue };
  });
  record.data = Object.assign({}, ...translatedRecordData);
  return record;
};

export const prepareMappedTranslationDataChoicesForEntityFieldsOfMultiSelectDropdown = (fieldName, translatedEntityListFields) => {
  const translatedFieldChoices = translatedEntityListFields?.[fieldName]?.choices ?? {};
  return Object.entries(translatedFieldChoices)?.reduce(
    (acc, [_, value]) => Object.assign(acc, { [value.label]: checkForDefaultTranslatedWord(value.translation, value.label) }),
    {}
  );
};

// map translation fields to labels for add/edit records
export const prepareMappedTranslationDataChoicesForEntityFieldsOfMultiSelectDropdownForAddUpdateRecord = (fieldName, translatedEntityListFields) => {
  const translatedFieldChoices = translatedEntityListFields?.[fieldName]?.choices ?? {};
  const translatedFieldChoicesMap = Object.entries(translatedFieldChoices)?.reduce(
    (acc, [_, value]) => Object.assign(acc, { [checkForDefaultTranslatedWord(value.translation, value.label)]: value.label }),
    {}
  );
  return { [fieldName]: translatedFieldChoicesMap };
};

export const prepareFilterValuesForTranslation = (filters, entityChoiceLookup) => {
  if (filters && !isEmpty(filters)) {
    return Object.entries(filters ?? {})?.reduce((acc, [filterKey, filterValue]) => {
      if (entityChoiceLookup?.get(filterKey)) {
        const translatedValue = filterValue?.value?.map(filter => ({
          ...filter,
          value: entityChoiceLookup?.get(filterKey)?.[filterKey]?.[filter.text] ?? encodeURIComponent(filter.value),
        }));
        return Object.assign(acc, { [filterKey]: { ...filterValue, value: translatedValue } });
      }

      if (typeof filterValue.value === 'string') {
        return Object.assign(acc, { [filterKey]: { ...filterValue, value: encodeURIComponent(filterValue.value) } });
      } else {
        let encodedURLComponentValue = filterValue?.value;
        if (Array.isArray(filterValue?.value)) {
          encodedURLComponentValue = filterValue?.value?.map(filter => ({
            ...filter,
            value: encodeURIComponent(filter.value),
          }));
        }
        return Object.assign(acc, { [filterKey]: { ...filterValue, value: encodedURLComponentValue } });
      }
    }, {});
  }
  return filters;
};
