/* eslint-disable indent */
import { axiosClient } from './axiosClient';
import { LEGO_CONSTANTS } from 'constants/index';

const startMetrics = (url, method) => {
  let pageEventId, requestEventId;
  if (window.FW_RUM_REACT_ADAPTER?._inRouteMeaningfulPaints === 0) {
    const currentRouteName = window.FW_RUM_REACT_ADAPTER?._currentRouteName;
    let transitionCount = window.FW_RUM_REACT_ADAPTER?._transitionCount;
    if (window.FW_RUM_REACT_ADAPTER._transitionCount === 0) {
      transitionCount = 1;
    }
    pageEventId = window.FW_RUM?.trackEventOnce(`API_Tracking_Page_${transitionCount}`, {
      logName: 'API_Tracking_Page',
      shouldPersist: false,
      scheduleType: 'routeEnd',
      meta: {
        routeName: currentRouteName,
      },
    })?.markStart().id;
    requestEventId = window.FW_RUM?.trackEvent({
      logName: 'API_Tracking_Request',
      meta: {
        requestUrl: url,
        method: method,
        routeName: currentRouteName,
      },
    }).markStart().id;
    if (transitionCount === 1 && currentRouteName) {
      const firstTransitionEvent = window.FW_RUM?.getEventById(pageEventId);
      if (!firstTransitionEvent.meta.routeName) {
        firstTransitionEvent.assignMeta({
          routeName: currentRouteName,
        });
      }
    }
  }
  return { pageEventId, requestEventId };
};

const endMetrics = (pageEventId, requestEventId) => {
  if (pageEventId && requestEventId) {
    if (window.FW_RUM_REACT_ADAPTER?._inRouteMeaningfulPaints === 0) {
      window.FW_RUM?.getEventById(pageEventId)?.markEnd();
      window.FW_RUM?.getEventById(requestEventId)?.markEnd().send();
    } else {
      window.FW_RUM?.getEventById(pageEventId)?.remove();
    }
  }
};

export default class RestService {
  validate = (shouldHave, ids) => {
    let haveAll = true;
    shouldHave.forEach(id => {
      if (!Object.prototype.hasOwnProperty.call(ids, id)) {
        haveAll = false;
      }
    });
    if (!haveAll) throw new Error(LEGO_CONSTANTS.INVALID_REQUEST_PARAMS);
  };

  sendRequest = (url, method = 'GET', body = {}, headers = {}) => {
    const { pageEventId, requestEventId } = startMetrics(url, method);
    const request = axiosClient.sendRequest({
      url,
      method,
      body,
      headers,
    });
    request.finally(endMetrics.bind(this, pageEventId, requestEventId));
    return request;
  };
}
