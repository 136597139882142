import { useState, useCallback, useRef } from 'react';
import { CommonUtils, LegoTable, prepareFilterQuery } from 'utils';
import { LEGO_CONSTANTS } from 'constants/index';
import { useRouteChangeEventChannelEmitter } from './use-location-change-event-channel-emitter';

const useLegoTable = (legoTable, filterSchema) => {
  if (!(legoTable instanceof LegoTable)) throw new Error('Invalid instance of LegoTable');
  const { emitRouteChangeEvent } = useRouteChangeEventChannelEmitter(false);
  const filtersRef = useRef(null);
  const searchDebounceTimerRef = useRef(null);
  const [tableState, setTableState] = useState({
    isLoading: true,
    error: null,
    records: [],
    totalRecords: LEGO_CONSTANTS.DEFAULT_PAGE_SIZE,
    currentPage: 1,
    lastToken: null,
    nextToken: null,
    prevToken: null,
    searchTerm: '',
  });

  const preparePageSize = (params, pageSize) => {
    params.page_size = pageSize;
  };

  const prepareOrder = (params, order, orderBy) => {
    if (orderBy && order) params.sort_by = `${orderBy};${order}`;
  };

  const preparePaginationInfo = (params, pageType, token) => {
    if (pageType === LEGO_CONSTANTS.PAGE_TYPE.NEXT_PAGE) params.next_token = token;
    else if (pageType === LEGO_CONSTANTS.PAGE_TYPE.PREV_PAGE) params.prev_token = token;
  };

  const prepareParamsForFilter = (params = {}) => {
    return params.queryString ? { queryString: params.queryString, ...params } : params;
  };

  const setPreFetchState = preFetchParams => {
    setTableState(prev => {
      return { ...prev, isLoading: true, error: null, ...preFetchParams };
    });
  };

  const setError = error => {
    setTableState(prev => {
      return { ...prev, isLoading: false, error: error?.data ?? null };
    });
  };

  const parseResponse = (response, dataField) => {
    const recordList = response?.[dataField];
    const nextToken = CommonUtils.parseTokenFromLink(response?._links?.next?.href, 'next_token');
    const prevToken = CommonUtils.parseTokenFromLink(response?._links?.prev?.href, 'prev_token');
    return { recordList, nextToken, prevToken };
  };

  const updateStateWithResponse = useCallback(resObj => {
    let { recordList, nextToken, prevToken } = parseResponse(resObj.response, resObj.dataField);
    setTableState(prev => {
      const totalRecords = resObj.countResponse?.count ?? prev.totalRecords;
      const currentPage =
        resObj.pageType === LEGO_CONSTANTS.PAGE_TYPE.NEXT_PAGE ? prev.currentPage + 1 : resObj.pageType === LEGO_CONSTANTS.PAGE_TYPE.PREV_PAGE ? prev.currentPage - 1 : 1;
      nextToken = nextToken ?? prev.nextToken;
      prevToken = prevToken ?? prev.prevToken;
      return { ...prev, records: recordList, isLoading: false, totalRecords, currentPage, nextToken, prevToken };
    });
  }, []);

  const updateSearchResponse = useCallback(
    resObj => {
      const { response, countResponse, currentPage } = resObj;
      setTableState(prev => {
        const totalRecords = countResponse?.count ?? prev.totalRecords;
        return { ...prev, isLoading: false, currentPage: currentPage ?? 1, totalRecords, nextToken: null, prevToken: null, records: response?.[legoTable.getDataField()] };
      });
    },
    [legoTable]
  );

  const fetchList = useCallback(
    async (params = {}, preFetchState = {}) => {
      let { pageType, token, ...requestParams } = params;
      setPreFetchState({ searchTerm: '', ...preFetchState });
      preparePageSize(requestParams, legoTable.getPageSize());
      preparePaginationInfo(requestParams, pageType, token);
      prepareOrder(requestParams, legoTable.getOrder(), legoTable.getOrderBy());
      if (filtersRef.current) {
        const filterQuery = prepareFilterQuery(filterSchema, filtersRef.current);
        const filterParams = prepareParamsForFilter({ queryString: filterQuery });
        requestParams = { ...requestParams, ...filterParams };
      }
      const { next_token: nextToken, prev_token: prevToken, include, ...nonPaginationParams } = requestParams;
      const [response, countResponse] = await Promise.all([
        legoTable
          .getService()
          .getAll(legoTable.getIds(), {
            ...nonPaginationParams,
            ...(nextToken && { next_token: nextToken }),
            ...(prevToken && { prev_token: prevToken }),
            ...(include && { include }),
          })
          .catch(e => setError(e)),
        !legoTable.isCountDisabled() &&
          legoTable
            .getService()
            .count(legoTable.getIds(), nonPaginationParams)
            .catch(e => console.error(e)),
      ]);
      if (response && legoTable.isFilterChangeEventEnabled() && filtersRef.current) {
        const resource = `${location.pathname.replace(LEGO_CONSTANTS.ROUTE_PREFIX, '')}?${LEGO_CONSTANTS.FILTERS}=${encodeURIComponent(JSON.stringify(filtersRef.current))}`;
        resource && emitRouteChangeEvent(resource);
      }
      let toProcess = { response, countResponse, pageType, dataField: legoTable.getDataField() };
      toProcess = await legoTable.applyOnSuccess()?.(toProcess);
      updateStateWithResponse(toProcess);
    },
    [legoTable, updateStateWithResponse, filterSchema, emitRouteChangeEvent]
  );

  const onSort = useCallback(
    async (orderBy, order, params = {}) => {
      legoTable.setOrderBy(orderBy);
      legoTable.setOrder(order);
      fetchList(params);
    },
    [legoTable, fetchList]
  );

  const __search = useCallback(
    async (params = {}) => {
      if (!legoTable.isSearchEnabled()) throw new Error('Search is not enabled');
      preparePageSize(params, legoTable.getPageSize());
      const response = await legoTable
        .getService()
        .search(legoTable.getIds(), { ...params, include: 'associations' })
        .catch(e => setError(e));
      const countResponse = { count: response.metadata.count };
      let toProcess = { response, countResponse, dataField: legoTable.getDataField(), currentPage: params.page };
      toProcess = await legoTable.applyOnSuccess()?.(toProcess);
      updateSearchResponse(toProcess);
    },
    [legoTable, updateSearchResponse]
  );

  const onPageChange = useCallback(
    async (currentPage, params = {}) => {
      if (currentPage > tableState.currentPage) {
        if (params.term) {
          params.page = currentPage;
          __search(params);
        } else {
          params.pageType = LEGO_CONSTANTS.PAGE_TYPE.NEXT_PAGE;
          params.token = tableState.nextToken;
          fetchList(params);
        }
      } else {
        if (params.term) {
          params.page = currentPage;
          __search(params);
        } else {
          params.pageType = LEGO_CONSTANTS.PAGE_TYPE.PREV_PAGE;
          params.token = tableState.prevToken;
          fetchList(params);
        }
      }
    },
    [fetchList, tableState, __search]
  );

  const search = useCallback(
    async (params = {}) => {
      clearTimeout(searchDebounceTimerRef.current);
      setPreFetchState({ searchTerm: params.term });
      if (params.term) params.term = encodeURIComponent(params.term);
      searchDebounceTimerRef.current = setTimeout(() => {
        if (params.term) __search(params);
        else {
          delete params.term;
          delete params.schema_id;
          fetchList(params);
        }
      }, 1500);
    },
    [__search, fetchList]
  );

  const filter = useCallback(
    async (filterValue, params) => {
      if (!legoTable.isFilterEnabled()) throw new Error('Filter is not enabled');
      filtersRef.current = filterValue;
      fetchList(params);
    },
    [legoTable, fetchList]
  );

  const areFiltersSelected = useCallback(() => {
    return filtersRef.current !== null;
  }, []);

  return { tableState, fetchList, onSort, onPageChange, search, filter, areFiltersSelected };
};

export default useLegoTable;
