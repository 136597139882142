import { SearchService, API, getPath } from '../lib';

class EntityRecordSearchService extends SearchService {
  search = (ids = {}, params = {}) => {
    delete params.context;
    const url = getPath(API.ENTITY_RECORDS_SEARCH, ids, params);
    return this.sendRequest(url, 'GET');
  };
}

export const entityRecordSearchService = new EntityRecordSearchService();
