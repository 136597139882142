import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FwButton, FwForm, FwFormControl, FwIcon, FwModal, FwModalContent, FwModalFooter, FwToggleGroup, FwToggleGroupButton } from '@freshworks/crayons/react';
import styles from './EntityBasicDetails.module.css';

const EntityBasicDetails = props => {
  const { t } = useTranslation();
  const formRef = useRef(null);
  const submitBtnRef = useRef(null);
  const { jsonPreset, iconPrefixUrl, entitySchema, isOpen, isSlider, titleText, submitText, cancelText, onSubmit, onClose } = props;

  const iconSelectHandler = event => {
    event.stopImmediatePropagation();
    event.stopPropagation();
    formRef.current.setFieldValue('icon_link', event.detail.value, false);
  };

  const submitBasicDetailsHandler = async () => {
    const { values, isValid } = await formRef.current.doSubmit();
    if (!isValid) {
      return;
    }
    submitBtnRef.current.loading = true;
    const strSelectedIcon = values?.icon_link || jsonPreset?.iconSet?.[0].name;
    const objResponseValues = { ...values, icon_link: strSelectedIcon };
    onSubmit({ value: objResponseValues });
  };

  const closeBasicDetailsHandler = () => {
    submitBtnRef.current.loading = false;
    onClose();
  };

  const renderToggleIconElement = (dataItem, intIndex) => {
    return (
      <FwToggleGroupButton index={intIndex} key={dataItem.name} value={dataItem.name} type="icon">
        <FwIcon slot="toggle-icon" size={20} color="#12344D" name={dataItem.name} src={`${iconPrefixUrl}`} />
      </FwToggleGroupButton>
    );
  };

  const getIconNameFromLink = strIconUrl => {
    if (strIconUrl && strIconUrl !== '') {
      const arrIconUrl = strIconUrl.split('/');
      if (arrIconUrl && arrIconUrl.length > 0) {
        const arrIconName = arrIconUrl[arrIconUrl.length - 1].split('.');
        const strIconName = arrIconName[0];
        if (formRef && formRef.current) {
          formRef.current.setFieldValue('icon_link', strIconName, false);
        }
        return strIconName;
      }
    }
    return null;
  };

  if (jsonPreset) {
    const arrIcons = jsonPreset.iconSet;
    const objMaxLimits = jsonPreset.maximumLimits;
    const iconGroupItems = arrIcons && arrIcons.length > 0 ? arrIcons.map((dataItem, index) => renderToggleIconElement(dataItem, index)) : null;

    return (
      <FwModal isOpen={isOpen} slider={isSlider} titleText={titleText} onFwClose={closeBasicDetailsHandler}>
        <FwModalContent>
          <FwForm
            ref={formRef}
            initialValues={entitySchema || {}}
            className={styles['entity-basic-details']}
            validate={async values => {
              if (!values?.name || !values?.name.length) {
                return {
                  name: t('createEntity.errors.emptyObjectName'),
                };
              } else if (values?.name && values?.name.length < objMaxLimits.entity_name.min) {
                return {
                  name: t('createEntity.errors.minCharsObjectName', { count: objMaxLimits.entity_name.min }),
                };
              }
              return {};
            }}
          >
            <FwFormControl
              type="TEXT"
              name="name"
              label={t('createEntity.basicDetailsName')}
              placeholder={t('createEntity.basicDetailsNamePlaceholder')}
              fieldProps={{ minlength: objMaxLimits?.entity_name?.min, maxlength: objMaxLimits?.entity_name?.max }}
              required
            ></FwFormControl>
            <FwFormControl
              type="PARAGRAPH"
              name="description"
              label={t('createEntity.basicDetailsDescription')}
              placeholder={t('createEntity.basicDetailsDescriptionPlaceholder')}
              fieldProps={{ minlength: objMaxLimits?.entity_description?.min, maxlength: objMaxLimits?.entity_description?.max, resize: 'vertical' }}
            ></FwFormControl>
            <FwFormControl type="TOGGLE_ICON" name="icon_link" label={t('createEntity.basicDetailsIcon')} required>
              <FwToggleGroup
                value={getIconNameFromLink(entitySchema?.icon_link) || jsonPreset?.iconSet?.[0].name}
                className={styles['entity-basic-details-icon-toggle-group']}
                onFwChange={iconSelectHandler}
              >
                {iconGroupItems}
              </FwToggleGroup>
            </FwFormControl>
          </FwForm>
        </FwModalContent>
        <FwModalFooter custom={true}>
          <span className={styles['entity-basic-details-modal-footer']}>
            <FwButton color="secondary" onFwClick={closeBasicDetailsHandler}>
              {cancelText}
            </FwButton>
            <FwButton ref={submitBtnRef} onFwClick={submitBasicDetailsHandler}>
              {submitText}
            </FwButton>
          </span>
        </FwModalFooter>
      </FwModal>
    );
  }
};

export default EntityBasicDetails;
