import { CommonUtils } from 'utils';
import EntityController from './EntityController';
import { COLUMN_WIDTH, ENTITY_FIELD_TYPE, LEGO_CONSTANTS } from 'constants/index';
import { checkForDefaultTranslatedWord, prepareMappedEntityFieldTypeTranslationChoices } from 'utils/translateEntity';

/* LEGO-3523 Check for the presence of id in the user column and replace key for platform table from id to customer id
  The impact is only observed in the Platform Table component */
const replaceUserFieldNameWithID = userFieldName =>
  userFieldName?.toLowerCase() === LEGO_CONSTANTS.ENTITY_WITH_COLUMN_NAME_ID.ID ? `${LEGO_CONSTANTS.ENTITY_WITH_COLUMN_NAME_ID.REPLACERPREFIX}${userFieldName}` : userFieldName;

class EntityPageController extends EntityController {
  __getDisplayIdsForEntityIdLookup = (records, entityIdLookupByName = {}) => {
    const lookup = {};
    const entityNames = Object.keys(entityIdLookupByName);
    entityNames.forEach(entityName => {
      records?.forEach(record => {
        const recordData = Object.keys(record.data);
        recordData
          .filter(key => key === entityName)
          .forEach(key => {
            lookup[entityIdLookupByName[key]] = lookup?.[entityIdLookupByName?.[key]] ?? [];
            if (record.data[key]) lookup[entityIdLookupByName[key]].push(record.data[key]);
          });
      });
    });
    return lookup;
  };

  getValueForFieldNameForCoList = (entity, diplayId, fieldType, item, translationFn, primaryFieldData, entityFieldTypeTranslatedChoices) => {
    switch (fieldType) {
      case ENTITY_FIELD_TYPE.PARAGRAPH:
        return {
          text: item.displayValue?.value ?? item.displayValue,
        };
      case ENTITY_FIELD_TYPE.PRIMARY:
        return {
          text: item.displayValue,
          to: `${LEGO_CONSTANTS.ROUTE_PREFIX}/schemas/${entity.id}/records/${diplayId}`,
          data: { ...primaryFieldData },
        };
      case ENTITY_FIELD_TYPE.RELATIONSHIP:
        return item.displayValue?.value ?? LEGO_CONSTANTS.EMPTY_DEFAULT_DATA;
      case ENTITY_FIELD_TYPE.TEXT:
        return item.displayValue;
      case ENTITY_FIELD_TYPE.NUMBER:
        return item.displayValue;
      case ENTITY_FIELD_TYPE.DECIMAL:
        return item.displayValue;
      case ENTITY_FIELD_TYPE.DATE:
        return item.displayValue;
      case ENTITY_FIELD_TYPE.DROPDOWN:
        return entityFieldTypeTranslatedChoices.get(item.fieldName)?.[item.displayValue] ?? item.displayValue;
      case ENTITY_FIELD_TYPE.CHECKBOX:
        return item.actualValue ? translationFn('general.yes') : translationFn('general.no');
      case ENTITY_FIELD_TYPE.MULTI_SELECT: {
        const multiSelectTranslatedValues = item.actualValue?.map(itemValue => {
          return entityFieldTypeTranslatedChoices.get(item.fieldName)?.[itemValue] ?? itemValue;
        });
        return {
          text: multiSelectTranslatedValues?.join(', ') ?? LEGO_CONSTANTS.EMPTY_DEFAULT_DATA,
        };
      }
      default:
        return item.displayValue?.value ?? item.displayValue;
    }
  };

  __constructRows = (entity, enrichedRecords, translationFn, primaryFieldData, translationDataForEntities) => {
    const entityFieldTypeTranslatedChoices = prepareMappedEntityFieldTypeTranslationChoices(entity, translationDataForEntities);
    return Object.keys(enrichedRecords)?.map(displayId => {
      const recordData = enrichedRecords[displayId];
      const data = recordData.reduce((map, item) => {
        return {
          ...map,
          [replaceUserFieldNameWithID(item.fieldName)]:
            this.getValueForFieldNameForCoList(entity, displayId, item.fieldType, item, translationFn, primaryFieldData, entityFieldTypeTranslatedChoices) ??
            LEGO_CONSTANTS.EMPTY_DEFAULT_DATA,
        };
      }, {});
      const rowInfo = {
        ...data,
        id: displayId,
      };
      return rowInfo;
    });
  };

  getTypeBasedWidth = type => {
    if ([ENTITY_FIELD_TYPE.PARAGRAPH].includes(type)) {
      return COLUMN_WIDTH.LARGE;
    } else if (
      [
        ENTITY_FIELD_TYPE.TEXT,
        ENTITY_FIELD_TYPE.PRIMARY,
        ENTITY_FIELD_TYPE.RELATIONSHIP,
        ENTITY_FIELD_TYPE.MULTI_SELECT,
        ENTITY_FIELD_TYPE.DROPDOWN,
        ENTITY_FIELD_TYPE.CREATED_AT,
        ENTITY_FIELD_TYPE.UPDATED_AT,
      ].includes(type)
    ) {
      return COLUMN_WIDTH.MEDIUM;
    } else return COLUMN_WIDTH.MEDIUM; // NUMBER, DECIMAL, DATE, CHECKBOX
  };

  prepareColumnsForPlatformTable = (entity, associatedEntities, anchorClickHandler, t, translationDataForEntities = []) => {
    const primaryFieldName = CommonUtils.getPrimaryField(entity)?.name;
    const translatedColumnData = translationDataForEntities?.filter(translationDataForEntity => translationDataForEntity?.translations?.name?.label === entity?.name);
    let customObjectTranslatedFields = {};
    if (translatedColumnData.length > 0) {
      customObjectTranslatedFields = translatedColumnData[0]?.translations?.fields;
    }
    const columns =
      entity?.fields?.map((field, index) => {
        const colInfo = {
          key: replaceUserFieldNameWithID(field.name),
          text: checkForDefaultTranslatedWord(customObjectTranslatedFields?.[field.name]?.translation, field.label) ?? field.label,
          position: index + 1,
          widthProperties: {
            width: this.getTypeBasedWidth(field.type),
          },
        };
        if (index > LEGO_CONSTANTS.COLUMNS_TO_SHOW - 1) {
          colInfo.hide = true;
        }
        if (ENTITY_FIELD_TYPE.MULTI_SELECT === field.type) {
          colInfo.variant = ENTITY_FIELD_TYPE.PARAGRAPH.toLowerCase();
        }
        if (ENTITY_FIELD_TYPE.PARAGRAPH === field.type) {
          colInfo.variant = ENTITY_FIELD_TYPE.PARAGRAPH.toLowerCase();
        }
        if (primaryFieldName === field.name) {
          colInfo.customTemplate = (createElement, props) => {
            return createElement(
              'a',
              {
                style: {
                  color: '#2C5CC5',
                  textDecoration: 'none',
                  fontWeight: '600',
                  cursor: 'pointer',
                },
                onClick: e => {
                  e.preventDefault();
                  anchorClickHandler(props.to, { entity, associatedEntities, data: props.data });
                },
              },
              props.text
            );
          };
          colInfo.lock = true;
        }
        return colInfo;
      }) ?? [];
    columns.push({
      key: 'created_at',
      text: t('general.createdAt'),
      position: columns.length,
      widthProperties: {
        width: this.getTypeBasedWidth(ENTITY_FIELD_TYPE.CREATED_AT),
      },
    });
    columns.push({
      key: 'updated_at',
      text: t('general.updatedAt'),
      position: columns.length,
      widthProperties: {
        width: this.getTypeBasedWidth(ENTITY_FIELD_TYPE.UPDATED_AT),
      },
    });
    return columns;
  };

  getFilterSchema = (entity, lookupSearch, selfSearch, t) => {
    return entity?.fields
      ?.filter(field => field.filterable)
      ?.reduce((map, field) => {
        const controlProps = {};
        controlProps.placeholder = CommonUtils.getPlaceholdersForFieldTypes(field.type, t);
        if ([ENTITY_FIELD_TYPE.DROPDOWN, ENTITY_FIELD_TYPE.MULTI_SELECT].includes(field.type)) {
          controlProps.options = (choices => {
            return choices?.map(ch => {
              return { text: ch.value, value: ch.value };
            });
          })(field.choices);
        } else if (ENTITY_FIELD_TYPE.RELATIONSHIP === field.type) {
          controlProps.search = async term => {
            return lookupSearch(term, field.related_entity_id);
          };
          controlProps.noDataText = t('general.startTyping');
        } else if ([ENTITY_FIELD_TYPE.TEXT, ENTITY_FIELD_TYPE.PRIMARY].includes(field.type)) {
          controlProps.search = async term => {
            return selfSearch(term, field.name);
          };
          controlProps.noDataText = t('general.startTyping');
        }
        return {
          ...map,
          [field.name]: {
            text: field.label,
            type: field.type,
            controlProps,
          },
        };
      }, {});
  };
}

export const entityPageController = new EntityPageController();
