import { entitySearchService, entityRecordSearchService } from './index';
import { LEGO_CONSTANTS, SEARCH_SERVICE_TYPE } from 'constants/index';

class SearchServiceDiscovery {
  static getService(name) {
    switch (name) {
      case SEARCH_SERVICE_TYPE.ENTITY:
        return entitySearchService;
      case SEARCH_SERVICE_TYPE.ENTITY_RECORD:
        return entityRecordSearchService;
      default:
        throw new Error(LEGO_CONSTANTS.INVALID_SEARCH_SERVICE);
    }
  }
}

const getSearchServiceImpl = name => {
  return SearchServiceDiscovery.getService(name);
};

export default getSearchServiceImpl;
