import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FwModal, FwModalContent, FwSelect, FwSpinner } from '@freshworks/crayons/react';
import { WidgetLinkModalContext } from 'store';
import { CommonUtils } from 'utils';
import SelectedRecord from './SelectedRecord';
import { SEARCH_SERVICE_TYPE, LEGO_CONSTANTS } from 'constants/index';
import styles from './LinkModal.module.css';

const LinkModal = () => {
  const { modalState, handleModal, onSearchHandler, onSubmitHandler, getEnrichRecordData } = useContext(WidgetLinkModalContext);
  const { t } = useTranslation();

  const [state, setState] = useState({
    loading: false,
    displayDetails: {},
    record: null,
  });

  useEffect(() => {
    if (!modalState.showModal) {
      setState({ record: null, loading: false });
    }
    return () => {
      setState({ record: null, loading: false });
    };
  }, [modalState.showModal]);

  const spinnerContainerStyle = 'fw-flex fw-justify-evenly';
  const spinnerStyle = `fw-flex fw-justify-around fw-flex-column ${styles['link-loading-spinner']}`;
  let searchBy = '';
  if (SEARCH_SERVICE_TYPE.ENTITY_RECORD === modalState.data?.searchServiceType) {
    searchBy = CommonUtils.getPrimaryField(modalState.data?.relatedEntity)?.label;
  } else {
    searchBy = CommonUtils.humanizeString(modalState.data?.searchServiceType ?? '');
  }

  let modalLabel = '';
  if (SEARCH_SERVICE_TYPE.ENTITY_RECORD === modalState.data?.searchServiceType) {
    modalLabel = LEGO_CONSTANTS.RECORD;
  } else {
    modalLabel = modalState.data?.searchServiceType?.toLowerCase() ?? '';
  }

  return (
    modalState.showModal && (
      <FwModal
        isOpen={modalState.showModal}
        slider
        submitDisabled={!state.record}
        submitColor="primary"
        submitText={t('general.save')}
        titleText={`${t('general.link', { label: modalLabel })}`}
        cancelText={t('appUpdateRecord.cancel')}
        onFwSubmit={() => onSubmitHandler(state.record)}
        onFwClose={() => handleModal()}
      >
        <FwModalContent>
          <div className="fw-mt-16">
            <FwSelect
              onFwChange={async e => {
                setState(prev => {
                  return { ...prev, loading: true };
                });
                const record = { ...e.detail?.meta?.selectedOptions?.[0]?.meta };
                if (e.detail?.meta?.selectedOptions?.[0]?.context === SEARCH_SERVICE_TYPE.ENTITY_RECORD) {
                  const displayDetails = await getEnrichRecordData(record);
                  setState({ record, displayDetails });
                } else {
                  setState({ record });
                }
                setState(prev => {
                  return { ...prev, loading: false };
                });
              }}
              label={t('general.searchBy', { label: searchBy })}
              required
              placeholder={t('general.searchPlaceholder')}
              noDataText={t('general.startTyping')}
              search={onSearchHandler}
            />
            {!state.loading && state.record && (
              <SelectedRecord
                relatedEntity={modalState.data?.relatedEntity}
                searchType={modalState.data?.searchServiceType}
                record={state.record}
                displayDetails={state.displayDetails}
              />
            )}
            {state.loading && (
              <div className={spinnerContainerStyle}>
                <div className={spinnerStyle}>
                  <FwSpinner size="large" color="#2C5CC5"></FwSpinner>
                </div>
              </div>
            )}
          </div>
        </FwModalContent>
      </FwModal>
    )
  );
};

export default React.memo(LinkModal);
