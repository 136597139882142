import { SERVICE_TYPE } from 'constants/index';
import getService from 'services/serviceDiscovery';
import { CommonUtils } from 'utils';
export default class Controller {
  constructor() {
    this.entityService = getService(SERVICE_TYPE.ENTITY);
    this.recordService = getService(SERVICE_TYPE.ENTITY_RECORD);
  }

  setPermissions = (entityPermissions, recordPermissions, configType) => {
    this.entityPermissions = entityPermissions;
    this.recordPermissions = recordPermissions;
    this.configType = configType;
  };

  buildResponse = (success = false, response = null, errorMessage = null) => {
    return CommonUtils.buildResponse(success, response, errorMessage);
  };

  getErrorMessage = error => {
    return CommonUtils.getErrorMessage(error);
  };

  buildErrorResponse = (error, parseError = true) => {
    return CommonUtils.buildErrorResponse(error, parseError);
  };

  hasEntityPermission = permission => {
    return this.entityPermissions?.isPresent(permission) ?? false;
  };

  getConfigType = () => {
    return this.configType ?? 'default';
  };

  hasRecordPermission = permission => {
    return this.recordPermissions?.isPresent(permission) ?? false;
  };

  constructErrorObject = message => {
    return { data: { message: message } };
  };
}
