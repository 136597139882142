import { FwButton } from '@freshworks/crayons/react';
import { PERMISSION_TYPE } from 'constants/index';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFreshdeskContextState } from 'store';
import customObjectImage from '../../../images/custom-object-icon.svg';
import { Header } from 'UI';

const EntityListHeader = props => {
  const { onCreateNewObjectHandler } = props;
  const { t } = useTranslation();
  const { getSchemaPermissions } = useFreshdeskContextState();
  const entityPermissions = getSchemaPermissions();
  return (
    <div className="fw-mb-12">
      <Header isLoading={false} heading={t('entities.customObjects')} description={t('entities.description')} image={customObjectImage}>
        {entityPermissions.isPresent(PERMISSION_TYPE.CREATE) && (
          <FwButton onFwClick={onCreateNewObjectHandler} color="primary">
            {t('entities.createObject')}
          </FwButton>
        )}
      </Header>
    </div>
  );
};

export default EntityListHeader;
