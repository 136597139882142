import { useCallback, useEffect, useContext, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { MessageChannelContext, propertyStore } from 'store';
import { LEGO_CONSTANTS } from 'constants/index';
import { CommonUtils } from 'utils';

let callbacksCache = {};

const updateCallbacksCache = (key, value) => {
  callbacksCache[key] = value;
};

const useChannel = () => {
  const { port1, port2 } = useContext(MessageChannelContext);
  const targetWindowExists = () => {
    return window?.parent?.postMessage;
  };
  useEffect(() => {
    const instantiateSecondPort = () => {
      try {
        if (targetWindowExists() && port1 && port2) {
          const TARGET_ORIGIN = propertyStore.get(LEGO_CONSTANTS.MESSAGE_CHANNEL.TARGET_ORIGIN);
          window.parent.postMessage(LEGO_CONSTANTS.MESSAGE_CHANNEL.INIT_MESSAGE, TARGET_ORIGIN, [port2]);
        }
      } catch (e) {}
    };
    const instantiateOnMessageHandler = () => {
      try {
        if (targetWindowExists() && port1) {
          port1.onmessage = ({ data }) => {
            try {
              const parsedData = JSON.parse(data);
              if (parsedData.requestId) {
                callbacksCache[parsedData.requestId]?.(parsedData);
              }
            } catch (e) {
              console.error(LEGO_CONSTANTS.MESSAGE_CHANNEL.PARSING_ERROR, data);
            }
          };
        }
      } catch (e) {}
    };
    instantiateSecondPort();
    instantiateOnMessageHandler();

    return () => {
      callbacksCache = {};
    };
  }, [port1, port2]);

  const promiseWithTimeout = useCallback(
    (promise, timout = 5000) => {
      let timeoutId;
      const promises = [promise];
      if (targetWindowExists() && port1 && port2) {
        promises.push(
          new Promise((resolve, reject) => {
            timeoutId = setTimeout(() => {
              reject(new Error(LEGO_CONSTANTS.MESSAGE_CHANNEL.REQUEST_TIMEOUT));
            }, timout);
          })
        );
      }
      return {
        promiseOrTimeout: Promise.race(promises),
        timeoutId,
      };
    },
    [port1, port2]
  );

  const postMessage = useCallback(
    async (message = {}, resolveOnResponse = true) => {
      if (!port1 || !port2) return { success: false };
      const requestId = uuidv4();
      message = { ...message, requestId };
      const channelPromise = new Promise((resolve, reject) => {
        if (targetWindowExists()) {
          port1.postMessage(message);
          if (resolveOnResponse) {
            updateCallbacksCache(message.requestId, data => {
              if (data?.code === LEGO_CONSTANTS.MESSAGE_CHANNEL.FAILED) {
                data.message = CommonUtils.humanizeString(data.action);
                reject(new Error(CommonUtils.getErrorMessage({ data })));
              } else resolve(data);
            });
          } else {
            resolve(true);
          }
        } else {
          reject(new Error(LEGO_CONSTANTS.MESSAGE_CHANNEL.WINDOW_ERROR));
        }
      });
      const { promiseOrTimeout, timeoutId } = promiseWithTimeout(channelPromise);
      try {
        const response = await promiseOrTimeout;
        return { response, success: true, error: null };
      } catch (e) {
        console.error(e.message);
        return { response: null, success: false, error: e.message };
      } finally {
        clearTimeout(timeoutId);
      }
    },
    [port1, port2, promiseWithTimeout]
  );

  return useMemo(() => {
    return { postMessage };
  }, [postMessage]);
};

export default useChannel;
